import React, {useEffect, useState} from "react";

const withScreenSize = WrappedComponent => {
  const Hoc = ({...rest}) => {

    const [screenSize, getDimension] = useState({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
      getDimension({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
      })
    }

    useEffect(() => {
      window.addEventListener('resize', setDimension);

      return(() => {
        window.removeEventListener('resize', setDimension);
      })
    }, [screenSize])

    return <WrappedComponent {...{screenSize}} {...rest} />
  }

  return Hoc
}

export default withScreenSize
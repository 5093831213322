import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {
  FloatButton,
  Modal,
  Typography,
  Badge,
  Input,
  Button,
  Space,
  Row,
  Col,
  Avatar,
  Divider,
  Table,
  Popconfirm
} from 'antd';
import {
  PlusOutlined,
  SearchOutlined,
  CloseOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween);

const {Title} = Typography;
import EmployeeDrawer from "../EmployeeDrawer/EmployeeDrawer";
import * as actionTypes from "../../constants/usersConstants";
import EmployeeForm from "../EmployeeForm/EmployeeForm";
import {bindActionCreators} from "redux";
import * as usersActionCreators from "../../actions/usersActionCreators";
import {useAuth} from "../../context/auth";
import {PageHeader, SearchInput} from "../../../../libs/components/StyledComponents";
import {userYearlyTakenAllowance, userTakenNextAllowance} from "../../../../libs/dateUtils";
import UserAvatar from "../UserAvatar/UserAvatar";
import UserEditModal from "../UserEditModal/UserEditModal";

function stateToProps(state) {
  return {
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings:  state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$usersStore.get('isSaving'),
  };
}

const ManagerUsersScreen = (props) => {
  const {activeCompanyId, users, companies, leaveTypes, leaveBookings, departments, isSaving} = props;
  const [chartDepartment, setChartDepartment] = useState('all');
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [showEmployeeDrawer, setShowEmployeeDrawer] = useState(false);
  const [activeUserId, setActiveUserId] = useState(null);
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const auth = useAuth();

  const {updateUser, submitUser, deleteUser} = bindActionCreators(usersActionCreators, props.dispatch);

  const activeUserMap = users.find((user) => user.get('id') === activeUserId);
  const activeUser = activeUserMap ? {
    ...Object.fromEntries(activeUserMap),
    work_schedules: Array.from(activeUserMap.get('work_schedules')).map((ws) => Object.fromEntries(ws)),
    leave_allowances: Array.from(activeUserMap.get('leave_allowances')).map((la) => Object.fromEntries(la))
  } : null;

  const company = activeUser ? companies.find((company) => company.get('id') === activeUser.company_id) : companies.find((company) => company.get('id') === activeCompanyId);

  const selectedUsers = users.filter((user) => user.get('company_id') === activeCompanyId && (chartDepartment === 'all' || chartDepartment === user.get('department_id')) && [user.get('first_name'), user.get('last_name')].join(' ').toLowerCase().indexOf(employeeSearch.toLowerCase()) !== -1);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      responsive: ['md'],
    },
    {
      title: 'Approver',
      dataIndex: 'approver',
      responsive: ['lg'],
    },
    {
      title: 'Date of Birth',
      dataIndex: 'birthDate',
      responsive: ['lg'],
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      responsive: ['lg'],
    },
    {
      title: 'Avatar Color',
      dataIndex: 'avatarColor',
      responsive: ['lg'],
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) =>
          userList.size >= 1 ? (
              <>
                <Button type={'link'} style={{padding: 0}} onClick={() => setActiveUserId(record.key)} disabled={auth.user.role !== 'company_admin'}>Edit</Button>
                <Divider type={'vertical'} />
                <Popconfirm title="Are you sure to delete?" onConfirm={() => onDeleteUser(record.key)} disabled={auth.user.role !== 'company_admin'}>
                  <Button type={'link'} style={{padding: 0}} disabled={auth.user.role !== 'company_admin'}>Delete</Button>
                </Popconfirm>
              </>
          ) : null,
    },
  ];

  const onSelectChange = (newSelectedUserIds) => {
    setSelectedUserIds(newSelectedUserIds);
  };

  const userSelection = {
    selectedUserIds,
    onChange: onSelectChange,
  };

  const userList = selectedUsers.map((user) => {
    const dep = departments.find((dep) => dep.get('id') === user.get('department_id'));
    const firstName = user.get('first_name');
    const lastName = user.get('last_name');

    return {
      key: user.get('id'),
      name: (
          <Space key={user.get('id')}>
            <Avatar style={{fontWeight: 'bold', background: user.get('avatar_color')}}
                    size={"default"}>{firstName && firstName[0]}{lastName && lastName[0]}</Avatar>
            <div>
              <Title level={5}
                     style={{margin: 0}}>{firstName} {lastName}</Title>
              <Button type={'link'} style={{padding: 0, height: 20}}>{user.get('email')}</Button>
            </div>
          </Space>
      ),
      role: dep ? dep.get('name') : '',
      approver: '',
      startDate: user.get('employment_from') ? dayjs(user.get('employment_from')).format('DD/MM/YYYY') : '',
      birthDate: user.get('birth_date') ? dayjs(user.get('birth_date')).format('DD/MM/YYYY') : '',
      avatarColor: user.get('avatar_color'),
      annualAllowance: user.get('annual_allowance'),
      annualCarryOver: user.get('annual_carry_over'),
      currentBalance: user.get('current_balance'),
      broughtAllowance: user.get('brought_allowance')
    }
  });

  const onFinish = (form, values) => {
    if (values.id) {
      updateUser(values.id, {
        user: {
          ...values,
          work_schedules_attributes: values.work_schedules,
          leave_allowances_attributes: values.leave_allowances
        }
      }).then(() => setActiveUserId(null));
    } else {
      submitUser({
        user: {
          ...values,
          work_schedules_attributes: values.work_schedules,
          leave_allowances_attributes: values.leave_allowances
        }
      }).then((data) => onCloseUserDrawer(form, data));
    }
  };

  const onDeleteUser = (userId) => {
    deleteUser(userId);
  };

  const onCloseUserDrawer = (form, data) => {
    if (data && data.type === actionTypes.SUBMIT_USER_SUCCESS) {
      setShowEmployeeDrawer(false);
    }
  }

  const onClose = (form, data) => {
    setActiveUserId(null);
  }

  const onChangeSearchEmployee = (value) => {
    setEmployeeSearch(value.target.value);
  }

  return (
      <>
        <div style={{margin: 'auto'}}>
          <PageHeader>
            <Col md={6}>
              <Title level={1}>Users</Title>
            </Col>
            <Col md={12}>
              <Space size={"large"} style={{width: '100%', justifyContent: 'center'}}>
                <SearchInput
                    onChange={onChangeSearchEmployee}
                    size={"large"}
                    prefix={<SearchOutlined className="site-form-item-icon"/>}
                    placeholder="Search employee"/>
              </Space>
            </Col>
          </PageHeader>
          <Row style={{marginBottom: 20}}>
            <Col style={{display: "flex", alignItems: "center", gap: 10, padding: 10, flexWrap: 'wrap'}}>
              <Title level={5}>Filter by</Title>
              <Space wrap>
                <Button type={'all' === chartDepartment ? "primary" : "default"} style={{height: 34, borderRadius: 34, lineHeight: '30px', fontSize: 16, padding: '1px 8px'}}
                        color={"#0023C4"} onClick={() => setChartDepartment('all')}>
                  <span style={{paddingLeft: 10, paddingRight: 5}}>All members</span>
                  <Badge
                      color={"#0E0B4E"}
                      count={users.filter((user) => user.get('company_id') === activeCompanyId).size}
                      style={{border: 0, top: -2, left: 3}}/>
                </Button>
                {departments.filter((d) => users.filter((user) => user.get('company_id') === activeCompanyId && user.get('department_id') === d.get('id')).size).map((d) => (
                    <Button key={d.get('id')} type={d.get('id') === chartDepartment ? "primary" : "default"} style={{height: 34, borderRadius: 34, lineHeight: '30px', fontSize: 16, padding: '1px 8px'}}
                            color={"#0023C4"} onClick={() => setChartDepartment(d.get('id'))}>
                      <span style={{paddingLeft: 10, paddingRight: 5}}>{d.get('name')}</span>
                      <Badge
                          color={"#0E0B4E"}
                          count={users.filter((user) => user.get('company_id') === activeCompanyId && user.get('department_id') === d.get('id')).size}
                          style={{border: 0, top: -2, left: 3}}/>
                    </Button>
                ))}
              </Space>
            </Col>
          </Row>
          <div style={{flexFlow: 'nowrap', overflowY: 'auto', overflowX: 'hidden'}}>
            <Table rowSelection={userSelection} size={'large'} columns={columns} dataSource={Array.from(userList)} pagination={false} />
          </div>
        </div>

        <FloatButton type="primary" onClick={setShowEmployeeDrawer} style={{bottom: 40, right: 40}} disabled={auth.user.role !== 'company_admin'}
                     icon={<PlusOutlined/>}/>

        <EmployeeDrawer
            open={showEmployeeDrawer}
            onClose={() => setShowEmployeeDrawer(false)}
            onFinish={onFinish}
            departments={departments}
            company={company}
            inLieuDays={0}
            takenDays={0}
            nextYearTakenDays={0}
            isSaving={isSaving}/>

        <UserEditModal activeUserId={activeUserId} company={company} onClose={() => setActiveUserId(null)}/>
    </>
  );
}

export default connect(stateToProps)(ManagerUsersScreen)
import React, {useState, useRef} from 'react';
import {
  Typography,
  Tabs,
  Button,
  Space,
  Avatar,
  Row,
  Col,
  Tour
} from 'antd';
import BookingDrawer from "../BookingDrawer/BookingDrawer";
import tileBriefcase from "../../../../assets/images/tile-briefcase.svg"
import iconArrow from "../../../../assets/images/icon-arrow.svg"
import tileCalendar from "../../../../assets/images/tile-calendar.svg"
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {useAuth} from "../../context/auth";
import utc from 'dayjs/plugin/utc'
import {
  countBookingMidDates,
  MONTH_NAMES,
  userYearlyTotalAllowance,
  userYearlyTakenAllowance
} from "../../../../libs/dateUtils";

const {Title} = Typography;

import css from './HomeScreen.module.scss';
import {bindActionCreators} from "redux";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import {connect} from "react-redux";
import {MY_CALENDAR_PATH, WALLCHART_PATH} from "../../constants/paths";
import {useHistory} from "react-router-dom";
import UserAvatar from "../UserAvatar/UserAvatar";
import styled from "styled-components";

dayjs.extend(utc)

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(isSameOrBefore)

dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'just now',
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years"
  }
})

const TopRow = styled(Row)`
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }
  `;


function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    departments: state.$$departmentsStore.get('$$departments'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const HomeScreen = (props) => {
  const {users, companies, leaveTypes, leaveBookings, bankHolidays, departments, isSaving} = props;
  const {submitLeaveBooking} = bindActionCreators(leaveBookingsActionCreators, props.dispatch);

  const [open, setOpen] = useState(false);
  const auth = useAuth();
  const history = useHistory();

  const user = users.find((user) => user.get('id') === auth.user.id);
  const company = companies.find((company) => company.get('id') === auth.user.company_id);
  const companyHolidays = bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')}));

  const userAllowance = user ? userYearlyTotalAllowance(user, dayjs().year()) : 0;
  const userAllowanceSpent = user ? userYearlyTakenAllowance(user, companies, leaveBookings, leaveTypes, bankHolidays, dayjs().year()) : 0;
  const userAllowanceLeft = userAllowance - userAllowanceSpent;
  const nextHoliday = leaveBookings.find((leaveBooking) => leaveBooking.get('approved') && leaveBooking.get('user_id') === auth.user.id && dayjs(leaveBooking.get('from')).isAfter(dayjs.utc()));

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinish = (form, values) => {
    submitLeaveBooking({
      leave_booking: {
        ...values,
        from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
        to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
      }
    }).then(resetAndClose(form));
  };

  const resetAndClose = (form) => {
    setOpen(false);
    form.resetFields();
  };

  const items = [
    {
      key: '1',
      label: `Awaiting Approval`,
      children: (
          <>
            {leaveBookings.filter((leaveBooking) => leaveBooking.get('approved') !== true && !leaveBooking.get('declined') && !leaveBooking.get('canceled') && auth.user.id === leaveBooking.get('user_id')).map((leaveBooking) => {
                  const user = users.size > 0 ? users.find((user) => user.get('id') === leaveBooking.get('user_id')) : null;
                  const dep = departments.find((dep) => dep.get('id') === user.get('department_id'));
                  const firstName = user ? user.get('first_name') : " ";
                  const lastName = user ? user.get('last_name') : " ";

                  return (
                      <Row key={leaveBooking.get('id')} className={css.leaveBookingCard}
                           style={{background: "white", margin: 20, padding: 20, borderRadius: 20}}>
                        <Col lg={8} sm={8} xs={9} style={{minWidth: 200}}>
                          <UserAvatar user={user} withCalendarLink={false}/>
                          <p>"{leaveBooking.get('notes')}"</p>
                        </Col>
                        <Col lg={12} sm={12} xs={15}>
                          <Space style={{marginBottom: 10}}>
                            <img src={tileBriefcase} width={30}/>
                            <Title
                                level={5}>{leaveTypes.size > 0 && leaveTypes.find((leaveType) => leaveType.get('id') === leaveBooking.get('leave_type_id')).get('name')} ({countBookingMidDates(leaveBooking, user.get('work_schedules'), company.get('bank_holidays'), companyHolidays)} days)
                            </Title>
                          </Space>
                          <p style={{marginBottom: 0}}>{leaveBooking.get('from') ? dayjs.utc(leaveBooking.get('from')).format('ddd, DD MMM YYYY') + ' - ' : ''}</p>
                          <p>{leaveBooking.get('to') ? dayjs.utc(leaveBooking.get('to')).format('ddd, DD MMM YYYY') : ''}</p>
                          <Button type={"link"}>Cancel</Button>
                        </Col>
                        <Col lg={4} xs={0} sm={4} style={{textAlign: "right"}}>
                          {dayjs(leaveBooking.get('created_at')).fromNow(true)}
                        </Col>
                      </Row>)
                }
            )}
          </>
      ),
    },
    {
      key: '2',
      label: `Approved`,
      children: (
          <div style={{margin: 30}}>
            <Row style={{margin: 5, paddingLeft: 5}}>
              <Col lg={4} style={{textAlign: 'left'}}>
                <Title level={5}>Type</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>From</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>To</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>Period</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>Approved by</Title>
              </Col>
            </Row>
            {leaveBookings.filter((leaveBooking) => leaveBooking.get('approved') === true && leaveBooking.get('user_id') === auth.user.id).map((leaveBooking) =>
                <Row key={leaveBooking.get('id')}
                     style={{
                       background: "white",
                       borderRadius: 2,
                       paddingLeft: 5,
                       paddingTop: 10,
                       paddingBottom: 10,
                       margin: 5
                     }}>
                  <Col lg={4} style={{textAlign: 'left'}}>
                    <p>{leaveTypes.find((leaveType) => leaveType.get('id') === leaveBooking.get('leave_type_id')).get('name')}</p>
                  </Col>
                  <Col lg={5} style={{textAlign: 'center'}}>
                    <p>{leaveBooking.get('from') ? dayjs.utc(leaveBooking.get('from')).format('ddd, DD MMM YYYY') : ''}</p>
                  </Col>
                  <Col lg={5} style={{textAlign: 'center'}}>
                    <p>{leaveBooking.get('to') ? dayjs.utc(leaveBooking.get('to')).format('ddd, DD MMM YYYY') : ''}</p>
                  </Col>
                  <Col lg={5} style={{textAlign: 'center'}}>
                    <p>{countBookingMidDates(leaveBooking, user.get('work_schedules'), companyHolidays)}</p>
                  </Col>
                  <Col lg={5} style={{textAlign: 'center'}}>
                    <p>{users.find((user) => user.get('id') === leaveBooking.get('approved_by_id')).get('first_name')} {users.find((user) => user.get('id') === leaveBooking.get('approved_by_id')).get('last_name')}</p>
                  </Col>
                </Row>
            )}
          </div>
      ),
    },
    {
      key: '3',
      label: `Declined`,
      children: (
          <div style={{margin: 30}}>
            <Row style={{margin: 5, paddingLeft: 5}}>
              <Col lg={4} style={{textAlign: 'left'}}>
                <Title level={5}>Type</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>From</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>To</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>Period</Title>
              </Col>
              <Col lg={5} style={{textAlign: 'center'}}>
                <Title level={5}>Declined by</Title>
              </Col>
            </Row>
            {leaveBookings.filter((leaveBooking) => leaveBooking.get('declined') === true && leaveBooking.get('user_id') === auth.user.id).map((leaveBooking) =>
                <Row key={leaveBooking.get('id')}
                     style={{
                       background: "white",
                       borderRadius: 2,
                       paddingLeft: 5,
                       paddingTop: 10,
                       paddingBottom: 10,
                       margin: 5
                     }}>
                  <Col lg={4} style={{textAlign: 'left'}}>
                    <p>{leaveTypes.find((leaveType) => leaveType.get('id') === leaveBooking.get('leave_type_id')).get('name')}</p>
                  </Col>
                  <Col lg={5} style={{textAlign: 'center'}}>
                    <p>{leaveBooking.get('from') ? dayjs.utc(leaveBooking.get('from')).format('ddd, DD MMM YYYY') : ''}</p>
                  </Col>
                  <Col lg={5} style={{textAlign: 'center'}}>
                    <p>{leaveBooking.get('to') ? dayjs.utc(leaveBooking.get('to')).format('ddd, DD MMM YYYY') : ''}</p>
                  </Col>
                  <Col lg={5} style={{textAlign: 'center'}}>
                    <p>{countBookingMidDates(leaveBooking, user.get('work_schedules'), companyHolidays)}</p>
                  </Col>
                  <Col lg={4} style={{textAlign: 'center'}}>
                    <p>{users.find((user) => user.get('id') === leaveBooking.get('declined_by_id')).get('first_name')} {users.find((user) => user.get('id') === leaveBooking.get('declined_by_id')).get('last_name')}</p>
                  </Col>
                </Row>
            )}
          </div>
      ),
    },
  ];

  return (
      <>
        <TopRow align="center">
          <Col lg={6} align="center" justify={"end"}>
            <Space align={"center"} style={{marginTop: 10}}>
              <Avatar size={64}
                      style={{fontWeight: 'bold', background: user.get('avatar_color')}}>{auth.user.first_name && auth.user.first_name[0]}{auth.user.last_name && auth.user.last_name[0]}</Avatar>
              <Title level={3}>Welcome {auth.user.first_name}</Title>
            </Space>
          </Col>
          <Col lg={12} align="center">
            <Space align={"baseline"} size={"large"}>
              <Title level={1}>{dayjs.utc().format('hh:mm')}</Title>
              <Title level={4}>{dayjs.utc().format('dddd, DD MMMM')}</Title>
            </Space>
          </Col>
        </TopRow>
        <Row style={{width: '100%', margin: 'auto', justifyContent: "center"}}>
          <Col lg={12} className={css.leftViewContainer}>
            <Space>
              <img src={tileBriefcase}/>
              <Title level={3}>Holiday breakdown</Title>
            </Space>
            {nextHoliday &&
            <Row style={{padding: 30, alignItems: "center"}}>
              <Col lg={6}>
                <Title level={4}>Next holiday</Title>
              </Col>
              <Col lg={14}>
                <Space style={{padding: 30, background: "#FAFAFA", borderRadius: 80}}>
                  <div style={{
                    marginLeft: 30,
                    marginRight: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <Title level={2} style={{margin: 0}}>{dayjs.utc(nextHoliday.get('from')).date()}</Title>
                    <p>{MONTH_NAMES[dayjs.utc(nextHoliday.get('from')).month() + 1]}</p>
                  </div>
                  <img src={iconArrow}/>
                  <div style={{
                    marginLeft: 30,
                    marginRight: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <Title level={2} style={{margin: 0}}>{dayjs.utc(nextHoliday.get('to')).date()}</Title>
                    <p>{MONTH_NAMES[dayjs.utc(nextHoliday.get('from')).month() + 1]}</p>
                  </div>
                </Space>
              </Col>
              <Col lg={4} style={{textAlign: "right"}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Title level={2} style={{margin: 0}}>{countBookingMidDates(nextHoliday, user.get('work_schedules'), companyHolidays)}</Title>
                  <p>Days</p>
                </div>
              </Col>
            </Row>
            }
            <Row align="" style={{padding: 30}}>
              <Col lg={12}>
                <Space style={{minWidth: 250}}>
                  <Title level={4}>Holiday taken</Title>
                  <div style={{marginLeft: 80, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Title level={2} style={{margin: 0}}>{userAllowanceSpent}</Title>
                    <p>Days</p>
                  </div>
                </Space>
              </Col>
              <Col lg={12} style={{textAlign: "right", minWidth: 250}}>
                <Space>
                  <Title level={4}>Holiday left</Title>
                  <div style={{marginLeft: 80, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Title level={2} style={{margin: 0}}>{userAllowanceLeft}</Title>
                    <p>Days</p>
                  </div>
                </Space>
              </Col>
            </Row>
            <Row align="center" style={{padding: 5}}>
              <Button type="primary" onClick={showDrawer} size={"large"}>
                Book time off
              </Button>
            </Row>
            <Row align="" style={{marginTop: 10, marginBottom: 30}}>
              <Col lg={12}>
                <Space>
                  <img src={tileCalendar}/>
                  <Title level={3}>Calendar</Title>
                </Space>
              </Col>
            </Row>
            <Row align="center" style={{padding: 30}}>
              <Col lg={24} style={{display: 'flex', justifyContent: 'space-around', gap: 20}}>
                <Button type="primary" onClick={() => history.push(MY_CALENDAR_PATH)} size={"large"}>
                  My Calendar
                </Button>
                <Button type="primary" onClick={() => history.push(WALLCHART_PATH)} size={"large"}>
                  Wall Chart
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={12} className={css.rightViewContainer}>
            <Tabs size={"large"} centered defaultActiveKey="1" items={items}/>
          </Col>
        </Row>
        <BookingDrawer open={open} isSaving={isSaving} onClose={onClose} onFinish={onFinish} users={users} ownerView={true}
                       leaveTypes={leaveTypes}/>
      </>
  );
}

export default connect(stateToProps)(HomeScreen);

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  FloatButton,
  Modal,
  Typography,
  Badge,
  Input,
  Button,
  Space,
  Pagination,
  Row,
  Col,
  Avatar,
} from 'antd';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  SearchOutlined,
  ArrowUpOutlined,
  CloseOutlined, DownOutlined, MinusOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import {connect} from "react-redux";

dayjs.extend(isBetween);
const {Title} = Typography;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const WallChartTopFilter = (props) => {
  const {users, chartYear, chartMonth, chartDate, dayRange, chartDepartment, activeCompanyId, departments, onChangeMonth, onChangeYear, onChangeDate} = props;

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
      <Row>
        <Col flex="auto" align={"center"}>

          {dayRange.length > 15 ?
              <Space>
                <Button size={"large"} type={"link"}
                        onClick={() => onChangeYear(chartYear - 1)}>
                  <CaretLeftOutlined/>
                </Button>
                <Title level={4} style={{color: '#0E0B4E', marginBottom: 0}}>{chartYear}</Title>
                <Button size={"large"} type={"link"} disabled={chartYear === (dayjs().year() + 1)}
                        onClick={() => onChangeYear(chartYear + 1)}>
                  <CaretRightOutlined/>
                </Button>

                <Pagination
                    itemRender={(index, type) => <Title
                        level={4}>{type === "page" ? months[index - 1] : (type === 'prev' ? <CaretLeftOutlined/> :
                        <CaretRightOutlined/>)}</Title>}
                    onChange={onChangeMonth} total={12} pageSize={1}
                    defaultCurrent={chartMonth + 1}
                    showLessItems={false} showQuickJumper={false} showPrevNextJumpers={false} showSizeChanger={false}
                    showTitle={false} onShowSizeChange={false}/>
              </Space>
              :
              <Space>
                <Button size={"large"} type={"link"}
                        onClick={() => onChangeDate(chartDate.subtract(7, 'day'))}>
                  <CaretLeftOutlined/>
                </Button>
                <Title level={4}
                       style={{color: '#0E0B4E', marginBottom: 0}}>{chartDate.format('DD MMM')}  <MinusOutlined/>  {chartDate.add(dayRange.length - 1, 'day').format('DD MMM')}</Title>
                <Button size={"large"} type={"link"}
                        onClick={() => onChangeDate(chartDate.add(7, 'day'))}>
                  <CaretRightOutlined/>
                </Button>
              </Space>
          }
        </Col>
      </Row>
  );
}

export default connect(stateToProps)(WallChartTopFilter)

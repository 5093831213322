import {Button, Form, Input, InputNumber, Select, Typography, Switch} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {MONTH_NAMES} from '../../../../libs/dateUtils.js';

const { Title } = Typography;

function CompanyForm(props) {
  const [form] = Form.useForm();
  const {onFinish, initValues, isSaving, bankRegions, disabled} = props;

  useEffect(() => {
    form.resetFields();
  }, [initValues.id])

  return (
    <Form
        style={{width: 300}}
        form={form}
        layout="vertical"
        initialValues={initValues}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name="name" rules={[{required: true, message: 'Please input name!'}]} label={<Title level={4} style={{marginBottom: 0}}>Company Name</Title>}>
        <Input size={"large"} type="text" placeholder="Company name" style={{borderWidth: '0 0 2px', borderRadius: 0}} />
      </Form.Item>

      <Form.Item name="annual_reset_month" rules={[{required: true, message: 'Please input annual start month!'}]} label={<Title level={4} style={{marginBottom: 0}}>Leave Year Starts</Title>}>
        <Select size={"large"} placeholder="Annual allowance" style={{borderWidth: '0 0 2px', borderRadius: 0}} disabled={true}>
          {Object.values(MONTH_NAMES).map((month, index) => (
              <Select.Option key={month}
                             value={index}>{month}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item tooltip={"Maximum of days leave employee is able to carry into the next financial year"} name="allowance_carry_over" rules={[{required: true, message: 'Please input allowance carry over max!'}]} label={<Title level={4} style={{marginBottom: 0}}>Allowance Carry Over</Title>}>
        <InputNumber size={"large"} placeholder="Allowance Carry Over" style={{borderWidth: '0 0 2px', borderRadius: 0, maxWidth: 300, width: '100%'}} />
      </Form.Item>

      <Form.Item name="hide_leave_types" label={<Title level={4} style={{marginBottom: 0}}>Hide Leave Types</Title>} valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item>
        <Button style={{marginTop: 40}}  size={"large"} type="primary" htmlType="submit" disabled={isSaving || disabled} icon={isSaving ? <LoadingOutlined /> : null}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRouter(CompanyForm);

import React from 'react';
import BaseComponent from 'libs/components/BaseComponent';
import CreatePasswordForm from '../CreatePasswordForm/CreatePasswordForm';
import css from './CreatePasswordScreen.module.scss';

export default class CreatePasswordScreen extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
        <div className={css.formContainer}>
          <CreatePasswordForm />
        </div>
    );
  }
}

/* eslint new-cap: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/usersConstants';

export const $$initialState = Immutable.fromJS({
  $$users: [],
  fetchUserError: null,
  isFetching: false,
  submitUserError: null,
  updateUserError: null,
  isSaving: false,
  deleteUserError: null,
  isDeleting: false
});

export default function usersReducer($$state = $$initialState, action = null) {
  const { type, userId, user, users, error } = action;

  switch (type) {
    case actionTypes.FETCH_USERS_SUCCESS: {
      return $$state.merge({
        $$users: users,
        fetchUserError: null,
        isFetching: false,
      });
    }

    case actionTypes.FETCH_USERS_FAILURE: {
      return $$state.merge({
        fetchUserError: error,
        isFetching: false,
      });
    }

    case actionTypes.SUBMIT_USER_SUCCESS: {
      return $$state.withMutations((state) =>
          state
              .updateIn(['$$users'], ($$users) => {
                const index = $$users.findIndex((com) => com.get('id') === user.id);
                return index === -1
                    ? $$users.unshift(Immutable.fromJS(user))
                    : $$users.set({ index, value: Immutable.fromJS(user) });
              })
              .merge({
                submitUserError: null,
                isSaving: false,
              }),
      );
    }

    case actionTypes.SUBMIT_USER_FAILURE: {
      return $$state.merge({
        submitUserError: error,
        isSaving: false,
      });
    }

    case actionTypes.UPDATE_USER_SUCCESS: {
      return $$state.withMutations((state) =>
          state
              .updateIn(['$$users'], ($$users) =>
                  $$users.map((com) => com.get('id') === user.id ? Immutable.fromJS(user) : com)
              )
              .merge({
                updateUserError: null,
                isSaving: false,
              }),
      );
    }

    case actionTypes.UPDATE_USER_FAILURE: {
      return $$state.merge({
        updateUserError: error,
        isSaving: false,
      });
    }

    case actionTypes.DELETE_USER_SUCCESS: {
      return $$state.withMutations((state) =>
          state
              .updateIn(['$$users'], ($$users) => $$users.delete($$users.findIndex((com) => com.get('id') === userId)))
              .merge({
                deleteUserError: null,
                isDeleting: false,
              }),
      );
    }

    case actionTypes.DELETE_USER_FAILURE: {
      return $$state.merge({
        deleteUserError: error,
        isDeleting: false,
      });
    }

    case actionTypes.SET_IS_FETCHING: {
      return $$state.merge({
        isFetching: true,
      });
    }

    case actionTypes.SET_IS_SAVING: {
      return $$state.merge({
        isSaving: true,
      });
    }

    case actionTypes.SET_IS_DELETING: {
      return $$state.merge({
        isDeleting: true,
      });
    }

    default: {
      return $$state;
    }
  }
}

import React from 'react';
import {Form, Input, Button, Typography} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { withRouter, useHistory } from 'react-router-dom';
import {useAuth} from "../../context/auth";

import css from './CreatePasswordForm.module.scss';
import {LOGIN_ROUTER_PATH} from "../../constants/paths";

const { Title } = Typography;

function CreatePasswordForm(props) {

  const history = useHistory();
  const auth = useAuth();

  const onFinish = (values) => {
    auth.createPassword(values, resetAndFocus);
  };

  const resetAndFocus = (user) => {
    history.push(LOGIN_ROUTER_PATH);
  }

  return (
    <div className={css.formContainer}>
      <Title level={1}>Create a password</Title>
      <Form
        name="create_password"
        className={css.loginForm}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
              size={"large"}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
          />
        </Form.Item>

        <Form.Item
            name="password_confirm"
            rules={[{ required: true, message: 'Please reenter your Password!' }]}
        >
          <Input.Password
              size={"large"}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password_confirm"
              placeholder="Reenter password"
          />
        </Form.Item>

        <Form.Item>
          <Button size={"large"} type="primary" htmlType="submit">
            Save Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default withRouter(CreatePasswordForm);

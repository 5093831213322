import styled from "styled-components";
import {Input, Row} from "antd";

export const PageHeader = styled(Row)`
  display: flex;
  margin-bottom: 20px;
  
  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SearchInput = styled(Input)`
    border: 0;
    border-radius: 30px;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.102);
    width: 250px;
    
    @media (min-width: 1024px) {
      padding: 20px;
      height: 60px;
      width: 360px;
      font-size: 20px;
    }
`;

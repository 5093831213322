import React, {useState} from 'react';
import {Form, Input, Alert, Button, Typography} from 'antd';
import {LoadingOutlined, LockOutlined, MailOutlined} from '@ant-design/icons';

import css from './SignupForm.module.scss';
import {useHistory} from "react-router-dom";
import {useAuth} from "../../context/auth";
import {SIGNUP_SUCCESS_ROUTER_PATH} from "../../constants/paths";

const { Title } = Typography;

function SignupForm(props) {

  const history = useHistory();
  const auth = useAuth();
  const [saving, setSaving] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const onFinish = (values) => {
    setSaving(true);
    auth.signUp(values, resetAndFocus);
  };

  const resetAndFocus = (data) => {
    if (data) {
      if (data.message === 'OK') {
        history.push(SIGNUP_SUCCESS_ROUTER_PATH);
      } else {
        setFeedbackMessage(data.message);
      }
      setSaving(false);
    }
  }

  return (
    <div className={css.formContainer}>
      <Form
        name="normal_login"
        className={css.loginForm}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {feedbackMessage.length > 0 &&
        <Alert message={feedbackMessage} type="error" style={{marginBottom: 20, fontWeight: 'bold', textAlign: "left"}} />
        }

        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }, { type: 'email', message: 'Enter a valid email address!' }]}
        >
          <Input size={"large"} style={{borderWidth: '0 0 2px', borderRadius: 0}} prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>

        <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }, { min: 6, message: 'Password must be at least 6 characters' }]}
        >
          <Input.Password
              style={{borderWidth: '0 0 2px', borderRadius: 0}}
              size={"large"}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
          />
        </Form.Item>

        <Form.Item className={css.formSubmit}>
          <Button size={"large"} type="primary" htmlType="submit" className={css.signupFormButton}
                  disabled={saving}
                  icon={saving ? <LoadingOutlined /> : null}>
            Get Started!
          </Button>
        </Form.Item>

        <Form.Item style={{textAlign: 'center', margin: 0}}>
          <Title level={5}>If TimeOff isn't right for you, cancel anytime.</Title>
        </Form.Item>
      </Form>
    </div>
  );
}

export default (SignupForm);

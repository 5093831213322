/* eslint new-cap: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/companiesConstants';

export const $$initialState = Immutable.fromJS({
  $$companies: [],
  activeCompanyId: 1,
  fetchCompaniesError: null,
  isFetching: false,
  updateCompanyError: null,
  isSaving: false,
});

export default function companiesReducer($$state = $$initialState, action = null) {
  const { type, company, companies, activeCompanyId, error } = action;

  switch (type) {
    case actionTypes.FETCH_COMPANIES_SUCCESS: {
      return $$state.merge({
        $$companies: companies,
        fetchCompaniesError: null,
        isFetching: false,
      });
    }

    case actionTypes.FETCH_COMPANIES_FAILURE: {
      return $$state.merge({
        fetchCompaniesError: error,
        isFetching: false,
      });
    }

    case actionTypes.SET_IS_FETCHING: {
      return $$state.merge({
        isFetching: true,
      });
    }

    case actionTypes.UPDATE_COMPANY_SUCCESS: {
      return $$state.withMutations((state) =>
          state
              .updateIn(['$$companies'], ($$companies) =>
                  $$companies.map((com) => com.get('id') === company.id ? Immutable.fromJS(company) : com)
              )
              .merge({
                updateCompanyError: null,
                isSaving: false,
              }),
      );
    }

    case actionTypes.UPDATE_COMPANY_FAILURE: {
      return $$state.merge({
        updateCompanyError: error,
        isSaving: false,
      });
    }

    case actionTypes.SET_IS_SAVING: {
      return $$state.merge({
        isSaving: true,
      });
    }

    case actionTypes.SET_ACTIVE_COMPANY_ID: {
      return $$state.merge({
        activeCompanyId: activeCompanyId,
      });
    }

    default: {
      return $$state;
    }
  }
}

export const SET_IS_LOGGINGIN = 'SET_IS_LOGGINGIN';
export const SET_IS_SIGNINGIN = 'SET_IS_SIGNINGIN';


export const SUBMIT_LOGIN_SUCCESS = 'SUBMIT_LOGIN_SUCCESS';
export const SUBMIT_LOGIN_FAILURE = 'SUBMIT_LOGIN_FAILURE';

export const SUBMIT_LOGOUT_SUCCESS = 'SUBMIT_LOGOUT_SUCCESS';
export const SUBMIT_LOGOUT_FAILURE = 'SUBMIT_LOGOUT_FAILURE';

export const SUBMIT_SIGNUP_SUCCESS = 'SUBMIT_SIGNUP_SUCCESS';
export const SUBMIT_SIGNUP_FAILURE = 'SUBMIT_SIGNUP_FAILURE';

import React from 'react';
import BaseComponent from 'libs/components/BaseComponent';
import LoginForm from '../LoginForm/LoginForm';
import css from './LoginScreen.module.scss';

export default class LoginScreen extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
        <div className={css.loginFormContainer}>
          <LoginForm />
        </div>
    );
  }
}

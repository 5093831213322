import React, { useContext, createContext, useState } from 'react';
import { Provider } from 'react-redux';
import ReactOnRails from 'react-on-rails';
import { BrowserRouter } from 'react-router-dom';
import routes from '../routes/routes.jsx';
import {ProvideAuth} from "../context/auth";

function ClientRouterApp(_props) {
  const store = ReactOnRails.getStore('appStore');

  return (
      <ProvideAuth>
        <Provider store={store}>
          <BrowserRouter>{routes}</BrowserRouter>
        </Provider>
      </ProvideAuth>
  );
}

export default ClientRouterApp;

import {
  Button,
  DatePicker,
  Form,
  Input,
  Tooltip,
  Select,
  Space,
  InputNumber,
  Checkbox,
  Divider,
  Typography
} from "antd";
import {LoadingOutlined, DownOutlined} from "@ant-design/icons";
import React from "react";
import {withRouter} from "react-router-dom";
import {getWeekdayName, MONTH_NAMES} from "../../../../libs/dateUtils";
import {USER_AVATAR_COLORS} from "../../../../libs/userUtils";
const { Title } = Typography;

function UserSettingsForm(props) {
  const [form] = Form.useForm();
  const {onFinish, initValues, isSaving} = props;

  return (
    <Form
        form={form}
        layout={"vertical"}
        initialValues={{...initValues}}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item
          name="avatar_color"
          label={<Title level={4} style={{marginBottom: 0}}>Avatar Color</Title>}>
        <Select size={"large"} style={{width: 250}} placeholder="Avatar color">
          {USER_AVATAR_COLORS.map((color) => (
              <Select.Option key={color} value={color}>{color}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button style={{marginTop: 10}}  size={"large"} type="primary" htmlType="submit" disabled={isSaving} icon={isSaving ? <LoadingOutlined /> : null}>
          Save settings
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRouter(UserSettingsForm);

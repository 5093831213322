import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/departmentsConstants';

export function setIsFetching() {
  return {
    type: actionTypes.SET_IS_FETCHING,
  };
}

export function fetchDepartmentsSuccess(data) {
  return {
    type: actionTypes.FETCH_DEPARTMENTS_SUCCESS,
    departments: data,
  };
}

export function fetchDepartmentsFailure(error) {
  return {
    type: actionTypes.FETCH_DEPARTMENTS_FAILURE,
    error,
  };
}

export function fetchDepartments() {
  return (dispatch) => {
    dispatch(setIsFetching());
    return requestsManager
      .fetchEntities('departments')
      .then((res) => dispatch(fetchDepartmentsSuccess(res.data)))
      .catch((error) => dispatch(fetchDepartmentsFailure(error)));
  };
}

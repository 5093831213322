import React, {useState} from 'react';
import {Form, Input, Button, Typography, Alert} from 'antd';
import {LoadingOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import { NavLink as Link, withRouter, useHistory } from 'react-router-dom';
import {useAuth} from "../../context/auth";

import css from './LoginForm.module.scss';
import {HOME_PATH, MANAGERS_WALLCHART_PATH} from "../../constants/paths";
import {bindActionCreators} from "redux";
import * as usersActionCreators from "../../actions/usersActionCreators";
import * as leaveTypesActionCreators from "../../actions/leaveTypesActionCreators";
import * as bankHolidaysActionCreators from "../../actions/bankHolidaysActionCreators";
import * as departmentsActionCreators from "../../actions/departmentsActionCreators";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import * as companiesActionCreators from "../../actions/companiesActionCreators";
import {connect} from "react-redux";
import * as bankRegionsActionCreators from "../../actions/bankRegionsActionCreators";

const { Title } = Typography;

function LoginForm(props) {

  const history = useHistory();
  const auth = useAuth();
  const [saving, setSaving] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const {fetchUsers} = bindActionCreators(usersActionCreators, props.dispatch);
  const {fetchLeaveTypes} = bindActionCreators(leaveTypesActionCreators, props.dispatch);
  const {fetchBankRegions} = bindActionCreators(bankRegionsActionCreators, props.dispatch);
  const {fetchBankHolidays} = bindActionCreators(bankHolidaysActionCreators, props.dispatch);
  const {fetchDepartments} = bindActionCreators(departmentsActionCreators, props.dispatch);
  const {fetchLeaveBookings} = bindActionCreators(leaveBookingsActionCreators, props.dispatch);
  const {fetchCompanies} = bindActionCreators(companiesActionCreators, props.dispatch);

  const onFinish = (values) => {
    setSaving(true);
    auth.signIn(values, resetAndFocus);
  };

  const resetAndFocus = (response) => {
    if (response) {
      if (response.status === 201) {
        const user = response.data;

        fetchUsers();
        fetchLeaveTypes();
        fetchBankRegions();
        fetchBankHolidays();
        fetchDepartments();
        fetchLeaveBookings();
        fetchCompanies();

        if (user.role === 'company_admin' || user.role === 'accountant') {
          history.push(MANAGERS_WALLCHART_PATH);
        } else if (user.role === 'user') {
          history.push(HOME_PATH);
        }
      } else {
        setFeedbackMessage(response.data.message);
      }
    } else {
      setFeedbackMessage("Login failed. Please check your E-mail and password.");
    }
    setSaving(false);
  }

  return (
    <div className={css.formContainer}>
      <Title level={1}>Login</Title>
      <Form
        name="normal_login"
        className={css.loginForm}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {feedbackMessage.length > 0 &&
        <Alert message={feedbackMessage} type="error" style={{marginBottom: 20, fontWeight: 'bold', textAlign: "left"}} />
        }

        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input size={"large"} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>

        <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
              size={"large"}
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Link  className={css.loginFormForgot} to="/forgot-password" activeClassName="active"><Button size={"large"} type="link">Forgot password</Button></Link>
        </Form.Item>

        <Form.Item>
          <Button size={"large"} type="primary" htmlType="submit" className={css.loginFormButton}
                  disabled={saving}
                  icon={saving ? <LoadingOutlined /> : null}>
            Login
          </Button>
          <Link to="/signup" activeClassName="active"><Button size={"large"} type="link">Sign up</Button></Link>
        </Form.Item>
      </Form>
    </div>
  );
}

export default connect()(withRouter(LoginForm));

import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/authConstants';
import {SUBMIT_LOGOUT_FAILURE, SUBMIT_LOGOUT_SUCCESS} from "../constants/authConstants";

export function setIsLoggingIn() {
  return {
    type: actionTypes.SET_IS_LOGGINGIN,
  };
}

export function setIsSigningIn() {
  return {
    type: actionTypes.SET_IS_SIGNINGIN,
  };
}

export function submitLoginSuccess(data) {
  return {
    type: actionTypes.SUBMIT_LOGIN_SUCCESS,
    user: data.user,
  };
}

export function submitLoginFailure(error) {
  return {
    type: actionTypes.SUBMIT_LOGIN_FAILURE,
    error,
  };
}

export function logoutSuccess() {
  return {
    type: actionTypes.SUBMIT_LOGOUT_SUCCESS,
  };
}

export function logoutFailure(error) {
  return {
    type: actionTypes.SUBMIT_LOGOUT_FAILURE,
    error,
  };
}

export function submitSignupSuccess(user) {
  return {
    type: actionTypes.SUBMIT_SIGNUP_SUCCESS,
    user,
  };
}

export function submitSignupFailure(error) {
  return {
    type: actionTypes.SUBMIT_SIGNUP_FAILURE,
    error,
  };
}

export function submitLogin(user) {
  return (dispatch) => {
    dispatch(setIsLoggingIn());
    return requestsManager
      .submitLogin({ user })
      .then((res) => dispatch(submitLoginSuccess(res.data)))
      .catch((error) => dispatch(submitLoginFailure(error)));
  };
}

export function submitSignup(user) {
  return (dispatch) => {
    dispatch(setIsSigningIn());
    return requestsManager
      .submitSignup({ user })
      .then((res) => dispatch(submitSignupSuccess(res.data)))
      .catch((error) => dispatch(submitSignupFailure(error)));
  };
}

export function logout(user) {
  return (dispatch) => {
    return requestsManager
        .logout()
        .then((res) => dispatch(submitLoginSuccess(res.data)))
        .catch((error) => dispatch(submitLoginFailure(error)));
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';

function NavigationBar(props) {
  const { email } = props;

  const handleMenuClick = (e) => {
  };

  const items = [
    {
      label: 'Logout',
      key: 'logout',
      icon: <UserOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  /* eslint-disable new-cap */
  return (
    <nav className="navbar navbar-default">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <a className="navbar-brand" href="http://www.shakacode.com">
            ShakaCode
          </a>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav">
            <Dropdown menu={menuProps}>
              <Button type="link">
                <Space>
                  <UserOutlined /> {email}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </ul>
        </div>
      </div>
    </nav>
  );
}

NavigationBar.propTypes = {
  commentsCount: PropTypes.number,
  pathname: PropTypes.string.isRequired,
};

export default NavigationBar;

import React from 'react';
import BaseComponent from '../../../libs/components/BaseComponent.jsx';
import CreatePasswordScreen from "../components/CreatePasswordScreen/CreatePasswordScreen";

class RouterAuthContainer extends BaseComponent {

  render() {
    return (
        <CreatePasswordScreen />
    );
  }
}

export default RouterAuthContainer;

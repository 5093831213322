import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {
  FloatButton,
  Typography,
  Button,
  Space,
  Row,
  Col, Select, Card, Divider, Badge, Modal,
} from 'antd';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined, CloseOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import BookingDrawer from "../BookingDrawer/BookingDrawer";
import {useAuth} from "../../context/auth";
import VtYearCalendar from "../VtYearCalendar/VtYearCalendar";
import isBetween from 'dayjs/plugin/isBetween'
import {bindActionCreators} from "redux";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import {PageHeader, SearchInput} from "../../../../libs/components/StyledComponents";
import LeaveBookingModal from "../LeaveBookingModal/LeaveBookingModal";
import styled from "styled-components";
import CalendarAllowanceOverview from "../CalendarAllowanceOverview/CalendarAllowanceOverview";

dayjs.extend(isBetween);

const {Title} = Typography;

const Wrapper = styled(Row)`
    flex-flow: nowrap;
    
    @media (max-width: 640px) {
      flex-direction: column-reverse;
    }
  `;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const MyCalendarScreen = (props) => {
  const auth = useAuth();

  const {users, companies, bankHolidays, leaveBookings, leaveTypes, isSaving} = props;
  const [openBooking, setOpenBooking] = useState(false);
  const [chartYear, setChartYear] = useState((new Date).getFullYear());
  const [activeBookingId, setActiveBookingId] = useState(null);
  const {updateLeaveBooking, submitLeaveBooking} = bindActionCreators(leaveBookingsActionCreators, props.dispatch);

  const showBookingDrawer = () => {
    setOpenBooking(true);
  };

  const onCloseBooking = () => {
    setOpenBooking(false);
  };

  const onCloseBookingInfo = () => {
    setActiveBookingId(null);
  };

  const onFinishBooking = (form, values) => {
    if (values.id) {
      updateLeaveBooking(values.id, {
        leave_booking: {
          ...values,
          from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
          to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
        }
      }).then(() => setActiveBookingId(null));
    } else {
      submitLeaveBooking({
        leave_booking: {
          ...values,
          from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
          to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
        }
      }).then(() => resetAndCloseBooking(form));
    }
  };

  const onCancelBooking = (form, leaveBooking) => {
    updateLeaveBooking(leaveBooking.id, {
      leave_booking: {
        canceled: true
      }
    }).then(() => setActiveBookingId(null));
  }

  const resetAndCloseBooking = (form) => {
    setOpenBooking(false);
    form.resetFields();
  };

  const today = (new Date);
  today.setUTCHours(0, today.getTimezoneOffset(), 0, 0);
  const user = users.find((user) => user.get('id') === auth.user.id);
  const company = companies.find((company) => company.get('id') === user.get('company_id'));

  const userBookings = leaveBookings.filter((leaveBooking) => leaveBooking.get('user_id') === user.get('id') && !leaveBooking.get('declined') && !leaveBooking.get('canceled'));
  const companyHolidays = bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')}));

  return (
      <>
        <div style={{padding: 20, margin: 'auto', maxWidth: 1240}}>
          <PageHeader>
            <Col md={8}>
              <Title level={1}>My Calendar</Title>
            </Col>
            <Col md={16} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <Button size={"large"} type={"link"}
                      onClick={() => setChartYear(chartYear - 1)}>
                <CaretLeftOutlined/>
              </Button>
              <Title level={3} style={{fontSize: 22, marginBottom: 0}}>{chartYear}</Title>
              <Button size={"large"} type={"link"} disabled={chartYear === (dayjs().year() + 1)}
                      onClick={() => setChartYear(chartYear + 1)}>
                <CaretRightOutlined/>
              </Button>
            </Col>
          </PageHeader>
          <Wrapper>
            <Col flex="auto" style={{overflowY: 'auto', overflowX: 'hidden'}}>
              <VtYearCalendar userBookings={userBookings} companyHolidays={companyHolidays} managerView={false} ownerView={user.get('id') === auth.user.id || auth.user.role === 'company_admin'} onChangeActiveBooking={(bookingId) => setActiveBookingId(bookingId)} year={chartYear} user={user} />
            </Col>
            <Col style={{display: 'flex', flexDirection: 'column', borderLeft: '1px solid #E9E9F0', padding: 20, minWidth: 300}}>
              <CalendarAllowanceOverview user={user} company={company} year={chartYear} />
            </Col>
          </Wrapper>
        </div>

        <FloatButton type="primary" onClick={showBookingDrawer} style={{bottom: 40, right: 40}}
                     icon={<PlusOutlined/>}/>

        <BookingDrawer open={openBooking} onClose={onCloseBooking} onFinish={onFinishBooking} ownerView={true}/>

        <LeaveBookingModal
            activeBookingId={activeBookingId}
            onCloseBookingInfo={onCloseBookingInfo}
            onFinishBooking={onFinishBooking}
            onCancelBooking={onCancelBooking}
            managerView={false}
            ownerView={true}/>
      </>
  );
}

export default connect(stateToProps)(MyCalendarScreen)
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  FloatButton,
  Modal,
  Typography,
  Badge,
  Input,
  Button,
  Space,
  Pagination,
  Row,
  Col,
  Avatar,
} from 'antd';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  SearchOutlined,
  ArrowUpOutlined,
  CloseOutlined, DownOutlined
} from '@ant-design/icons';
import {
  userYearlyTotalAllowance,
  userYearlyTakenAllowance,
} from '../../../../libs/dateUtils.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import {connect} from "react-redux";
import {getUserFullName, getUserInitialName, getUserTimeInLieuDays} from "../../../../libs/userUtils";
import {MANAGERS_MY_CALENDAR_PATH} from "../../constants/paths";
import {useHistory} from "react-router-dom";

dayjs.extend(isBetween);
const {Title} = Typography;

const MainWrapper = styled(Space)`
  height: 70px;
  width: 195px;
  @media (max-width: 425px) {
    width: 70px;
  }
`;

const TitleWrapper = styled.div`
    
    @media (max-width: 425px) {
      display: none;
    }
  `;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const UserAvatar = (props) => {
  const {user, companies, leaveBookings, leaveTypes, departments, bankHolidays, withCalendarLink, withoutDepartment} = props;
  const history = useHistory();

  const department = departments.find((dep) => dep.get('id') === user.get('department_id'));

  return (
      <MainWrapper key={user.get('id')}>
        <Badge
            count={withCalendarLink ? userYearlyTotalAllowance(user, dayjs().year()) - userYearlyTakenAllowance(user, companies, leaveBookings, leaveTypes, bankHolidays, dayjs().year()) : 0}
            color={'#0023C4'} placement={"end"}>
          <Avatar style={{fontWeight: 'bold', background: user.get('avatar_color')}}
                  size={"large"}>{getUserInitialName(user)}</Avatar>
        </Badge>
        <TitleWrapper>
          <Button type={"link"} size={'large'} onClick={() => history.push(MANAGERS_MY_CALENDAR_PATH + `?user=${user.get('id')}`)}
                  disabled={!withCalendarLink}
                  style={{padding: 0, margin: 0, height: 24, fontWeight: 'bold', fontSize: 18, color: '#0E0B4E', width: 130, overflow: "hidden", textAlign: 'left'}}>{getUserFullName(user)}</Button>
          {!withoutDepartment && <p>{department !== undefined && department.get('name')}</p>}
        </TitleWrapper>
      </MainWrapper>
  );
}

export default connect(stateToProps)(UserAvatar)

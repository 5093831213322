import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/companiesConstants';

export function setIsFetching() {
  return {
    type: actionTypes.SET_IS_FETCHING,
  };
}

export function setIsSaving() {
  return {
    type: actionTypes.SET_IS_SAVING,
  };
}

export function setActiveCompanyId(data) {
  return {
    type: actionTypes.SET_ACTIVE_COMPANY_ID,
    activeCompanyId: data
  };
}

export function fetchCompaniesSuccess(data) {
  return {
    type: actionTypes.FETCH_COMPANIES_SUCCESS,
    companies: data,
  };
}

export function fetchCompaniesFailure(error) {
  return {
    type: actionTypes.FETCH_COMPANIES_FAILURE,
    error,
  };
}

export function fetchCompanies() {
  return (dispatch) => {
    dispatch(setIsFetching());
    return requestsManager
      .fetchEntities('companies')
      .then((res) => dispatch(fetchCompaniesSuccess(res.data)))
      .catch((error) => dispatch(fetchCompaniesFailure(error)));
  };
}

export function updateCompanySuccess(company) {
  return {
    type: actionTypes.UPDATE_COMPANY_SUCCESS,
    company,
  };
}

export function updateCompanyFailure(error) {
  return {
    type: actionTypes.UPDATE_COMPANY_FAILURE,
    error,
  };
}

export function updateCompany(id, company) {
  return (dispatch) => {
    dispatch(setIsSaving());
    return requestsManager
        .updateEntity('companies', id, company)
        .then((res) => dispatch(updateCompanySuccess(res.data)))
        .catch((error) => dispatch(updateCompanyFailure(error)));
  };
}

import ReactOnRails from 'react-on-rails';
import 'bootstrap-loader';
// eslint-disable-next-line import/no-webpack-loader-syntax
import 'expose-loader?exposes=$,jQuery!jquery';
import 'jquery-ujs';

import RouterApp from '../bundles/comments/startup/ClientRouterApp.jsx';
import LoginScreen from '../bundles/comments/components/LoginScreen/LoginScreen.jsx';
import SignupSuccessScreen from '../bundles/comments/components/SignupScreen/SignupScreen.jsx';
import appStore from '../bundles/comments/store/appStore.js';
import NavigationBarApp from '../bundles/comments/startup/NavigationBarApp.jsx';

ReactOnRails.setOptions({
  // traceTurbolinks: process.env.TRACE_TURBOLINKS, // eslint-disable-line no-undef
  // process.env.TRACE_TURBOLINKS -> error: development is not defined
  traceTurbolinks: true,
});

ReactOnRails.register({
  RouterApp,
  NavigationBarApp,
  LoginScreen,
  SignupScreen: SignupSuccessScreen,
});

ReactOnRails.registerStore({
  appStore,
});

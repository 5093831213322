/* eslint new-cap: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/departmentsConstants';

export const $$initialState = Immutable.fromJS({
  $$departments: [],
  fetchDepartmentError: null,
  isFetching: false,
});

export default function departmentsReducer($$state = $$initialState, action = null) {
  const { type, department, departments, error } = action;

  switch (type) {
    case actionTypes.FETCH_DEPARTMENTS_SUCCESS: {
      return $$state.merge({
        $$departments: departments,
        fetchDepartmentError: null,
        isFetching: false,
      });
    }

    case actionTypes.FETCH_DEPARTMENTS_FAILURE: {
      return $$state.merge({
        fetchDepartmentError: error,
        isFetching: false,
      });
    }

    case actionTypes.SET_IS_FETCHING: {
      return $$state.merge({
        isFetching: true,
      });
    }

    default: {
      return $$state;
    }
  }
}

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  FloatButton,
  Modal,
  Typography,
  Badge,
  Input,
  Button,
  Space,
  Pagination,
  Row,
  Col,
  Avatar, Card, Divider,
} from 'antd';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  SearchOutlined,
  ArrowUpOutlined,
  CloseOutlined, DownOutlined, MinusOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import {connect} from "react-redux";
import {
  annualStartDate,
  userCurrentAllowance,
  userCurrentBroughtForward, userYearlyTakenAllowance, userTakenNonDeductedAllowance,
  userYearlyTotalAllowance
} from "../../../../libs/dateUtils";
import {useAuth} from "../../context/auth";
import InLieuTimesDrawer from "../InLiueTimesDrawer/InLieuTimesDrawer";

dayjs.extend(isBetween);
const {Title} = Typography;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const CalendarAllowanceOverview = (props) => {
  const {managerView, company, user, year, leaveTypes, companies, leaveBookings, bankHolidays, onOpenInLieuTimesDrawer} = props;

  const auth = useAuth();

  const deductedBookingCounts = leaveTypes.filter((leaveType) => leaveType.get('deduct_allowance')).map((leaveType) => {
    return {
      type: leaveType,
      count: userYearlyTakenAllowance(user, companies, leaveBookings.filter((leaveBooking) => leaveType.get('id') === leaveBooking.get('leave_type_id')), leaveTypes, bankHolidays, year)
    };
  });

  const nonDeductedBookingCounts = leaveTypes.filter((leaveType) => !leaveType.get('deduct_allowance')).map((leaveType) => {
    return {
      type: leaveType,
      count: userTakenNonDeductedAllowance(user, companies, leaveBookings.filter((leaveBooking) => leaveType.get('id') === leaveBooking.get('leave_type_id')), leaveTypes, bankHolidays, year)
    };
  });

  const annualEnd = company && annualStartDate(company, year).add(1, 'year').subtract(1, 'day');
  const lieuTimeDays = user ? user.get('in_lieu_times').reduce((ilt, value) => value.get('amount') + ilt, 0) : 0;

  return (
      <>
        <Card bodyStyle={{padding: 10}} style={{marginBottom: 10}}>
          <Space align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', marginBottom: 10}}>
            <span style={{fontSize: 22}}>Year end <strong>{dayjs(annualEnd).format('MMM YYYY')}</strong></span>
          </Space>
          <Space align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
            <span style={{fontSize: 16}}>Brought forward</span>
            <span style={{fontSize: 16}}>{dayjs().year() === year ? userCurrentBroughtForward(user, year) : '-'}</span>
          </Space>
          <Space align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
            <span style={{fontSize: 16}}>Contractual allowance</span>
            <span style={{fontSize: 16}}>{userCurrentAllowance(user, year)}</span>
          </Space>
          <Space align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
            {(managerView && auth.user.role === 'company_admin' && dayjs().year() === year) ?
                <Button type={"link"} style={{fontSize: 16, padding: 0, height: 16}} onClick={() => onOpenInLieuTimesDrawer()}>Time in lieu</Button> :
                <span style={{fontSize: 16}}>Time in lieu</span>
            }
            <span style={{fontSize: 16}}>{dayjs().year() === year ? lieuTimeDays : '-'}</span>
          </Space>
          <Divider style={{margin: 10}}/>
          <Space align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
            <span style={{fontSize: 16, fontWeight: 'bold'}}>Total</span>
            <span style={{fontSize: 16, fontWeight: 'bold'}}>{userYearlyTotalAllowance(user, year)}</span>
          </Space>

          {deductedBookingCounts.map((leaveTypeCounts) => (
              <Space key={leaveTypeCounts.type.get('id')} align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
                <Badge color={leaveTypeCounts.type.get('ui_color')} text={<span style={{fontSize: 16}}>{leaveTypeCounts.type.get('name')}</span>} />
                <span style={{fontSize: 16}}>{leaveTypeCounts.count}</span>
              </Space>
          ))}
          <Divider style={{margin: 10}}/>
          <Space align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
            <span style={{fontSize: 20}}>Days remaining</span>
            <span style={{fontSize: 20}}>{userYearlyTotalAllowance(user, year) - deductedBookingCounts.reduce((sum, value) => (sum + value.count), 0)}</span>
          </Space>
        </Card>
        <Card bodyStyle={{padding: 10}} style={{marginBottom: 10}}>
          <Space align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', marginBottom: 10}}>
            <span style={{fontSize: 22}}>Non-deductible leave</span>
          </Space>
          {nonDeductedBookingCounts.filter((booking) => booking.count !== 0).map((leaveTypeCounts) => (
              <Space key={leaveTypeCounts.type.get('id')} align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
                <Badge color={leaveTypeCounts.type.get('ui_color')} text={<span style={{fontSize: 16}}>{leaveTypeCounts.type.get('name')}</span>} />
                <span style={{fontSize: 16}}>{leaveTypeCounts.count}</span>
              </Space>
          ))}
        </Card>
        <Card bodyStyle={{padding: 10}}>
          {leaveTypes.map((leaveType) => (
              <Space key={leaveType.get('id')} align={'start'} style={{flexDirection: 'row', justifyContent: "space-between", width: '100%', margin: 5}}>
                <Badge color={leaveType.get('ui_color')} text={<span style={{fontSize: 16}}>{leaveType.get('name')}</span>} />
              </Space>
          ))}
        </Card>
      </>
  );
}

export default connect(stateToProps)(CalendarAllowanceOverview)

/* eslint new-cap: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/leaveTypesConstants';

export const $$initialState = Immutable.fromJS({
  $$leaveTypes: [],
  fetchLeaveTypeError: null,
  isFetching: false,
});

export default function leaveTypesReducer($$state = $$initialState, action = null) {
  const { type, leaveType, leaveTypes, error } = action;

  switch (type) {
    case actionTypes.FETCH_LEAVE_TYPES_SUCCESS: {
      return $$state.merge({
        $$leaveTypes: leaveTypes,
        fetchLeaveTypeError: null,
        isFetching: false,
      });
    }

    case actionTypes.FETCH_LEAVE_TYPES_FAILURE: {
      return $$state.merge({
        fetchLeaveTypeError: error,
        isFetching: false,
      });
    }

    case actionTypes.SET_IS_FETCHING: {
      return $$state.merge({
        isFetching: true,
      });
    }

    default: {
      return $$state;
    }
  }
}

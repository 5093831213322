import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/usersConstants';

export function setIsFetching() {
  return {
    type: actionTypes.SET_IS_FETCHING,
  };
}

export function setIsSaving() {
  return {
    type: actionTypes.SET_IS_SAVING,
  };
}

export function setIsDeleting() {
  return {
    type: actionTypes.SET_IS_DELETING,
  };
}

export function fetchUsersSuccess(data) {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: data,
  };
}

export function fetchUsersFailure(error) {
  return {
    type: actionTypes.FETCH_USERS_FAILURE,
    error,
  };
}

export function fetchUsers() {
  return (dispatch) => {
    dispatch(setIsFetching());
    return requestsManager
      .fetchEntities('users')
      .then((res) => dispatch(fetchUsersSuccess(res.data)))
      .catch((error) => dispatch(fetchUsersFailure(error)));
  };
}

export function submitUser(user) {
  return (dispatch) => {
    dispatch(setIsSaving());
    return requestsManager
        .submitEntity('users', user)
        .then((res) => dispatch(submitUserSuccess(res.data)))
        .catch((error) => dispatch(submitUserFailure(error)));
  };
}

export function submitUserSuccess(user) {
  return {
    type: actionTypes.SUBMIT_USER_SUCCESS,
    user,
  };
}

export function submitUserFailure(error) {
  return {
    type: actionTypes.SUBMIT_USER_FAILURE,
    error,
  };
}

export function updateUser(id, user) {
  return (dispatch) => {
    dispatch(setIsSaving());
    return requestsManager
        .updateEntity('users', id, user)
        .then((res) => dispatch(updateUserSuccess(res.data)))
        .catch((error) => dispatch(updateUserFailure(error)));
  };
}

export function updateUserSuccess(user) {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    user,
  };
}

export function updateUserFailure(error) {
  return {
    type: actionTypes.UPDATE_USER_FAILURE,
    error,
  };
}

export function deleteUser(userId) {
  return (dispatch) => {
    dispatch(setIsDeleting());
    return requestsManager
        .deleteEntity('users', userId)
        .then((res) => dispatch(deleteUserSuccess(userId)))
        .catch((error) => dispatch(deleteUserFailure(error)));
  };
}

export function deleteUserSuccess(userId) {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    userId,
  };
}

export function deleteUserFailure(error) {
  return {
    type: actionTypes.DELETE_USER_FAILURE,
    error,
  };
}

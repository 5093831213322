import React from 'react';
import BaseComponent from '../../../libs/components/BaseComponent.jsx';

import SignupSuccessScreen from "../components/SignupSuccessScreen/SignupSuccessScreen";

class SignupSuccessContainer extends BaseComponent {

  render() {
    return (
        <SignupSuccessScreen />
    );
  }
}

export default SignupSuccessContainer;

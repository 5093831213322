import React from 'react';
import {Button, Typography, Drawer, Space} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import EmployeeForm from "../EmployeeForm/EmployeeForm";
import dayjs from "dayjs";

const { Title } = Typography;

function EmployeeDrawer(props) {
  const {open, onClose, onFinish, departments, company, inLieuDays, takenDays, nextYearTakenDays, isSaving} = props;

  return (
    <Drawer placement="right" headerStyle={{display: 'none'}} bodyStyle={{padding: 50}} onClose={onClose} open={open}>
      <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 40}}>
        <Title level={2}>Add user</Title>
        <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onClose} style={{width: 50}}/>
      </Space>

      <EmployeeForm
          onCancel={onClose}
          onFinish={onFinish}
          isSaving={isSaving}
          departments={departments}
          company={company}
          inLieuDays={inLieuDays}
          takenDays={takenDays}
          nextYearTakenDays={nextYearTakenDays}
          initValues={{
            department_id: 1,
            work_schedules: [],
            leave_allowances: [{
              year: dayjs().year(),
              allowance: 0,
              brought_forward: 0
            },{
              year: dayjs().year() + 1,
              allowance: 0,
              brought_forward: 0
            }]
          }}
      />
    </Drawer>
  );
}

export default withRouter(EmployeeDrawer);

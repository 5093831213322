import React from "react";
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {Button, DatePicker, Form, Input, Select, Space, Typography, Avatar, Alert, Popconfirm} from "antd";
import {DownOutlined, LoadingOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  countAvailableMidDates,
  PM_FENCE_HOUR,
  AM_FENCE_HOUR,
  userYearlyTakenAllowance, annualStartDate, userYearlyTotalAllowance
} from "../../../../libs/dateUtils";
import {useAuth} from "../../context/auth";

const { TextArea } = Input;
const { Title } = Typography;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

function LeaveBookingForm(props) {
  const {isSaving, onFinish, onApprove, onCancel, onDecline, users, companies, bankHolidays, leaveTypes, leaveBookings, managerView, ownerView, initValues} = props;
  const auth = useAuth();

  const [form] = Form.useForm();
  const userId = Form.useWatch('user_id', form);
  const fromDate = Form.useWatch('from_date', form);
  const toDate = Form.useWatch('to_date', form);
  const fromMid = Form.useWatch('from_mid', form);
  const toMid = Form.useWatch('to_mid', form);
  const leaveTypeId = Form.useWatch('leave_type_id', form);

  const user = users.find((user) => user.get('id') === (userId ? userId : auth.user.id));
  const company = user && companies.find((company) => company.get('id') === user.get('company_id'));
  const companyHolidays = company && bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')}));

  const leaveType = leaveTypes.find((leaveType) => leaveType.get('id') === leaveTypeId);

  const remainingAllowance = user && (userYearlyTotalAllowance(user, dayjs(fromDate).year()) - userYearlyTakenAllowance(user, companies, leaveBookings, leaveTypes, bankHolidays, dayjs(fromDate).year()));

  const daysDiff = fromDate && toDate && leaveType.get('deduct_allowance') && countAvailableMidDates(dayjs(fromDate).hour(fromMid === 'am' ? 0 : 12), dayjs(toDate).hour(toMid=== 'pm' ? 23 : 12), user.get('work_schedules'), companyHolidays);

  const annualEnd = company && annualStartDate(company, dayjs().year()).add(1, 'year');
  const nextAnnualEnd = company && annualStartDate(company, dayjs().year()).add(2, 'year');

  return (
    <Form
        form={form}
        initialValues={initValues}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="Username" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Space.Compact size={"large"} style={{flexWrap: 'wrap'}}>
        {managerView &&
        <Form.Item
            name="user_id"
            rules={[{required: true, message: 'Please choose user!'}]}
        >
          <Select size={"large"} style={{width: 200}} placeholder="Who for">
            {users.map((user) => (
                <Select.Option key={user.get('id')}
                               value={user.get('id')}>{user.get('first_name')} {user.get('last_name')}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        }
        {ownerView &&
        <Form.Item name='user_id' noStyle>
          <Input type="hidden" />
        </Form.Item>
        }

        <Form.Item
            name="leave_type_id"
            rules={[{required: true, message: 'Please input leave type!'}]}
        >
          <Select size={"large"} style={{width: 200}} placeholder="Type" disabled={initValues.approved && !managerView}>
            {leaveTypes.map((leaveType) => (
                <Select.Option key={leaveType.get('id')}
                               value={leaveType.get('id')}>{leaveType.get('name')}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Space.Compact>

      <Space.Compact size={"large"}>
        <Form.Item
            name="from_date"
            rules={[{required: true, message: 'Please input starting date!'}]}
        >
          <DatePicker suffixIcon={<DownOutlined/>} format={(value) => `${value.format('ddd, DD MMM YYYY')}`}
                      size="large" placeholder={"Starting"} style={{width: 200}} disabled={initValues.approved && !managerView}/>
        </Form.Item>

        <Form.Item
            name="from_mid"
            rules={[{required: true, message: 'Please input am or pm!'}]}
        >
          <Select size={"large"} style={{width: 100}} placeholder="AM | PM" disabled={initValues.approved && !managerView}>
            <Select.Option value="am">AM</Select.Option>
            <Select.Option value="pm">PM</Select.Option>
          </Select>
        </Form.Item>
      </Space.Compact>

      <Space.Compact size={"large"}>
        <Form.Item
            name="to_date"
            rules={[{required: true, message: 'Please input ending date!'}]}
        >
          <DatePicker suffixIcon={<DownOutlined/>} format={(value) => `${value.format('ddd, DD MMM YYYY')}`}
                      size="large" placeholder={"Ending"} style={{width: 200}} disabled={initValues.approved && !managerView}/>
        </Form.Item>

        <Form.Item
            name="to_mid"
            rules={[{required: true, message: 'Please input am or pm!'}]}
        >
          <Select size={"large"} style={{width: 100}} placeholder="AM | PM" disabled={initValues.approved && !managerView}>
            <Select.Option value="am">AM</Select.Option>
            <Select.Option value="pm">PM</Select.Option>
          </Select>
        </Form.Item>
      </Space.Compact>

      <Form.Item name="notes">
        <TextArea size={"large"} rows={3} placeholder="Reason (optional)" disabled={initValues.approved && !managerView}/>
      </Form.Item>

      {!initValues.id ?
        <Form.Item>
          {(dayjs(fromDate).year() !== dayjs(toDate).year()) &&
          <Alert message={`You are not allowed to book leave spanning multiple financial years.`} type="warning" showIcon={true} />
          }
          {nextAnnualEnd && nextAnnualEnd.isBefore(toDate) &&
          <Alert message={`You are not allowed to book days after next financial year.`} type="warning" showIcon={true} />
          }
          {fromDate && toDate && remainingAllowance < daysDiff &&
          <Alert message={`You have only ${remainingAllowance} days allowances left.`} type="warning" showIcon={true} />
          }

          <Space align={'center'} style={{justifyContent: "space-between", flexWrap: 'wrap', width: '100%', marginTop: 20}} size={20}>
            <Title level={5}>Takes <Avatar size={"small"} style={{backgroundColor: '#0023c4'}}>{daysDiff}</Avatar> days from allowance</Title>
            <Button
                size={"large"}
                type="primary"
                htmlType="submit"
                disabled={isSaving || (fromDate && toDate && remainingAllowance < daysDiff) || (nextAnnualEnd && nextAnnualEnd.isBefore(toDate))}
                icon={isSaving ? <LoadingOutlined /> : null}>
              Send Request
            </Button>
          </Space>
        </Form.Item> :
          ((!initValues.approved && !initValues.declined) && managerView ?
          <Space align={'end'} style={{justifyContent: "flex-end", width: '100%', marginTop: 20}} size={20}>
            <Button size={"large"} type="primary" htmlType="submit" onClick={() => onApprove(form, form.getFieldsValue())}>
            Approve
            </Button>
            <Button size={"large"} type="default" htmlType="submit" onClick={() => onDecline(form, form.getFieldsValue())}>
            Decline
            </Button>
          </Space> :
          <Space align={'end'} style={{justifyContent: "flex-end", width: '100%', marginTop: 20}} size={20}>
            <Button size={"large"} type="primary" htmlType="submit" onClick={() => onFinish(form, form.getFieldsValue())}>
              Save
            </Button>
            {(dayjs().isBefore(fromDate) || managerView) &&
              <Popconfirm title="Are you sure to remove?" onConfirm={() => onCancel(form, form.getFieldsValue())}>
                <Button size={"large"} type="default">
                  Remove
                </Button>
              </Popconfirm>
            }
          </Space>)
      }
    </Form>
  );
}

export default connect(stateToProps)(withRouter(LeaveBookingForm));

import {Button, DatePicker, Form, Input, Select, Space, InputNumber, Checkbox, Divider} from "antd";
import {LoadingOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import React from "react";
import {withRouter} from "react-router-dom";
import dayjs from 'dayjs'

const { TextArea } = Input;

function InLieuTimesForm(props) {
  const [form] = Form.useForm();
  const {onFinish, initValues, isSaving} = props;

  return (
    <Form
        form={form}
        initialValues={{...initValues}}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.List name="in_lieu_times">
        {(fields, { add, remove }) => (
            <>
              {fields.map(({key, name, ...restField}) => (
                  <Space key={key} align="baseline">
                    <Form.Item style={{width: 90}}>
                      <span>{dayjs(form.getFieldValue('in_lieu_times')[name]?.created_at).format('ddd, DD MMM YYYY')}</span>
                    </Form.Item>

                    <Form.Item label="id" name={[name, 'id']} noStyle>
                      <Input type="hidden" />
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'amount']}>
                      <InputNumber placeholder="Amount" precision={1} step={0.5}/>
                    </Form.Item>

                    <Form.Item  name={[name, 'comments']}>
                      <Input placeholder="Comments"/>
                    </Form.Item>

                    <DeleteOutlined onClick={() => remove(name)} />
                  </Space>
              ))}

              <Form.Item>
                <Button size={'small'} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            </>
        )}
      </Form.List>

      <Form.Item>
        <Button style={{marginTop: 40}}  size={"large"} type="primary" htmlType="submit" disabled={isSaving} icon={isSaving ? <LoadingOutlined /> : null}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRouter(InLieuTimesForm);

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AppLayout from '../layout/Layout.jsx';
import WallChartContainer from "../containers/WallChartContainer";
import MyCalendarContainer from "../containers/MyCalendarContainer";
import ManagerHomeContainer from '../containers/ManagerHomeContainer.jsx';
import ManagerMyCalendarContainer from '../containers/ManagerMyCalendarContainer.jsx';
import ManagerSettingsContainer from '../containers/ManagerSettingsContainer.jsx';
import ManagerUsersContainer from '../containers/ManagerUsersContainer.jsx';
import RouterAuthContainer from '../containers/RouterAuthContainer.jsx';
import RouterLoginContainer from '../containers/RouterLoginContainer.jsx';
import RouterForgotPasswordContainer from '../containers/RouterForgotPasswordContainer.jsx';
import RouterResetPasswordContainer from '../containers/RouterResetPasswordContainer.jsx';
import HomeContainer from "../containers/HomeContainer";
import SignupSuccessContainer from "../containers/SignupSuccessContainer";
import LandingPageContainer from '../containers/LandingPageContainer.jsx';
import {PrivateRoute, ManagerPrivateRoute, HomeRoute} from '../context/auth.jsx';
import {
  ROOT_PATH,
  LOGIN_ROUTER_PATH,
  SIGNUP_ROUTER_PATH,
  FORGOT_PASSWORD_ROUTER_PATH,
  HOME_PATH,
  WALLCHART_PATH,
  MANAGERS_WALLCHART_PATH,
  MANAGERS_USERS_PATH,
  CREATE_PASSWORD_ROUTER_PATH,
  MANAGERS_MY_CALENDAR_PATH,
  MY_CALENDAR_PATH,
  USER_SETTINGS_PATH,
  MANAGERS_COMPANY_SETTINGS_PATH,
  MANAGERS_USER_SETTINGS_PATH,
  SIGNUP_SUCCESS_ROUTER_PATH,
} from "../constants/paths";
import UserSettingsScreen from "../components/UserSettingsScreen/UserSettingsScreen";
import ForgotPasswordScreen from "../components/ForgotPasswordScreen/ForgotPasswordScreen";


export default (
    <AppLayout>
      <Switch>
        <Route path={SIGNUP_ROUTER_PATH} component={RouterAuthContainer}/>
        <Route path={SIGNUP_SUCCESS_ROUTER_PATH} component={SignupSuccessContainer}/>
        <Route path={LOGIN_ROUTER_PATH} component={RouterLoginContainer}/>
        <Route path={FORGOT_PASSWORD_ROUTER_PATH} component={ForgotPasswordScreen}/>
        <Route path={CREATE_PASSWORD_ROUTER_PATH} component={RouterResetPasswordContainer}/>

        <HomeRoute path={ROOT_PATH} exact>
          <LandingPageContainer />
        </HomeRoute>

        <ManagerPrivateRoute path={MANAGERS_WALLCHART_PATH}>
          <ManagerHomeContainer/>
        </ManagerPrivateRoute>
        <ManagerPrivateRoute path={MANAGERS_MY_CALENDAR_PATH}>
          <ManagerMyCalendarContainer />
        </ManagerPrivateRoute>
        <ManagerPrivateRoute path={MANAGERS_USERS_PATH}>
          <ManagerUsersContainer/>
        </ManagerPrivateRoute>
        <ManagerPrivateRoute path={MANAGERS_COMPANY_SETTINGS_PATH}>
          <ManagerSettingsContainer/>
        </ManagerPrivateRoute>
        <ManagerPrivateRoute path={MANAGERS_USER_SETTINGS_PATH}>
          <UserSettingsScreen/>
        </ManagerPrivateRoute>

        <PrivateRoute path={HOME_PATH}>
          <HomeContainer/>
        </PrivateRoute>
        <PrivateRoute path={WALLCHART_PATH}>
          <WallChartContainer/>
        </PrivateRoute>
        <PrivateRoute path={MY_CALENDAR_PATH}>
          <MyCalendarContainer/>
        </PrivateRoute>
        <PrivateRoute path={USER_SETTINGS_PATH}>
          <UserSettingsScreen/>
        </PrivateRoute>
      </Switch>
    </AppLayout>
)

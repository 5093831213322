import React from 'react';
import {Button, Typography, Drawer, Space} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import LeaveBookingForm from "../LeaveBookingForm/LeaveBookingForm";
import {useAuth} from "../../context/auth";

const { Title } = Typography;

function BookingDrawer(props) {
  const {open, onClose, onFinish, ownerView} = props;
  const auth = useAuth();

  return (
    <Drawer placement="right" headerStyle={{display: 'none'}} bodyStyle={{padding: 50}} onClose={onClose}
            open={open}>
      <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 40}}>
        <Title level={2}>Book time off</Title>
        <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onClose} style={{width: 50}}/>
      </Space>

      <LeaveBookingForm
          onCancel={onClose}
          onFinish={onFinish}
          ownerView={ownerView}
          initValues={{
            leave_type_id: 1,
            user_id: auth.user.id
          }}
      />
    </Drawer>
  );
}

export default withRouter(BookingDrawer);

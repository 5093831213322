import React from "react";
import { withRouter } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  props.history.listen(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
  });

  return <></>;
};

export default withRouter(AuthVerify);
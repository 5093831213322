import axios from "axios";
import {useState} from "react";

const jwtInterceoptor = axios.create({});

jwtInterceoptor.interceptors.request.use((config) => {
  config.headers.common["Authorization"] = localStorage.getItem('token');
  return config;
});
//
// jwtInterceoptor.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     async (error) => {
//       if (error.response.status === 401) {
//         console.log('-------------------------------------------')
//         const authData = JSON.parse(localStorage.getItem("tokens"));
//         const payload = {
//           access_token: authData.access_token,
//           refresh_token: authData.refreshToken,
//         };
//
//         let apiResponse = await axios.post(
//             "/auth/refreshtoken",
//             payload
//         );
//         localStorage.setItem("token", JSON.stringify(apiResponse.data));
//         error.config.headers[
//             "Authorization"
//             ] = `bearer ${apiResponse.data.access_token}`;
//         return axios(error.config);
//       } else {
//         return Promise.reject(error);
//       }
//     }
// );

export default jwtInterceoptor;
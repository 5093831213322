import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/leaveBookingsConstants';

export function setIsFetching() {
  return {
    type: actionTypes.SET_IS_FETCHING,
  };
}

export function setIsSaving() {
  return {
    type: actionTypes.SET_IS_SAVING,
  };
}

export function fetchLeaveBookingsSuccess(data) {
  return {
    type: actionTypes.FETCH_LEAVE_BOOKINGS_SUCCESS,
    leaveBookings: data,
  };
}

export function fetchLeaveBookingsFailure(error) {
  return {
    type: actionTypes.FETCH_LEAVE_BOOKINGS_FAILURE,
    error,
  };
}

export function submitLeaveBookingSuccess(leaveBooking) {
  return {
    type: actionTypes.SUBMIT_LEAVE_BOOKING_SUCCESS,
    leaveBooking,
  };
}

export function submitLeaveBookingFailure(error) {
  return {
    type: actionTypes.SUBMIT_LEAVE_BOOKING_FAILURE,
    error,
  };
}

export function updateLeaveBookingSuccess(leaveBooking) {
  return {
    type: actionTypes.UPDATE_LEAVE_BOOKING_SUCCESS,
    leaveBooking,
  };
}

export function updateLeaveBookingFailure(error) {
  return {
    type: actionTypes.UPDATE_LEAVE_BOOKING_FAILURE,
    error,
  };
}

export function fetchLeaveBookings() {
  return (dispatch) => {
    dispatch(setIsFetching());
    return requestsManager
      .fetchEntities('leave_bookings')
      .then((res) => dispatch(fetchLeaveBookingsSuccess(res.data)))
      .catch((error) => dispatch(fetchLeaveBookingsFailure(error)));
  };
}

export function submitLeaveBooking(leaveBooking) {
  return (dispatch) => {
    dispatch(setIsSaving());
    return requestsManager
        .submitEntity('leave_bookings', leaveBooking)
        .then((res) => dispatch(submitLeaveBookingSuccess(res.data)))
        .catch((error) => dispatch(submitLeaveBookingFailure(error)));
  };
}

export function updateLeaveBooking(id, leaveBooking) {
  return (dispatch) => {
    dispatch(setIsSaving());
    return requestsManager
        .updateEntity('leave_bookings', id, leaveBooking)
        .then((res) => dispatch(updateLeaveBookingSuccess(res.data)))
        .catch((error) => dispatch(updateLeaveBookingFailure(error)));
  };
}

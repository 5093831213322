import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseComponent from '../../../libs/components/BaseComponent.jsx';
import WallChartScreen from '../components/WallChartScreen/WallChartScreen.jsx';

function stateToProps(state) {
  return {
    uStore: state.$$usersStore,
    ltStore: state.$$leaveTypesStore,
    depStore: state.$$departmentsStore,
    lbStore: state.$$leaveBookingsStore,
    comStore: state.$$companiesStore
  };
}

class WallChartContainer extends BaseComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    uStore: PropTypes.oneOfType([PropTypes.object]).isRequired,
    ltStore: PropTypes.oneOfType([PropTypes.object]).isRequired,
    lbStore: PropTypes.oneOfType([PropTypes.object]).isRequired,
    depStore: PropTypes.oneOfType([PropTypes.object]).isRequired,
    comStore: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  render() {
    const { uStore, ltStore, depStore, lbStore, comStore } = this.props;

    return (
        <WallChartScreen {...{uStore, ltStore, depStore, lbStore, comStore }} />
    );
  }
}

export default connect(stateToProps)(WallChartContainer);

import React, {useState} from 'react';
import {Button, Typography, Drawer, Space} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {useHistory, withRouter} from 'react-router-dom';
import UserPasswordForm from "../UserPasswordForm/UserPasswordForm";
import {connect} from "react-redux";
import dayjs from "dayjs";
import {useAuth} from "../../context/auth";
import {bindActionCreators} from "redux";
import * as usersActionCreators from "../../actions/usersActionCreators";
import UserSettingsForm from "../UserSettingsForm/UserSettingsForm";

const { Title } = Typography;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$usersStore.get('isSaving'),
  };
}

function UserSettingsScreen(props) {
  const {users, onClose, departments, isSaving} = props;

  const auth = useAuth();
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [updatingSettings, setUpdatingSettings] = useState(false);

  const {updateUser} = bindActionCreators(usersActionCreators, props.dispatch);

  const user = users.size > 0 && auth.user.id ? Object.fromEntries(users.find((user) => user.get('id') === auth.user.id)) : {};

  const onFinish = (form, values) => {
    setUpdatingPassword(true);
    setUpdatingSettings(false);
    updateUser(values.id, {
      user: {
        password: values.password
      }
    });
  };

  const onFinishSettings = (form, values) => {
    setUpdatingSettings(true);
    setUpdatingPassword(false);
    updateUser(values.id, {
      user: values
    });
  };

  return (
      <div style={{display: 'flex', justifyContent: "center", marginTop: 50}}>
        <div style={{width: 500}}>
          <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 20}}>
            <Title level={2}>User Settings</Title>
          </Space>

          <UserPasswordForm
              isSaving={updatingPassword && isSaving}
              onFinish={onFinish}
              initValues={{
                ...user
              }}
          />

          <UserSettingsForm
              isSaving={updatingSettings && isSaving}
              onFinish={onFinishSettings}
              initValues={{
                ...user
              }}
          />
        </div>
      </div>
  );
}

export default connect(stateToProps)(withRouter(UserSettingsScreen));

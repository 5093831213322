import React, {useState} from 'react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {Typography} from "antd";
import {getAllMdDates, daysOfTheWeekOffset, getDaysInMonth, getDaysInDayRange} from "../../../../libs/dateUtils";
import CalendarDateCell from "../CalendarDateCell/CalendarDateCell";

dayjs.extend(isBetween)

const VtMonthCalendar = ({
                            year,
                            month,
                           user,
                           userBookings,
                           companyHolidays,
                           onChangeActiveBooking,
                           selection,
                           hoveration,
                           dragStartDate,
                           dragStartDateMid,
                           onChangeDragStartDate,
                           onChangeDragStartDateMid,
                           onChangeDragEndDate,
                           onChangeDragEndDateMid,
                           onChangeDragHoverDate,
                           onChangeDragHoverDateMid,
                           ownerView
    }) => {

  const jsDays = getDaysInMonth(year, month);

  const mdDatesInMonth = getAllMdDates(jsDays, userBookings, Array.from(companyHolidays), selection, hoveration);

  const firstDayOfWeek = dayjs(`${year}-${month}-01`).day()

  const offsetDays =
      firstDayOfWeek !== 0
          ? new Array(firstDayOfWeek - 1).fill('')
          : new Array(Number(daysOfTheWeekOffset[firstDayOfWeek])).fill('')


  return (
    <div className='content' style={{display: 'flex', padding: 0, flexWrap: 'wrap', width: 280}}>
      {offsetDays.map((_, index) => (
          <div key={index} className='month-offset-date'>
            <div className={'am'} />
            <div className={'pm'} />
          </div>
      ))}
      {mdDatesInMonth.map((mdDate) => {
        return <CalendarDateCell key={`${mdDate[0].date}`} mdDate={mdDate} user={user}
                                 ownerView={ownerView}
                                 dragStartDate={dragStartDate}
                                 dragStartDateMid={dragStartDateMid}
                                 onChangeDragStartDate={onChangeDragStartDate}
                                 onChangeDragStartDateMid={onChangeDragStartDateMid}
                                 onChangeActiveBooking={onChangeActiveBooking}
                                 onChangeDragEndDate={onChangeDragEndDate}
                                 onChangeDragEndDateMid={onChangeDragEndDateMid}
                                 onChangeDragHoverDate={onChangeDragHoverDate}
                                 onChangeDragHoverDateMid={onChangeDragHoverDateMid}
        />
      })}
    </div>
  )
}

export default VtMonthCalendar

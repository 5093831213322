import React from 'react';
import {Button, Menu, Typography, Drawer, Space} from 'antd';
import {
  CloseOutlined, HomeOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import {useHistory, withRouter} from 'react-router-dom';
import {
  HOME_PATH,
  MANAGERS_MY_CALENDAR_PATH,
  MANAGERS_COMPANY_SETTINGS_PATH,
  MANAGERS_USERS_PATH,
  MANAGERS_WALLCHART_PATH, MY_CALENDAR_PATH, USER_SETTINGS_PATH, WALLCHART_PATH
} from "../../constants/paths";
import {useAuth} from "../../context/auth";

const { Title } = Typography;

function NavigationDrawer(props) {
  const {open, onClose, title} = props;
  const auth = useAuth();
  const history = useHistory();

  return (
    <Drawer placement="left" headerStyle={{display: 'none'}} bodyStyle={{padding: 20, background: '#0e0b44'}} onClose={onClose}
            open={open} destroyOnClose>
      <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 40}}>
        <Title style={{background: '#0e0b44', color: '#FFFFFF'}} level={4}>{title}</Title>
        <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onClose} style={{width: 50, background: "#0e0b44"}}/>
      </Space>
      <Menu style={{background: '#0e0b44', color: '#FFFFFF'}} items={(auth.user.role === 'company_admin' || auth.user.role === 'accountant') ? [
        {
          key: 'wallchart',
          icon: <UnorderedListOutlined />,
          label: 'Wall Chart',
          onClick: () => {history.push(MANAGERS_WALLCHART_PATH); onClose();}
        },
        {
          key: 'mycalendar',
          icon: <ScheduleOutlined />,
          label: 'My Calendar',
          onClick: () => {history.push(MANAGERS_MY_CALENDAR_PATH); onClose();}
        },
        {
          key: 'users',
          label: 'Users',
          icon: <TeamOutlined />,
          onClick: () => {history.push(MANAGERS_USERS_PATH); onClose();}
        },
        {
          key: 'settings',
          label: 'Settings',
          icon: <SettingOutlined />,
          onClick: () => {history.push(MANAGERS_COMPANY_SETTINGS_PATH); onClose();}
        },
      ] : [
          {
            key: 'home',
            icon: <HomeOutlined />,
            label: 'Home',
            onClick: () => {history.push(HOME_PATH); onClose();}
          },
          {
            key: 'wallchart',
            icon: <UnorderedListOutlined />,
            label: 'Wall Chart',
            onClick: () => {history.push(WALLCHART_PATH); onClose();}
          },
          {
            key: 'mycalendar',
            icon: <ScheduleOutlined />,
            label: 'My Calendar',
            onClick: () => {history.push(MY_CALENDAR_PATH); onClose();}
          },
          {
            key: 'userSettings',
            icon: <ScheduleOutlined />,
            label: 'Settings',
            onClick: () => {history.push(USER_SETTINGS_PATH); onClose();}
          },
        ]}>

      </Menu>
    </Drawer>
  );
}

export default withRouter(NavigationDrawer);

import React from 'react';
import {Button, Typography, Drawer, Space} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import InLieuTimesForm from "../InLieuTimesForm/InLieuTimesForm";

const { Title } = Typography;

function InLieuTimesDrawer(props) {
  const {open, onClose, onFinish, user, isSaving} = props;

  return (
    <Drawer placement="right" headerStyle={{display: 'none'}} bodyStyle={{padding: 30, paddingTop: 50}} onClose={onClose}
            open={open} destroyOnClose>
      <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 40}}>
        <Title level={2}>Time In Lieu</Title>
        <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onClose} style={{width: 50}}/>
      </Space>

      <InLieuTimesForm
          onCancel={onClose}
          onFinish={onFinish}
          isSaving={isSaving}
          initValues={user ? {...Object.fromEntries(user), in_lieu_times: Array.from(user.get('in_lieu_times')).map((mp) => Object.fromEntries(mp))} : {}
          }
      />
    </Drawer>
  );
}

export default withRouter(InLieuTimesDrawer);

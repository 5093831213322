import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const getUserFullName = (user) => {
  const firstName = user.get('first_name');
  const lastName = user.get('last_name');

  return `${firstName} ${lastName}`;
}

export const getUserInitialName = (user) => {
  const firstName = user.get('first_name');
  const lastName = user.get('last_name');

  return `${firstName && firstName[0]}${lastName && lastName[0]}`;
}

export const USER_AVATAR_COLORS = ['navy', 'darkgreen', 'darkorange', 'brown', 'pink', 'purple'];

export const getUserTimeInLieuDays = (user) => {
  return (user ? user.get('in_lieu_times').reduce((ilt, value) => value.get('amount') + ilt, 0) : 0);
}
import React from 'react';
import BaseComponent from 'libs/components/BaseComponent';
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import css from './ForgotPasswordScreen.module.scss';

export default class ForgotPasswordScreen extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
        <div className={css.loginFormContainer}>
          <ForgotPasswordForm />
        </div>
    );
  }
}

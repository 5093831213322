export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

export const SET_IS_FETCHING = 'SET_IS_FETCHING';

export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const SET_IS_SAVING = 'SET_IS_COMPANY_SAVING';

export const SET_ACTIVE_COMPANY_ID = 'SET_ACTIVE_COMPANY_ID';
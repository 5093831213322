import {Button, DatePicker, Form, Input, Tabs, Typography, Select, Space, InputNumber, Checkbox, Row, Col} from "antd";
import {LoadingOutlined, DownOutlined, MinusOutlined, PlusOutlined, PauseOutlined} from "@ant-design/icons";
import React from "react";
import {withRouter} from "react-router-dom";
import {getWeekdayName} from "../../../../libs/dateUtils";
import dayjs from "dayjs";
import {USER_AVATAR_COLORS} from "../../../../libs/userUtils";

const {Title} = Typography;

const default_schedules = [
  {
    week_day: 1,
    am_available: true,
    am_from: 9,
    am_to: 12,
    pm_available: true,
    pm_from: 13,
    pm_to: 17,
  },
  {
    week_day: 2,
    am_available: true,
    am_from: 9,
    am_to: 12,
    pm_available: true,
    pm_from: 13,
    pm_to: 17,
  },
  {
    week_day: 3,
    am_available: true,
    am_from: 9,
    am_to: 12,
    pm_available: true,
    pm_from: 13,
    pm_to: 17,
  },
  {
    week_day: 4,
    am_available: true,
    am_from: 9,
    am_to: 12,
    pm_available: true,
    pm_from: 13,
    pm_to: 17,
  },
  {
    week_day: 5,
    am_available: true,
    am_from: 9,
    am_to: 12,
    pm_available: true,
    pm_from: 13,
    pm_to: 17,
  },
  {
    week_day: 6,
    am_available: false,
    am_from: 9,
    am_to: 12,
    pm_available: false,
    pm_from: 13,
    pm_to: 17,
  },
  {
    week_day: 0,
    am_available: false,
    am_from: 9,
    am_to: 12,
    pm_available: false,
    pm_from: 13,
    pm_to: 17,
  },
];

function EmployeeForm(props) {
  const [form] = Form.useForm();
  const {onFinish, departments, company, inLieuDays, takenDays, nextYearTakenDays, initValues, isSaving} = props;

  return (
    <Form
        layout="vertical"
        form={form}
        initialValues={{...initValues,
          work_schedules: (initValues.work_schedules.length === 0 ? default_schedules : initValues.work_schedules)}}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Tabs
          defaultActiveKey="details"
          size={'large'}
          items={[
            {
              label: `Details`,
              key: 'details',
              children: <>
                <Form.Item name="first_name" rules={[{required: true, message: 'Please input first name!'}]}>
                  <Input size={"large"} type="text" placeholder="First name" style={{borderWidth: '0 0 2px', borderRadius: 0}} />
                </Form.Item>

                <Form.Item name="last_name" rules={[{required: true, message: 'Please input last name!'}]}>
                  <Input size={"large"} type="text" placeholder="Last name" style={{borderWidth: '0 0 2px', borderRadius: 0}} />
                </Form.Item>

                <Form.Item name="email" rules={[{required: true, message: 'Please input email address!'}]}>
                  <Input size={"large"} type="email" placeholder="Email address" style={{borderWidth: '0 0 2px', borderRadius: 0}} disabled={initValues.id} />
                </Form.Item>

                <Form.Item name="password" rules={[
                  { min: 8, message: 'Password must be at least 8 characters' },
                  { pattern: '[a-zA-Z]', message: 'Password must include letters' },
                  { pattern: '[0-9]', message: 'Password must include numbers' }]}>
                  <Input.Password size={"large"} type="password" placeholder="Password" style={{borderWidth: '0 0 2px', borderRadius: 0}} />
                </Form.Item>

                <Form.Item
                    name="department_id"
                    rules={[{required: true, message: 'Please input department!'}]}
                >
                  <Select size={"large"} style={{width: 250}} placeholder="Type">
                    {departments.map((department) => (
                        <Select.Option key={department.get('id')} value={department.get('id')}>{department.get('name')}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                    name="avatar_color"
                >
                  <Select size={"large"} style={{width: 250}} placeholder="Avatar color">
                    {USER_AVATAR_COLORS.map((color) => (
                        <Select.Option key={color} value={color}>{color}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                    name="birth_date"
                >
                  <DatePicker suffixIcon={<DownOutlined/>} format={(value) => `${value.format('DD/MM/YYYY')}`}
                              size="large" placeholder={"Date of Birth"} style={{width: 250}}/>
                </Form.Item>

                <Form.Item
                    name="employment_from"
                >
                  <DatePicker suffixIcon={<DownOutlined/>} format={(value) => `${value.format('DD/MM/YYYY')}`}
                              size="large" placeholder={"Starting"} style={{width: 250}}/>
                </Form.Item>
              </>,
            },
            {
              label: `Schedule`,
              key: 'schedule',
              children: <>
                <Form.List name="work_schedules">
                  {(fields, { add, remove }) => (
                      <>
                        {fields.map(({key, name, ...restField}) => (
                            <Space key={key} align="baseline" wrap={true} style={{width: '100%', justifyContent: 'center'}}>
                              <Form.Item label="id" name={[name, 'id']} noStyle>
                                <Input type="hidden" />
                              </Form.Item>

                              <span style={{width: 70, display: 'block', fontWeight: 'bold'}}>{getWeekdayName(form.getFieldValue('work_schedules')[name].week_day)}</span>

                              <span style={{width: '100%', flexWrap: 'wrap', display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'center'}}>
                                <span style={{width: 210, display: 'flex', alignItems: 'center', gap: 10}}>
                                  <Form.Item
                                      {...restField}
                                      label=""
                                      name={[name, 'am_available']}
                                      valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>

                                  <Form.Item noStyle
                                             shouldUpdate={(prev, cur) =>
                                                 prev?.work_schedules[name]?.am_available !==
                                                 cur?.work_schedules[name]?.am_available}>
                                    {() => {
                                      return (
                                          <Form.Item {...restField} label="" name={[name, 'am_from']}>
                                            <InputNumber
                                                style={{width: 70}}
                                                disabled={!form.getFieldValue([
                                                  "work_schedules",
                                                  name,
                                                  "am_available"
                                                ])}
                                                formatter={(value) => `${value}:00`} parser={(value) => value.split(':')[0]}
                                                max={24} min={0} />
                                          </Form.Item>)
                                    }}
                                  </Form.Item>

                                  <Form.Item>to</Form.Item>

                                  <Form.Item noStyle
                                             shouldUpdate={(prev, cur) =>
                                                 prev?.work_schedules[name]?.am_available !==
                                                 cur?.work_schedules[name]?.am_available}>
                                    {() => {
                                      return (
                                          <Form.Item {...restField} label="" name={[name, 'am_to']}>
                                            <InputNumber
                                                style={{width: 70}}
                                                disabled={!form.getFieldValue([
                                                  "work_schedules",
                                                  name,
                                                  "am_available"
                                                ])}
                                                formatter={(value) => `${value}:00`} parser={(value) => value.split(':')[0]}
                                                max={24} min={0} />
                                          </Form.Item>)
                                    }}
                                  </Form.Item>
                                </span>

                                <span style={{width: 210, display: 'flex', alignItems: 'center', gap: 10}}>
                                  <Form.Item
                                      {...restField}
                                      label=""
                                      name={[name, 'pm_available']}
                                      valuePropName="checked"
                                  >
                                    <Checkbox />
                                  </Form.Item>


                                  <Form.Item noStyle
                                             shouldUpdate={(prev, cur) =>
                                                 prev?.work_schedules[name]?.pm_available !==
                                                 cur?.work_schedules[name]?.pm_available}>
                                    {() => {
                                      return (
                                          <Form.Item {...restField} label="" name={[name, 'pm_from']}>
                                            <InputNumber
                                                style={{width: 70}}
                                                disabled={!form.getFieldValue([
                                                  "work_schedules",
                                                  name,
                                                  "pm_available"
                                                ])}
                                                formatter={(value) => `${value}:00`} parser={(value) => value.split(':')[0]}
                                                max={24} min={0} />
                                          </Form.Item>)
                                    }}
                                  </Form.Item>

                                  <Form.Item>to</Form.Item>

                                  <Form.Item noStyle
                                             shouldUpdate={(prev, cur) =>
                                                 prev?.work_schedules[name]?.pm_available !==
                                                 cur?.work_schedules[name]?.pm_available}>
                                    {() => {
                                      return (
                                          <Form.Item {...restField} label="" name={[name, 'pm_to']}>
                                            <InputNumber
                                                style={{width: 70}}
                                                disabled={!form.getFieldValue([
                                                  "work_schedules",
                                                  name,
                                                  "pm_available"
                                                ])}
                                                formatter={(value) => `${value}:00`} parser={(value) => value.split(':')[0]}
                                                max={24} min={0} />
                                          </Form.Item>)
                                    }}
                                  </Form.Item>
                                </span>
                              </span>
                            </Space>
                        ))}
                      </>
                  )}
                </Form.List>
              </>,
            },
            {
              label: `Allowance`,
              key: 'allowance',
              children: <>

                <Form.List name="leave_allowances" >
                  {(fields, { add, remove }) => (
                      <>
                        <Title level={5}>Your leave year starts in {dayjs().month(company.get('annual_reset_month')).format('MMMM')}</Title>
                        {fields.sort((a,b) => form.getFieldValue("leave_allowances")[a.name].year - form.getFieldValue("leave_allowances")[b.name].year)
                            .map(({key, name, ...restField}) => (
                            <>
                              <Title level={4}>
                                {form.getFieldValue("leave_allowances")[name].year > dayjs().year() ? <span>Next year</span> : <span>Current year</span>}: {dayjs().month(company.get('annual_reset_month')).format('MMMM')} to {dayjs().month(company.get('annual_reset_month') + 11).format('MMMM')} {form.getFieldValue("leave_allowances")[name].year}
                              </Title>

                              <Space key={key} align="end" style={{marginTop: 20, flexWrap: "wrap"}}>
                                <Form.Item {...restField} label="Brought Fwd" name={[name, 'brought_forward']}>
                                  <InputNumber placeholder="Brought Fwd" precision={1} step={0.5}/>
                                </Form.Item>

                                <Form.Item>
                                  <PlusOutlined />
                                </Form.Item>

                                <Form.Item {...restField} label="Allowance" name={[name, 'allowance']}>
                                  <InputNumber placeholder="Allowance" precision={1} step={0.5}/>
                                </Form.Item>

                                {form.getFieldValue("leave_allowances")[name].id &&
                                <>
                                  <Form.Item>
                                    <PlusOutlined />
                                  </Form.Item>

                                  <div className={"ant-form-item"} style={{marginBottom: 24}}>
                                    <Col className={"ant-form-item-label"}>
                                      <label>In Lieu</label>
                                    </Col>
                                    <Col className={"ant-form-item-control"}>
                                      <InputNumber
                                          readOnly={true}
                                          value={form.getFieldValue("leave_allowances")[name].year === dayjs().year() ? inLieuDays : 0}
                                          precision={1}
                                          step={0.5}/>
                                    </Col>
                                  </div>

                                  <Form.Item>
                                    <MinusOutlined />
                                  </Form.Item>

                                  <div className={"ant-form-item"} style={{marginBottom: 24}}>
                                    <Col className={"ant-form-item-label"}>
                                      <label>Taken</label>
                                    </Col>
                                    <Col className={"ant-form-item-control"}>
                                      <InputNumber
                                          readOnly={true}
                                          value={(form.getFieldValue("leave_allowances")[name].year === dayjs().year() ? takenDays : nextYearTakenDays)}
                                          style={{fontWeight: 'bold'}}
                                          precision={1}
                                          step={0.5}/>
                                    </Col>
                                  </div>

                                  <Form.Item>
                                    <PauseOutlined style={{transform: 'rotate(90deg)'}} />
                                  </Form.Item>

                                  <div className={"ant-form-item"} style={{marginBottom: 24}}>
                                    <Col className={"ant-form-item-label"}>
                                      <label>Remaining</label>
                                    </Col>
                                    <Col className={"ant-form-item-control"}>
                                      <InputNumber
                                          readOnly={true}
                                          value={form.getFieldValue("leave_allowances")[name].brought_forward + form.getFieldValue("leave_allowances")[name].allowance + (form.getFieldValue("leave_allowances")[name].year === dayjs().year() ? inLieuDays : 0) - (form.getFieldValue("leave_allowances")[name].year === dayjs().year() ? takenDays : nextYearTakenDays)}
                                          style={{fontWeight: 'bold'}}
                                          precision={1}
                                          step={0.5}/>
                                    </Col>
                                  </div>
                                </>
                                }

                                <Form.Item label="id" name={[name, 'id']} noStyle>
                                  <Input type="hidden" />
                                </Form.Item>
                              </Space>
                            </>
                        ))}
                      </>
                  )}
                </Form.List>

                {/*<Tooltip title={'Amount of days leave this employee is entitled to per financial year'}>*/}
                {/*  <Form.Item name="annual_allowance" rules={[{required: true, message: 'Please input annual allowance!'}]}>*/}
                {/*    <Input size={"large"} type="number" placeholder="Annual allowance" style={{borderWidth: '0 0 2px', borderRadius: 0}} />*/}
                {/*  </Form.Item>*/}
                {/*</Tooltip>*/}

                {/*<Tooltip title={'Maximum of days leave employee is able to carry into the next financial year'}>*/}
                {/*  <Form.Item name="annual_carry_over" rules={[{required: true, message: 'Please input allowance carry over max!'}]}>*/}
                {/*    <Input size={"large"} type="number" placeholder="Allowance Carry Over" style={{borderWidth: '0 0 2px', borderRadius: 0}} />*/}
                {/*  </Form.Item>*/}
                {/*</Tooltip>*/}

                {/*<Tooltip title={'Amount of leave this employee has remaining this financial year'}>*/}
                {/*  <Form.Item name="current_balance" rules={[{required: true, message: 'Please input allowance entitled in the current company year!'}]}>*/}
                {/*    <Input size={"large"} type="number" placeholder="Current balance" style={{borderWidth: '0 0 2px', borderRadius: 0}} />*/}
                {/*  </Form.Item>*/}
                {/*</Tooltip>*/}

                {/*<Tooltip title={'Days brought forward from the last financial year'}>*/}
                {/*  <Form.Item name="brought_allowance" rules={[{required: true, message: 'Please input allowance brought forward!'}]}>*/}
                {/*    <Input size={"large"} type="number" placeholder="Brought forward" style={{borderWidth: '0 0 2px', borderRadius: 0}} />*/}
                {/*  </Form.Item>*/}
                {/*</Tooltip>*/}
              </>,
            },
          ]}
      />

      <Form.Item>
        <Button style={{marginTop: 40}}  size={"large"} type="primary" htmlType="submit" disabled={isSaving} icon={isSaving ? <LoadingOutlined /> : null}>
          Save user
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRouter(EmployeeForm);

import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/bankRegionsConstants';

export function setIsFetching() {
  return {
    type: actionTypes.SET_IS_FETCHING,
  };
}

export function fetchBankRegionsSuccess(data) {
  return {
    type: actionTypes.FETCH_BANK_REGIONS_SUCCESS,
    bankRegions: data,
  };
}

export function fetchBankRegionsFailure(error) {
  return {
    type: actionTypes.FETCH_BANK_REGIONS_FAILURE,
    error,
  };
}

export function fetchBankRegions() {
  return (dispatch) => {
    dispatch(setIsFetching());
    return requestsManager
      .fetchEntities('bank_regions')
      .then((res) => dispatch(fetchBankRegionsSuccess(res.data)))
      .catch((error) => dispatch(fetchBankRegionsFailure(error)));
  };
}

import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import {ConfigProvider, Badge, Divider, Typography, Avatar, Spin, Select, Row, Col, Tour} from 'antd';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import AuthVerify from "../context/authVerify.js";
import {
  TeamOutlined,
  DownOutlined,
  ScheduleOutlined,
  BellOutlined,
  LogoutOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  HomeOutlined,
  LoadingOutlined,
  MenuOutlined
} from '@ant-design/icons';
import {Button, Dropdown, Layout, Menu, Space, theme} from 'antd';
import logo from '../../../assets/images/logo.svg';
import logoCalendar from '../../../assets/images/logo-calendar.svg';
import tourWallchart from '../../../assets/images/tour-wallchart.png';
import tourMyCalendar from '../../../assets/images/tour-mycalendar.png';
import tourUsers from '../../../assets/images/tour-users.png';
import tourSettings from '../../../assets/images/tour-settings.png';

const {Header, Content, Footer, Sider} = Layout;
const {Title} = Typography;

import css from './Layout.module.scss';

import {useAuth} from "../context/auth";
import {
  MANAGERS_WALLCHART_PATH,
  HOME_PATH,
  WALLCHART_PATH,
  MY_CALENDAR_PATH,
  MANAGERS_USERS_PATH,
  MANAGERS_COMPANY_SETTINGS_PATH,
  MANAGERS_MY_CALENDAR_PATH, LOGIN_ROUTER_PATH, USER_SETTINGS_PATH
} from "../constants/paths";
import {antTheme} from "../../../libs/theme";
import {bindActionCreators} from "redux";
import NavigationDrawer from "../../../bundles/comments/components/NavigationDrawer/NavigationDrawer";
import * as usersActionCreators from "../actions/usersActionCreators";
import * as leaveTypesActionCreators from "../actions/leaveTypesActionCreators";
import * as bankRegionsActionCreators from "../actions/bankRegionsActionCreators";
import * as bankHolidaysActionCreators from "../actions/bankHolidaysActionCreators";
import * as departmentsActionCreators from "../actions/departmentsActionCreators";
import * as leaveBookingsActionCreators from "../actions/leaveBookingsActionCreators";
import * as companiesActionCreators from "../actions/companiesActionCreators";
import tileBriefcase from "../../../assets/images/tile-briefcase.svg";
import {countBookingMidDates} from "../../../libs/dateUtils";
import dayjs from "dayjs";
import UserAvatar from "../components/UserAvatar/UserAvatar";
import NotificationsDrawer from "../components/NotificationsDrawer/NotificationsDrawer";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function stateToProps(state) {
  return {
    loading: state.$$usersStore.get('isFetching') ||
        state.$$leaveTypesStore.get('isFetching') ||
        state.$$bankRegionsStore.get('isFetching') ||
        state.$$bankHolidaysStore.get('isFetching') ||
        state.$$departmentsStore.get('isFetching') ||
        state.$$leaveBookingsStore.get('isFetching') ||
        state.$$companiesStore.get('isFetching') ||
        state.$$usersStore.get('$$users').size === 0 ||
        state.$$companiesStore.get('$$companies').size === 0,
    companies: state.$$companiesStore.get('$$companies'),
    departments: state.$$departmentsStore.get('$$departments'),
    bankRegions: state.$$bankRegionsStore.get('$$bankRegions'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    users:  state.$$usersStore.get('$$users'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
  };
}


const TOHeader = styled(Header)`
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    border-bottom: #E4E3E3 1px solid;
    
    .mobile-notification-wrapper {
      display: none;
    }
    .menu-toggle-wrapper {
      display: none;
    }
    .right-nav-wrapper {
      padding: 0;
      text-align: right;
      float: right;
    }
    
    .login-user-wrapper {
      .avatar-name {
        margin-left: 10px;
      }
    }
    
    @media (max-width: 768px) {
      .company-selector-wrapper {
        display: none;
      }
      .notification-divider {
        display: none;
      }
      .mobile-notification-wrapper {
        display: block;
      }
      .notification-wrapper {
        display: none;
      }
      .menu-toggle-wrapper {
        display: block;
      }
      .login-user-wrapper {
        .avatar-name {
          display: none;
        }
        .avatar-arrow {
          display: none;
        }
      }
    }
  `;


function AppLayout(props) {
  const { children, loading, companies, activeCompanyId, users, leaveTypes, bankHolidays, departments, leaveBookings } = props;

  const auth = useAuth();
  const history = useHistory();

  const company = auth.user ? companies.find((company) => (auth.user.role === 'accountant' &&  company.get('id') === activeCompanyId) || company.get('id') === auth.user.company_id) : null;
  const loginUser = auth.user ? users.find((user) => (auth.user.id === user.get('id'))) : null;

  const [openNavigation, setOpenNavigation] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const {fetchUsers} = bindActionCreators(usersActionCreators, props.dispatch);
  const {fetchLeaveTypes} = bindActionCreators(leaveTypesActionCreators, props.dispatch);
  const {fetchBankRegions} = bindActionCreators(bankRegionsActionCreators, props.dispatch);
  const {fetchBankHolidays} = bindActionCreators(bankHolidaysActionCreators, props.dispatch);
  const {fetchDepartments} = bindActionCreators(departmentsActionCreators, props.dispatch);
  const {fetchLeaveBookings} = bindActionCreators(leaveBookingsActionCreators, props.dispatch);
  const {fetchCompanies} = bindActionCreators(companiesActionCreators, props.dispatch);
  const {setActiveCompanyId} = bindActionCreators(companiesActionCreators, props.dispatch);

  useEffect(() => {
    if (auth.user !== null) {

      const token = localStorage.getItem('token');

      if (token) {
        const decodedJwt = parseJwt(token);

        if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
          handleSignOutClick();
        }
      }

      fetchUsers();
      fetchLeaveTypes();
      fetchBankHolidays();
      fetchBankRegions();
      fetchDepartments();
      fetchLeaveBookings();
      fetchCompanies();
    }
  }, [])

  const handleSignOutClick = (e) => {
    auth.signOut(() => {
      history.push(LOGIN_ROUTER_PATH);
    });
  };

  const items = [
    {
      key: 'wallchart',
      icon: <UnorderedListOutlined />,
      label: 'Wall Chart',
      onClick: () => history.push(MANAGERS_WALLCHART_PATH)
    },
    {
      key: 'mycalendar',
      icon: <ScheduleOutlined />,
      label: 'My Calendar',
      onClick: () => history.push(MANAGERS_MY_CALENDAR_PATH)
    },
    {
      key: 'users',
      label: 'Users',
      icon: <TeamOutlined />,
      onClick: () => history.push(MANAGERS_USERS_PATH)
    },
    {
      key: 'settings',
      label: 'Settings',
      icon: <SettingOutlined />,
      onClick: () => history.push(MANAGERS_COMPANY_SETTINGS_PATH)
    },
    {type: 'divider'},
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined/>,
      onClick: () => handleSignOutClick()
    },
  ];

  const employerMenuProps = {
    items,
  };

  const employeeItems = [
    {
      key: 'home',
      icon: <HomeOutlined />,
      label: 'Home',
      onClick: () => history.push(HOME_PATH)
    },
    {
      key: 'wallchart',
      icon: <UnorderedListOutlined />,
      label: 'Wall Chart',
      onClick: () => history.push(WALLCHART_PATH)
    },
    {
      key: 'mycalendar',
      icon: <ScheduleOutlined />,
      label: 'My Calendar',
      onClick: () => history.push(MY_CALENDAR_PATH)
    },
    {
      key: 'userSettings',
      icon: <ScheduleOutlined />,
      label: 'Settings',
      onClick: () => history.push(USER_SETTINGS_PATH)
    },
    {type: 'divider'},
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined/>,
      onClick: () => handleSignOutClick()
    },
  ];

  const employeeMenuProps = {
    items: employeeItems,
  };

  const companyHolidays = company ? bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')})) : null;

  const notificationsMenuItems = companyHolidays && auth.user.role === 'company_admin' && users.size > 0 ? {
    items: leaveBookings.filter((leaveBooking) => leaveBooking.get('approved') !== true && !leaveBooking.get('declined') && !leaveBooking.get('canceled')).map((leaveBooking) => {
      const user = users.find((user) => user.get('id') === leaveBooking.get('user_id'));

      return ({
        label:
          <Row key={leaveBooking.get('id')}
               style={{width: 530}}>
            <Col span={10}>
              <UserAvatar user={user} withCalendarLink/>
              <p>"{leaveBooking.get('notes')}"</p>
            </Col>
            <Col span={10}>
              <Space style={{marginBottom: 10}}>
                <img src={tileBriefcase} width={30}/>
                <Title
                    level={5}>{leaveTypes.size > 0 && leaveTypes.find((leaveType) => leaveType.get('id') === leaveBooking.get('leave_type_id')).get('name')} ({countBookingMidDates(leaveBooking, user.get('work_schedules'), company.get('bank_holidays'), companyHolidays)} days)
                </Title>
              </Space>
              <p style={{marginBottom: 0}}>{leaveBooking.get('from') ? dayjs.utc(leaveBooking.get('from')).format('ddd, DD MMM YYYY') + ' - ' : ''}</p>
              <p>{leaveBooking.get('to') ? dayjs.utc(leaveBooking.get('to')).format('ddd, DD MMM YYYY') : ''}</p>
            </Col>
            <Col span={4} style={{textAlign: "right"}}>
              {dayjs(leaveBooking.get('created_at')).fromNow(true)}
            </Col>
          </Row>,
        key: leaveBooking.get('id')
      })}
    ),
    onClick: ({ key }) => {
      const lb = leaveBookings.find((leaveBooking) => `${leaveBooking.get('id')}` === key);
      history.push(MANAGERS_MY_CALENDAR_PATH + `?user=${lb.get('user_id')}`);
    }
  } : {items: []}

  const notificationsDrawerContent = companyHolidays && auth.user.role === 'company_admin' && users.size > 0 ?
      <div>
        {
          leaveBookings.filter((leaveBooking) => leaveBooking.get('approved') !== true && !leaveBooking.get('declined') && !leaveBooking.get('canceled')).map((leaveBooking) => {
            const user = users.find((user) => user.get('id') === leaveBooking.get('user_id'));
            return <Row key={leaveBooking.get('id')} style={{marginBottom: 10, borderBottom: '1px solid #E9E9F0'}} onClick={() => {history.push(MANAGERS_MY_CALENDAR_PATH + `?user=${leaveBooking.get('user_id')}`); setOpenNotifications(false);}}>
              <Col span={5}>
                <UserAvatar user={user} withCalendarLink/>
                <p>"{leaveBooking.get('notes')}"</p>
              </Col>
              <Col span={13}>
                <Space style={{marginBottom: 10}}>
                  <img src={tileBriefcase} width={30}/>
                  <Title
                      level={5}>{leaveTypes.size > 0 && leaveTypes.find((leaveType) => leaveType.get('id') === leaveBooking.get('leave_type_id')).get('name')} ({countBookingMidDates(leaveBooking, user.get('work_schedules'), company.get('bank_holidays'), companyHolidays)} days)
                  </Title>
                </Space>
                <p style={{marginBottom: 0}}>{leaveBooking.get('from') ? dayjs.utc(leaveBooking.get('from')).format('ddd, DD MMM YYYY') + ' - ' : ''}</p>
                <p>{leaveBooking.get('to') ? dayjs.utc(leaveBooking.get('to')).format('ddd, DD MMM YYYY') : ''}</p>
              </Col>
              <Col span={6} style={{textAlign: "right"}}>
                {dayjs(leaveBooking.get('created_at')).fromNow(true)}
              </Col>
            </Row>;
          })
        }
      </div> : ''

  if (auth.user === null) {
    return (
      <ConfigProvider
          theme={antTheme}
      >
        <section>
          <header>
            <div className={css.headerContainer}>
              <img width={200} src={logoCalendar}/>
            </div>
          </header>
          <div className={css.mainContainer}>
            {children}
          </div>
        </section>
      </ConfigProvider>
    );
  }

  return (
    <ConfigProvider theme={antTheme}>
      <Layout>
        <Layout>
          <TOHeader>
            <Space className={"company-selector-wrapper"}>
              {auth.user.role === 'accountant' ?
                  <Select size={"large"} bordered={false} value={activeCompanyId}
                          onChange={(id) => setActiveCompanyId(id)}>
                    {companies.map((company) => (
                        <Select.Option key={company.get('id')}
                                       value={company.get('id')}>{company.get('name')}</Select.Option>
                    ))}
                  </Select>
                :
                <Title style={{margin: 10}} level={4}>{company && company.get('name')}</Title>
              }
            </Space>

            <Button type={'link'} className={"menu-toggle-wrapper"} style={{padding: 11, lineHeight: 1, height: 48}}>
              <MenuOutlined style={{fontSize: 24, color: '#0E0B44'}} onClick={() => setOpenNavigation(true)} />
            </Button>

            <NavigationDrawer title={company && company.get('name')} open={openNavigation} onClose={() => setOpenNavigation(false)} />

            <div><img src={logoCalendar} width={180}/></div>

            <Space className={"right-nav-wrapper"} size={0}>
              <Dropdown menu={notificationsMenuItems} className={"notification-wrapper"}>
                <Space>
                  <Badge count={notificationsMenuItems.items.size} size={'small'}>
                    <BellOutlined style={{fontSize: 18}}/>
                  </Badge>

                  <Divider  className={"notification-divider"} type="vertical"/>
                </Space>
              </Dropdown>

              <NotificationsDrawer open={openNotifications} onClose={() => setOpenNotifications(false)}>
                {notificationsDrawerContent}
              </NotificationsDrawer>

              <Button type="link" className={'mobile-notification-wrapper'} style={{height: 'auto', fontSize: 16, padding: 0}} onClick={() => setOpenNotifications(true)}>
                <Badge count={notificationsMenuItems.items.size} size={'small'}>
                  <BellOutlined style={{fontSize: 18}}/>
                </Badge>
              </Button>

              <Dropdown className={"login-user-wrapper"} menu={(auth.user.role === 'company_admin' || auth.user.role === 'accountant') ? employerMenuProps : employeeMenuProps}>
                <Button type="link" style={{height: 'auto', fontSize: 16, fontWeight: 'bold', padding: 8}}>
                  <Avatar style={{background: loginUser ? loginUser.get('avatar_color') : auth.user.avatar_color}}>
                    {auth.user.first_name && auth.user.first_name[0]}{auth.user.last_name && auth.user.last_name[0]}
                  </Avatar>
                  <span className={"avatar-name"}>{auth.user.first_name} {auth.user.last_name}</span>
                  <DownOutlined className={"avatar-arrow"}/>
                </Button>
              </Dropdown>
            </Space>
          </TOHeader>

          <Content style={{margin: 0}}>
            <div className={css.mainArea}>
                {(loading || !auth.user) ? <Spin style={{margin: 'auto'}} indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} size="large" /> : children}
            </div>
          </Content>

          <Footer style={{display: 'flex', flexDirection: "column", alignItems: 'center'}}>
            <a href={'https://timeoff.co.uk'} target={'_blank'} style={{color: '#0E0B44'}}>© timeoff.co.uk</a>
            <span><a href={'https://timeoff.co.uk/status'} target={'_blank'} style={{color: '#0E0B44'}}>Service Status</a> - <a href={'https://timeoff.co.uk/terms'} target={'_blank'} style={{color: '#0E0B44'}}>Terms</a></span>
          </Footer>

          <AuthVerify logOut={handleSignOutClick} />

        </Layout>
      </Layout>
    </ConfigProvider>
  );
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(stateToProps)(AppLayout);
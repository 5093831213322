import React from 'react';
import {
  Modal,
  Typography,
  Button,
  Space,
} from 'antd';
import {
  CloseOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import UserAvatar from "../UserAvatar/UserAvatar";
import EmployeeForm from "../EmployeeForm/EmployeeForm";
import * as usersActionCreators from "../../actions/usersActionCreators";
import {userYearlyTakenAllowance} from "../../../../libs/dateUtils";

function stateToProps(state) {
  return {
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings:  state.$$leaveBookingsStore.get('$$leaveBookings'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    isSaving: state.$$usersStore.get('isSaving'),
  };
}

const UserEditModal = (props) => {
  const {activeUserId, company, onClose, users, companies, leaveTypes, leaveBookings, departments, bankHolidays, isSaving} = props;

  const {updateUser, submitUser} = bindActionCreators(usersActionCreators, props.dispatch);

  const activeUserMap = users.find((user) => user.get('id') === activeUserId);
  const activeUser = activeUserMap ? {
    ...Object.fromEntries(activeUserMap),
    work_schedules: Array.from(activeUserMap.get('work_schedules')).map((ws) => Object.fromEntries(ws)),
    leave_allowances: Array.from(activeUserMap.get('leave_allowances')).map((la) => Object.fromEntries(la))
  } : null;

  const inLieuDays = activeUserMap ? activeUserMap.get('in_lieu_times').reduce((ilt, value) => value.get('amount') + ilt, 0) : 0;
  const takenDays = activeUserMap ? leaveTypes.filter((leaveType) => leaveType.get('deduct_allowance')).map((leaveType) => {
    return {
      type: leaveType,
      count: userYearlyTakenAllowance(activeUserMap, companies, leaveBookings, leaveTypes, bankHolidays, dayjs().year())
    };
  }).reduce((sum, value) => (sum + value.count), 0) : 0;

  const nextYearTakenDays = activeUserMap ? leaveTypes.filter((leaveType) => leaveType.get('deduct_allowance')).map((leaveType) => {
    return {
      type: leaveType,
      count: userYearlyTakenAllowance(activeUserMap, companies, leaveBookings, leaveTypes, bankHolidays, dayjs().year() + 1)
    };
  }).reduce((sum, value) => (sum + value.count), 0) : 0;

  const onFinish = (form, values) => {
    if (values.id) {
      updateUser(values.id, {
        user: {
          ...values,
          work_schedules_attributes: values.work_schedules,
          leave_allowances_attributes: values.leave_allowances
        }
      }).then(() => onClose());
    } else {
      submitUser({
        user: {
          ...values,
          work_schedules_attributes: values.work_schedules,
          leave_allowances_attributes: values.leave_allowances
        }
      }).then(() => onClose());
    }
  };

  return (
      <Modal open={activeUserId} footer={null} width={620} closable={false} destroyOnClose>
        {activeUser &&
        <>
          <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 0}}>
            <UserAvatar key={activeUser.id} user={activeUserMap} withCalendarLink={false} withoutDepartment={true}/>
            <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onClose}
                    style={{width: 50}}/>
          </Space>

          <EmployeeForm
              departments={departments}
              company={company}
              inLieuDays={inLieuDays}
              takenDays={takenDays}
              nextYearTakenDays={nextYearTakenDays}
              isSaving={isSaving}
              onFinish={onFinish}
              initValues={{
                ...activeUser,
                employment_from: activeUser.employment_from ? dayjs(activeUser.employment_from) : null,
                birth_date: activeUser.birth_date ? dayjs(activeUser.birth_date) : null,
              }}
          />
        </>}
      </Modal>
  );
}

export default connect(stateToProps)(UserEditModal)

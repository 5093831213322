import React, {useState, useEffect} from 'react';
import {
  FloatButton,
  Typography,
  Badge,
  Input,
  Button,
  Space,
  Pagination,
  Row,
  Col,
  Avatar, Modal,
} from 'antd';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  SearchOutlined,
  ArrowUpOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  getDaysInDayRange, getDaysInMonth,
} from '../../../../libs/dateUtils.js';

const {Title} = Typography;
import css from './WallChartScreen.module.scss';
import BookingDrawer from "../BookingDrawer/BookingDrawer";
import WallchartFilterDrawer from "../WallchartFilterDrawer/WallchartFilterDrawer";
import HzMonthCalendar from "../HzMonthCalendar/HzMonthCalendar";
import {PageHeader, SearchInput} from "../../../../libs/components/StyledComponents";
import {useAuth} from "../../context/auth";

import dayjs from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween'
import {connect} from "react-redux";
import LeaveBookingForm from "../LeaveBookingForm/LeaveBookingForm";
import {bindActionCreators} from "redux";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import WallChartTopFilter from "../WallChartTopFilter/WallChartTopFilter";
import {getUserFullName, getUserInitialName, getUserTimeInLieuDays} from "../../../../libs/userUtils";
import LeaveBookingModal from "../LeaveBookingModal/LeaveBookingModal";
import styled from "styled-components";
import withScreenSize from "../../../../libs/components/withScreenSize";
import UserAvatar from "../UserAvatar/UserAvatar";

dayjs.extend(isBetween);

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
    max-width: 220px;
    border-right: 1px solid #E9E9F0;
    padding-left: 10px;

    @media (max-width: 425px) {
      padding-top: 70px;
    }
  `;

// const PageTitle = styled(Title)`
//     font-size: 24px !important;
//
//     @media (min-width: 1024px) {
//       font-size: 36px !important;
//     }
// `;

const DepartmentLabel = styled(Col)`
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    span.department-title {
      padding-left: 5px;
      padding-right: 2px;
    }
    @media (max-width: 425px) {
      display: none;
    }
`;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const WallChartScreen = (props) => {
  const {users, activeCompanyId, leaveBookings, departments, leaveTypes, companies, screenSize} = props;
  const [openBooking, setOpenBooking] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [chartDepartment, setChartDepartment] = useState('all');
  const [chartYear, setChartYear] = useState((new Date).getFullYear());
  const [chartMonth, setChartMonth] = useState((new Date).getMonth());
  const [chartDate, setChartDate] = useState(dayjs().weekday(1));
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [activeBookingId, setActiveBookingId] = useState(null);
  const auth = useAuth();
  const {updateLeaveBooking, submitLeaveBooking} = bindActionCreators(leaveBookingsActionCreators, props.dispatch);

  const showBookingDrawer = () => {
    setOpenBooking(true);
  };

  const showFilterDrawer = () => {
    setOpenFilter(true);
  }

  const onCloseBooking = () => {
    setOpenBooking(false);
  };

  const onFinishBooking = (form, values) => {
    if (values.id) {
      updateLeaveBooking(values.id, {
        leave_booking: {
          ...values,
          from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
          to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
        }
      }).then(() => setActiveBookingId(null));
    } else {
      submitLeaveBooking({
        leave_booking: {
          ...values,
          from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
          to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
        }
      }).then(() => resetAndCloseBooking(form));
    }
  };

  const onCancelBooking = (form, leaveBooking) => {
    updateLeaveBooking(leaveBooking.id, {
      leave_booking: {
        canceled: true
      }
    }).then(() => setActiveBookingId(null));
  }

  const resetAndCloseBooking = (form) => {
    setOpenBooking(false);
    form.resetFields();
  };

  const onCloseBookingInfo = () => {
    setActiveBookingId(null);
  };

  const onCloseFilter = () => {
    setOpenFilter(false);
  };

  const onFinishFilter = (form, values) => {
    resetAndCloseFilter(form);
    // actions3.submitLeaveBooking({leave_booking: values}).then(() => resetAndClose(form));
  };

  const resetAndCloseFilter = (form) => {
    setOpenBooking(false);
    form.resetFields();
  };

  const onChangeMonth = (value) => {
    setChartMonth(value - 1)
  }

  const onChangeYear = (value) => {
    setChartYear(value)
  }

  const onChangeDate = (value) => {
    setChartDate(value)
  }

  const onChangeSearchEmployee = (value) => {
    setEmployeeSearch(value.target.value);
  }

  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const dayRange = screenSize.dynamicWidth >= 1440 ? getDaysInMonth(chartYear, chartMonth + 1) : getDaysInDayRange(chartDate, chartDate.add(screenSize.dynamicWidth < 768 ? 6 : 13, 'day'));

  const today = (new Date);
  today.setUTCHours(0, today.getTimezoneOffset(), 0, 0);

  const company = companies.find((company) => company.get('id') === auth.user.company_id);

  const selectedUsers = users.filter((user) => (chartDepartment === 'all' || chartDepartment === user.get('department_id')) && [user.get('first_name'), user.get('last_name')].join(' ').toLowerCase().indexOf(employeeSearch.toLowerCase()) !== -1);

  return (
      <>
        <div style={{padding: 20, margin: 'auto'}}>
          <PageHeader>
            <Col md={6}>
              <Title level={1}>Wall Chart</Title>
            </Col>
            <Col md={12}>
              <WallChartTopFilter
                  chartYear={chartYear}
                  chartMonth={chartMonth}
                  chartDate={chartDate}
                  chartDepartment={chartDepartment}
                  onChangeMonth={onChangeMonth}
                  onChangeYear={onChangeYear}
                  onChangeDate={onChangeDate}
                  dayRange={dayRange}/>
            </Col>
          </PageHeader>

          <Row style={{flexFlow: 'nowrap', overflowY: 'auto', overflowX: 'hidden'}}>
            <StyledCol>
              <DepartmentLabel>
                <Button type={"primary"}
                        style={{height: 34, borderRadius: 34, lineHeight: '30px', fontSize: 16, padding: '1px 8px'}}
                        color={"#0023C4"}>
                  <span className="department-title">
                    {chartDepartment === 'all' ? 'All members' : departments.find((d) => d.get('id') === chartDepartment).get('name')}
                  </span>
                  <Badge
                      color={"#0E0B4E"}
                      count={users.filter((user) => user.get('company_id') === activeCompanyId && (chartDepartment === 'all' || user.get('department_id') === chartDepartment)).size}
                      style={{border: 0, top: -2, left: 3}}/>
                </Button>
              </DepartmentLabel>

              {
                selectedUsers.map((user) => {
                  return (
                      <UserAvatar key={user.get('id')} user={user} withCalendarLink={false}/>
                  )
                })
              }
            </StyledCol>
            <Col flex="auto">
              <Row style={{height: 70, display: 'flex', alignItems: 'center', flexWrap: 'nowrap'}}>
                {dayRange.map((date) => <Col key={date} flex="34px"
                                             style={date.valueOf() === today.valueOf() && {
                                               border: '1px solid rgba(179, 7, 133, 1)',
                                               borderRadius: 20
                                             }}
                                             className={css.weekDayCell}>{weekdays[date.day()][0]}</Col>)}
              </Row>
              {
              selectedUsers.map((user) => {

                return (
                    <Row key={user.get('id')} style={{height: 70, display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                      <HzMonthCalendar
                          ownerView={user.get('id') === auth.user.id}
                          managerView={false}
                          onChangeActiveBooking={(bookingId) => setActiveBookingId(bookingId)}
                          dayRange={dayRange}
                          user={user}
                          hideLeaveTypes={company.get('hide_leave_types')} />
                    </Row>
                );
              })
            }
            </Col>
          </Row>
        </div>
        {!openFilter &&
        <FloatButton type="primary" onClick={showFilterDrawer} style={{bottom: 100, right: 40}} disabled={auth.user.role === 'accountant'}
                     icon={<ArrowUpOutlined/>}/>
        }

        <WallchartFilterDrawer year={chartYear} month={chartMonth} department={chartDepartment}
                               onChangeYear={setChartYear} onChangeMonth={setChartMonth} onCloseFilter={onCloseFilter}
                               onChangeDepartment={setChartDepartment} open={openFilter} onFinish={onFinishFilter} ownerView={true}/>

        <FloatButton type="primary" onClick={showBookingDrawer} style={{bottom: 40, right: 40}}
                     icon={<PlusOutlined/>}/>

        <BookingDrawer
            open={openBooking}
            onClose={onCloseBooking}
            onFinish={onFinishBooking}
            ownerView={false}/>

        <LeaveBookingModal
            activeBookingId={activeBookingId}
            onCloseBookingInfo={onCloseBookingInfo}
            onFinishBooking={onFinishBooking}
            onCancelBooking={onCancelBooking}/>
    </>
  );
}

export default connect(stateToProps)(withScreenSize(WallChartScreen))
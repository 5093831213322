import {Button, Form, Input, InputNumber, Select, Typography, Switch, Space} from "antd";
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {MONTH_NAMES} from '../../../../libs/dateUtils.js';
import dayjs from "dayjs";

const { Title } = Typography;

function CompanyAdminDelegationForm(props) {
  const [form] = Form.useForm();
  const {onFinish, initValues, isSaving, users, disabled} = props;

  useEffect(() => {
    form.resetFields();
  }, [initValues.id])

  return (
    <Form
        style={{width: 300}}
        form={form}
        layout="vertical"
        initialValues={{...initValues}}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.List name="company_admins">
        {(fields, { add, remove }) => (
            <>
              {fields.map(({key, name, ...restField}) => (
                  <Space key={key} align="baseline">
                    <Form.Item
                        name={[name, 'user_id']}
                        rules={[{required: true, message: 'Please choose user!'}]}
                    >
                      <Select size={"large"} style={{width: 200}} placeholder="Who for">
                        {users.map((user) => (
                            <Select.Option key={user.get('id')}
                                           value={user.get('id')}>{user.get('first_name')} {user.get('last_name')}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <DeleteOutlined onClick={() => remove(name)} />
                  </Space>
              ))}

              <Form.Item>
                <Button size={'small'} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                  Add
                </Button>
              </Form.Item>
            </>
        )}
      </Form.List>

      <Form.Item>
        <Button style={{marginTop: 40}}  size={"large"} type="primary" htmlType="submit" disabled={isSaving || disabled} icon={isSaving ? <LoadingOutlined /> : null}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRouter(CompanyAdminDelegationForm);

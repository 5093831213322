import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {
  FloatButton,
  Modal,
  Typography,
  Button,
  Space,
  Pagination,
  Row,
  Col,
  Select,
  Card,
  Divider, Badge
} from 'antd';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  EditOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween);

const {Title} = Typography;
import BookingDrawer from "../BookingDrawer/BookingDrawer";
import {useAuth} from "../../context/auth";
import VtYearCalendar from "../VtYearCalendar/VtYearCalendar";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import InLieuTimesDrawer from "../InLiueTimesDrawer/InLieuTimesDrawer";
import * as actionTypes from "../../constants/usersConstants";
import * as usersActionCreators from "../../actions/usersActionCreators";
import LeaveBookingModal from "../LeaveBookingModal/LeaveBookingModal";
import {PageHeader} from "../../../../libs/components/StyledComponents";
import styled from "styled-components";
import CalendarAllowanceOverview from "../CalendarAllowanceOverview/CalendarAllowanceOverview";
import {MANAGERS_MY_CALENDAR_PATH} from "../../constants/paths";
import {getUserFullName} from "../../../../libs/userUtils";
import UserEditModal from "../UserEditModal/UserEditModal";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const Wrapper = styled(Row)`
    flex-flow: nowrap;
    
    @media (max-width: 640px) {
      flex-direction: column-reverse;
    }
  `;

const ManagerMyCalendarScreen = (props) => {
  const {leaveTypes, users, leaveBookings, companies, activeCompanyId, bankHolidays, isSaving} = props;

  const {updateLeaveBooking, submitLeaveBooking} = bindActionCreators(leaveBookingsActionCreators, props.dispatch);
  const {updateUser, submitUser, deleteUser} = bindActionCreators(usersActionCreators, props.dispatch);

  const auth = useAuth();
  const query = useQuery();
  const history = useHistory();

  const [openBooking, setOpenBooking] = useState(false);
  const [openInLieuTimesDrawer, setOpenInLieuTimesDrawer] = useState(false);
  const [chartYear, setChartYear] = useState((new Date).getFullYear());
  const [activeBookingId, setActiveBookingId] = useState(null);
  const [activeEditUserId, setActiveEditUserId] = useState(null);

  const activeUserId = query.get('user') ? parseInt(query.get('user')) : auth.user.id;

  const showBookingDrawer = () => {
    setOpenBooking(true);
  };

  const onCloseBooking = () => {
    setOpenBooking(false);
  };

  const onFinishBooking = (form, values) => {
    if (values.id) {
      updateLeaveBooking(values.id, {
        leave_booking: {
          ...values,
          from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
          to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
        }
      }).then(() => setActiveBookingId(null));
    } else {
      submitLeaveBooking({
        leave_booking: {
          ...values,
          from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
          to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
        }
      }).then(() => resetAndCloseBooking(form));
    }
  };

  const onFinishInLieuTimes = (form, values) => {
    if (values.id) {
      const formValue = values.in_lieu_times;
      user.get('in_lieu_times').forEach((lieuTime) => {
        if (!values.in_lieu_times.find((value) => value.id === lieuTime.get('id'))) {
          formValue.push({id: lieuTime.get('id'), _destroy: true});
        }
      })
      updateUser(values.id, {
        user: {
          ...values,
          in_lieu_times_attributes: formValue
        }
      }).then((data) => {
        if (data && data.type === actionTypes.UPDATE_USER_SUCCESS) {
          form.resetFields();
          setOpenInLieuTimesDrawer(false);
        }
      });
    } else {
      submitUser({
        user: {
          ...values,
          in_lieu_times_attributes: values.in_lieu_times
        }
      }).then((data) => {
        if (data && data.type === actionTypes.SUBMIT_USER_SUCCESS) {
          form.resetFields();
          setOpenInLieuTimesDrawer(false);
        }
      });
    }
  };

  const onApproveBooking = (form, leaveBooking) => {
    updateLeaveBooking(leaveBooking.id, {
      leave_booking: {
        approved: true,
        approved_by_id: auth.user.id,
        ...leaveBooking
      }
    }).then(() => setActiveBookingId(null));
  }

  const onDeclineBooking = (form, leaveBooking) => {
    updateLeaveBooking(leaveBooking.id, {
      leave_booking: {
        declined: true,
        declined_by_id: auth.user.id,
        ...leaveBooking
      }
    }).then(() => setActiveBookingId(null));
  }

  const onCancelBooking = (form, leaveBooking) => {
    updateLeaveBooking(leaveBooking.id, {
      leave_booking: {
        canceled: true
      }
    }).then(() => setActiveBookingId(null));
  }

  const resetAndCloseBooking = (form) => {
    setOpenBooking(false);
    form.resetFields();
  };

  const onCloseBookingInfo = () => {
    setActiveBookingId(null);
  };

  const today = (new Date);
  today.setUTCHours(0, today.getTimezoneOffset(), 0, 0);

  const user = users.find((user) => user.get('id') === activeUserId);
  const company = companies.find((company) => (user.get('role') === 'accountant' &&  company.get('id') === activeCompanyId) || company.get('id') === user.get('company_id'));
  const userBookings = leaveBookings.filter((leaveBooking) => leaveBooking.get('user_id') === user.get('id') && !leaveBooking.get('declined') && !leaveBooking.get('canceled'));
  const companyHolidays = bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')}));

  return (
      <>
        <div style={{padding: 20, margin: 'auto', maxWidth: 1240}}>
          <PageHeader>
            <Col md={8}>
              <Title level={1}>Calendar:
                <Button type={"link"} size={'large'} onClick={() => setActiveEditUserId(user.get('id'))}
                        style={{padding: 0, margin: 0, marginLeft: 14, fontWeight: 'bold', fontSize: 30, color: '#0E0B4E', textAlign: 'left'}}
                        >
                  {getUserFullName(user)}<EditOutlined />
                </Button>
              </Title>
            </Col>
            <Col md={16} className={'user-selector-container'} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <Select size={"large"} bordered={false} style={{border: 'none', borderWidth: 0, fontWeight: 'bold'}} placeholder="Select employee..." value={activeUserId} onChange={(id) => history.push(MANAGERS_MY_CALENDAR_PATH + `?user=${id}`)}>
                {users.filter((user) => user.get('company_id') === activeCompanyId).map((user) => (
                    <Select.Option key={user.get('id')}
                                   value={user.get('id')}>{user.get('first_name')} {user.get('last_name')}</Select.Option>
                ))}
              </Select>
              <Button size={"large"} type={"link"}
                      onClick={() => setChartYear(chartYear - 1)}>
                <CaretLeftOutlined/>
              </Button>
              <Title level={3} style={{fontSize: 22, marginBottom: 0}}>{chartYear}</Title>
              <Button size={"large"} type={"link"} disabled={chartYear === (dayjs().year() + 1)}
                      onClick={() => setChartYear(chartYear + 1)}>
                <CaretRightOutlined/>
              </Button>
            </Col>
          </PageHeader>
          <Wrapper>
            <Col flex="auto" style={{overflowY: 'auto', overflowX: 'hidden'}}>
              <VtYearCalendar userBookings={userBookings} companyHolidays={companyHolidays} managerView ownerView={user.get('id') === auth.user.id || auth.user.role === 'company_admin'} onChangeActiveBooking={(bookingId) => setActiveBookingId(bookingId)} year={chartYear} user={user} />
            </Col>
            <Col style={{display: 'flex', flexDirection: 'column', borderLeft: '1px solid #E9E9F0', padding: 20, minWidth: 300}}>
              <CalendarAllowanceOverview
                  managerView
                  user={user}
                  year={chartYear}
                  company={company}
                  onOpenInLieuTimesDrawer={() => setOpenInLieuTimesDrawer(true)} />
            </Col>
          </Wrapper>
        </div>

        <FloatButton type="primary" onClick={showBookingDrawer} style={{bottom: 40, right: 40}} disabled={!(user.get('id') === auth.user.id || auth.user.role === 'company_admin')}
                     icon={<PlusOutlined/>}/>

        <BookingDrawer open={openBooking} onClose={onCloseBooking} onFinish={onFinishBooking} ownerView={true}/>

        <InLieuTimesDrawer user={users.find((user) => user.get('id') === activeUserId)} open={openInLieuTimesDrawer} onClose={() => setOpenInLieuTimesDrawer(false)} onFinish={onFinishInLieuTimes}/>

        <LeaveBookingModal
            managerView
            activeBookingId={activeBookingId}
            onCloseBookingInfo={onCloseBookingInfo}
            onFinishBooking={onFinishBooking}
            onCancelBooking={onCancelBooking}
            onApproveBooking={onApproveBooking}
            onDeclineBooking={onDeclineBooking}
        />

        <UserEditModal activeUserId={activeEditUserId} company={company} onClose={() => setActiveEditUserId(null)}/>
    </>
  );
}

export default connect(stateToProps)(ManagerMyCalendarScreen);
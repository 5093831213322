/* eslint new-cap: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/bankRegionsConstants';

export const $$initialState = Immutable.fromJS({
  $$bankRegions: [],
  fetchBankRegionError: null,
  isFetching: false,
});

export default function bankRegionsReducer($$state = $$initialState, action = null) {
  const { type, bankRegion, bankRegions, error } = action;

  switch (type) {
    case actionTypes.FETCH_BANK_REGIONS_SUCCESS: {
      return $$state.merge({
        $$bankRegions: bankRegions,
        fetchBankRegionError: null,
        isFetching: false,
      });
    }

    case actionTypes.FETCH_BANK_REGIONS_FAILURE: {
      return $$state.merge({
        fetchBankRegionError: error,
        isFetching: false,
      });
    }

    case actionTypes.SET_IS_FETCHING: {
      return $$state.merge({
        isFetching: true,
      });
    }

    default: {
      return $$state;
    }
  }
}

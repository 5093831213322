/* eslint new-cap: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/bankHolidaysConstants';

export const $$initialState = Immutable.fromJS({
  $$bankHolidays: [],
  fetchBankHolidayError: null,
  isFetching: false,
});

export default function bankHolidaysReducer($$state = $$initialState, action = null) {
  const { type, bankHoliday, bankHolidays, error } = action;

  switch (type) {
    case actionTypes.FETCH_BANK_HOLIDAYS_SUCCESS: {
      return $$state.merge({
        $$bankHolidays: bankHolidays,
        fetchBankHolidayError: null,
        isFetching: false,
      });
    }

    case actionTypes.FETCH_BANK_HOLIDAYS_FAILURE: {
      return $$state.merge({
        fetchBankHolidayError: error,
        isFetching: false,
      });
    }

    case actionTypes.SET_IS_FETCHING: {
      return $$state.merge({
        isFetching: true,
      });
    }

    default: {
      return $$state;
    }
  }
}

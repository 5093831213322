import React from 'react';
import BaseComponent from '../../../libs/components/BaseComponent.jsx';

import SignupScreen from "../components/SignupScreen/SignupScreen";

class RouterAuthContainer extends BaseComponent {

  render() {
    return (
        <SignupScreen />
    );
  }
}

export default RouterAuthContainer;

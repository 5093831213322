import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/bankHolidaysConstants';

export function setIsFetching() {
  return {
    type: actionTypes.SET_IS_FETCHING,
  };
}

export function fetchBankHolidaysSuccess(data) {
  return {
    type: actionTypes.FETCH_BANK_HOLIDAYS_SUCCESS,
    bankHolidays: data,
  };
}

export function fetchBankHolidaysFailure(error) {
  return {
    type: actionTypes.FETCH_BANK_HOLIDAYS_FAILURE,
    error,
  };
}

export function fetchBankHolidays() {
  return (dispatch) => {
    dispatch(setIsFetching());
    return requestsManager
      .fetchEntities('bank_holidays')
      .then((res) => dispatch(fetchBankHolidaysSuccess(res.data)))
      .catch((error) => dispatch(fetchBankHolidaysFailure(error)));
  };
}

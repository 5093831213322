import React, {useState} from 'react';
import {Form, Input, Button, Typography, Alert} from 'antd';
import {LoadingOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import { NavLink as Link, withRouter, useHistory } from 'react-router-dom';
import {useAuth} from "../../context/auth";

import css from './ForgotPasswordForm.module.scss';
import {HOME_PATH, LOGIN_ROUTER_PATH, MANAGERS_WALLCHART_PATH, SIGNUP_SUCCESS_ROUTER_PATH} from "../../constants/paths";
import {bindActionCreators} from "redux";
import * as usersActionCreators from "../../actions/usersActionCreators";
import * as leaveTypesActionCreators from "../../actions/leaveTypesActionCreators";
import * as bankHolidaysActionCreators from "../../actions/bankHolidaysActionCreators";
import * as departmentsActionCreators from "../../actions/departmentsActionCreators";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import * as companiesActionCreators from "../../actions/companiesActionCreators";
import {connect} from "react-redux";
import * as bankRegionsActionCreators from "../../actions/bankRegionsActionCreators";

const { Title } = Typography;

function ForgotPasswordForm(props) {

  const history = useHistory();
  const auth = useAuth();
  const [saving, setSaving] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const onFinish = (values) => {
    setSaving(true);
    auth.resetPassword(values, resetAndFocus);
  };

  const resetAndFocus = (response) => {
    if (response) {
      setFeedbackMessage("Reset password request succeeded. Please check your E-mail and continue.");
    } else {
      setFeedbackMessage("Reset password request failed. Please check your E-mail.");
    }
    setSaving(false);
  }

  return (
    <div className={css.formContainer}>
      <Title level={1}>Forgot Password</Title>
      <Form
        name="forgot_password"
        className={css.loginForm}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {feedbackMessage.length > 0 &&
        <Alert message={feedbackMessage} type="success" style={{marginBottom: 20, fontWeight: 'bold', textAlign: "left"}} />
        }

        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input size={"large"} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>

        <Form.Item>
          <Button size={"large"} type="primary" htmlType="submit" className={css.loginFormButton}
                  disabled={saving}
                  icon={saving ? <LoadingOutlined /> : null}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default connect()(withRouter(ForgotPasswordForm));

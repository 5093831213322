import React from 'react';
import BaseComponent from 'libs/components/BaseComponent';
import {Typography} from 'antd';

import css from './SignupSuccessScreen.module.scss';

const { Title } = Typography;

export default class SignupSuccessScreen extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {

    return (
        <div className={css.loginFormContainer}>
          <Title level={1}>Signup Succeeded!</Title>
          <Title level={4}>Thanks for creating an account. Please check your e-mail to continue.</Title>
        </div>
    );
  }
}

import {
  Button,
  DatePicker,
  Form,
  Input,
  Tooltip,
  Select,
  Space,
  InputNumber,
  Checkbox,
  Divider,
  Typography
} from "antd";
import {LoadingOutlined, DownOutlined} from "@ant-design/icons";
import React from "react";
import {withRouter} from "react-router-dom";
import {getWeekdayName, MONTH_NAMES} from "../../../../libs/dateUtils";
import {USER_AVATAR_COLORS} from "../../../../libs/userUtils";
const { Title } = Typography;

function UserPasswordForm(props) {
  const [form] = Form.useForm();
  const {onFinish, initValues, isSaving} = props;

  return (
    <Form
        form={form}
        layout={"vertical"}
        initialValues={{...initValues}}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name="password"
                 label={<Title level={4} style={{marginBottom: 0}}>Change Password</Title>}
                 rules={[{ min: 8, message: 'Password must be at least 8 characters' },
                      { pattern: '[a-zA-Z]', message: 'Password must include letters' },
                      { pattern: '[0-9]', message: 'Password must include numbers' }]}>
        <Input.Password size={"large"} type="password" placeholder="Password" style={{borderWidth: '0 0 2px', borderRadius: 0}} />
      </Form.Item>

      <Form.Item name="confirm" dependencies={['password']} hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password!',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                          }),
                        ]}>
        <Input.Password size={"large"} type="password" placeholder="Confirm password" style={{borderWidth: '0 0 2px', borderRadius: 0}} />
      </Form.Item>

      <Form.Item>
        <Button style={{marginTop: 10, marginBottom: 50}}  size={"large"} type="primary" htmlType="submit" disabled={isSaving} icon={isSaving ? <LoadingOutlined /> : null}>
          Save password
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRouter(UserPasswordForm);

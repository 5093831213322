export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_IS_SAVING = 'SET_IS_USER_SAVING';
export const SET_IS_DELETING = 'SET_IS_DELETING';

export const SUBMIT_USER_SUCCESS = 'SUBMIT_USER_SUCCESS';
export const SUBMIT_USER_FAILURE = 'SUBMIT_USER_FAILURE';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

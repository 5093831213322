import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

import utc from 'dayjs/plugin/utc'
import {getUserTimeInLieuDays} from "./userUtils";

dayjs.extend(utc)

export const AM_FENCE_HOUR = 11;
export const PM_FENCE_HOUR = 13;

export const getAllDates = (year, month) => {
  let date = new Date(year, month, 1, 0, 0, 0, 0);
  let dates = [];
  let i = 0;
  while (date.getMonth() === month) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
    i++;
  }

  return dates;
}

export const annualStartDate = (company, year) => {
  const annualResetMonth = company.get('annual_reset_month');
  const todayDate = dayjs.utc(dayjs.utc().format('YYYY-MM-DD'));

  return annualResetMonth > todayDate.month() ? todayDate.year(year).subtract(1, 'year').month(annualResetMonth).date(1) : todayDate.year(year).month(annualResetMonth).date(1);
}

export const countUserBookedMidDates = (company, leaveBookings, leaveTypes, workSchedules, companyHolidays, financialYear) => {
  let bookedMidDatesCounts = 0;
  let jsDate = dayjs.utc(dayjs.utc().format('YYYY-MM-DD'));

  let annualStart = annualStartDate(company, financialYear);
  jsDate = annualStart.add(1, 'year');

  while (jsDate.isSameOrAfter(annualStart, 'day')) {
    const dateInFormat = jsDate.format('YYYY-MM-DD');
    if (companyHolidays.find((holiday) => dateInFormat === holiday.date)) {
      jsDate = jsDate.subtract(1, 'day')
      continue;
    }

    const weekday = jsDate.day();
    // skip weekends by default when work schedule is not set for user
    if (workSchedules.length === 0 && (weekday === 0 || weekday === 6)) {
      jsDate = jsDate.subtract(1, 'day')
      continue;
    }

    const workDaySchedule = workSchedules.find((schedule) => schedule.get('week_day') === weekday);
    const noneWorkAM = workDaySchedule ? !workDaySchedule.get('am_available') : (weekday === 0 || weekday === 6);
    const noneWorkPM = workDaySchedule ? !workDaySchedule.get('pm_available') : (weekday === 0 || weekday === 6);

    jsDate = jsDate.hour(AM_FENCE_HOUR);
    if (!noneWorkAM && leaveBookings.findIndex((leaveBooking) => jsDate.isBetween(dayjs.utc(leaveBooking.get('from')), dayjs.utc(leaveBooking.get('to')))) >= 0) {
      // if (leaveBookings.findIndex((leaveBooking) => jsDate.isBetween(dayjs.utc(leaveBooking.get('from')), dayjs.utc(leaveBooking.get('to')))) >= 0) {
      bookedMidDatesCounts++;
    }

    jsDate = jsDate.hour(PM_FENCE_HOUR);
    if (!noneWorkPM && leaveBookings.findIndex((leaveBooking) => jsDate.isBetween(dayjs.utc(leaveBooking.get('from')), dayjs.utc(leaveBooking.get('to')))) >= 0) {
      // if (leaveBookings.findIndex((leaveBooking) => jsDate.isBetween(dayjs.utc(leaveBooking.get('from')), dayjs.utc(leaveBooking.get('to')))) >= 0) {
      bookedMidDatesCounts++;
    }
    jsDate = jsDate.subtract(1, 'day')
  }

  return bookedMidDatesCounts / 2;
}

export const countBookingMidDates = (leaveBooking, workSchedules, companyHolidays) => {
  let endDate = dayjs(leaveBooking.get('to').substring(0, 10)).hour(leaveBooking.get('to').substring(11, 13));

  let beginDate = dayjs(leaveBooking.get('from').substring(0, 10)).hour(leaveBooking.get('from').substring(11, 13));

  return countAvailableMidDates(beginDate, endDate, workSchedules, companyHolidays);
}

export const countAvailableMidDates = (beginDate, endDate, workSchedules, companyHolidays) => {
  let bookedMidDatesCounts = 0;
  let curDate = endDate;

  while (curDate.isSameOrAfter(beginDate, 'day')) {
    const weekday = curDate.day();
    const dateInFormat = curDate.format('YYYY-MM-DD');

    if (companyHolidays.find((holiday) => dateInFormat === holiday.date)) {
      curDate = curDate.subtract(1, 'day')
      continue;
    }

    // skip weekends by default schedule
    if (workSchedules.length === 0 && (weekday === 0 || weekday === 6)) {
      curDate = curDate.subtract(1, 'day')
      continue;
    }

    const workDaySchedule = workSchedules.find((schedule) => schedule.get('week_day') === weekday);
    const noneWorkAM = workDaySchedule ? !workDaySchedule.get('am_available') : (weekday === 0 || weekday === 6);
    const noneWorkPM = workDaySchedule ? !workDaySchedule.get('pm_available') : (weekday === 0 || weekday === 6);

    const amDate = curDate.hour(AM_FENCE_HOUR);
    if (!noneWorkAM && amDate.isAfter(beginDate)) {
      bookedMidDatesCounts++;
    }

    const pmDate = curDate.hour(PM_FENCE_HOUR);
    if (!noneWorkPM && pmDate.isAfter(beginDate) && endDate.isAfter(curDate.hour(PM_FENCE_HOUR))) {
      bookedMidDatesCounts++;
    }
    curDate = curDate.subtract(1, 'day')
  }

  return bookedMidDatesCounts / 2;
}

export const userCurrentAllowance = (user, year) => {
  const currentAllowance = user.get('leave_allowances').find((leaveAllowance) => leaveAllowance.get('year') === year);
  return currentAllowance ? currentAllowance.get('allowance') : 0;
}

export const userCurrentBroughtForward = (user, year) => {
  const currentAllowance = user.get('leave_allowances').find((leaveAllowance) => leaveAllowance.get('year') === year);
  return currentAllowance ? currentAllowance.get('brought_forward') : 0;
}

export const userYearlyTotalAllowance = (user, year) => {
  const curYear = dayjs().year();
  return userCurrentAllowance(user, year) + userCurrentBroughtForward(user, year) + (curYear === year ? getUserTimeInLieuDays(user) : 0);
}

export const userYearlyTakenAllowance = (user, companies, leaveBookings, leaveTypes, bankHolidays, year) => {
  const company = companies.find((company) => company.get('id') === user.get('company_id'));

  const companyHolidays = bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')}))

   return countUserBookedMidDates(
      company,
      leaveBookings.filter((leaveBooking) => leaveBooking.get('approved') && leaveBooking.get('canceled') === null &&
          leaveBooking.get('user_id') === user.get('id') &&
          leaveTypes.find((leaveType) => leaveType.get('id') === leaveBooking.get('leave_type_id')).get('deduct_allowance')),
      leaveTypes,
      user.get('work_schedules'), companyHolidays, year
  )
}

export const userTakenNonDeductedAllowance = (user, companies, leaveBookings, leaveTypes, bankHolidays, year) => {
  const company = companies.find((company) => company.get('id') === user.get('company_id'));

  const companyHolidays = bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')}))

  return countUserBookedMidDates(
      company,
      leaveBookings.filter((leaveBooking) => leaveBooking.get('approved') && leaveBooking.get('canceled') === null &&
          leaveBooking.get('user_id') === user.get('id')),
      leaveTypes,
      user.get('work_schedules'), companyHolidays, year
  )
}

const Days = {
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'T',
  Friday: 'F',
  Saturday: 'S',
  Sunday: 'S',
}

export const DayOffset = {
  Monday: '6',
  Tuesday: '5',
  Wednesday: '4',
  Thursday: '3',
  Friday: '2',
  Saturday: '1',
  Sunday: '0',
}

export const daysOfTheWeek = [
  Days.Monday,
  Days.Tuesday,
  Days.Wednesday,
  Days.Thursday,
  Days.Friday,
  Days.Saturday,
  Days.Sunday,
]

export const daysOfTheWeekOffset = [
  DayOffset.Monday,
  DayOffset.Tuesday,
  DayOffset.Wednesday,
  DayOffset.Thursday,
  DayOffset.Friday,
  DayOffset.Saturday,
  DayOffset.Sunday,
]

export const MONTH_NAMES = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

export const getMonthName = (month) => {
  return MONTH_NAMES[month + 1]
}

export const WEEK_NAMES = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  0: 'Sunday',
}

export const getWeekdayName = (day) => {
  return WEEK_NAMES[day]
}

export const getDaysInDayRange = (startDate, endDate) => {
  const jsDays = []
  let curDate = startDate;
  while(curDate.isSameOrBefore(endDate)) {
    jsDays.push(curDate);
    curDate = curDate.add(1, 'day');
  }

  return jsDays;
}

export const getDaysInMonth = (year, month) => {
  const totalDays = dayjs(`${year}/${month}`).daysInMonth();

  return Array.from({ length: totalDays }, (value, index) => index+1).map((day) => dayjs(`${year}/${month}/${day}`));
}

export const getAllMdDates = (daysRange, userBookings, companyHolidays, selectDuration, hoverDuration) => {

  const mdDates = [];
  daysRange.forEach((jsDay) => {
    const dateInFormat = dayjs(jsDay).format('YYYY-MM-DD');

    ['am', 'pm'].forEach((md) => {
      const mdHour = dayjs(jsDay.format('YYYY-MM-DD') + `T${md === 'am' ? `${AM_FENCE_HOUR}` : `${PM_FENCE_HOUR}`}:00:00Z`);

      let mdDate = {
        date: jsDay,
        md: md,
        status: 'available',
        booking: null,
        holiday: '',
      }
      const matchedHoliday = companyHolidays.find((holiday) => dateInFormat === holiday.date);
      if (matchedHoliday) {
        mdDate.status = 'holiday';
        mdDate.holiday = matchedHoliday.name;
      } else {
        userBookings.forEach((booking) => {
          if (mdHour.isBetween(booking.get('from'), booking.get('to'), 'hour')) {
            if (booking.get('approved') === true)
              mdDate.status = 'booked';
            else
              mdDate.status = 'pending';

            mdDate.booking = booking;
          }
        });
      }

      if (mdDate.status !== 'booked' && mdDate.status !== 'pending') {
        if (hoverDuration && mdHour.isBetween(hoverDuration.from, hoverDuration.to)) {
          mdDate.status = 'hovered';
        }

        if (selectDuration && mdHour.isBetween(selectDuration.from, selectDuration.to)) {
          mdDate.status = 'selected';
        }
      }
      mdDates.push(mdDate);
    })
  })

  return mdDates.reduce((accumulator, currentValue, currentIndex, array) => {
    if (currentIndex % 2 === 0) {
      accumulator.push(array.slice(currentIndex, currentIndex + 2));
    }
    return accumulator;
  }, [])
}
import React, {useState} from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Typography,
  Row,
  Col,
  Layout,
  Divider
} from 'antd';
const { Sider, Content } = Layout;
import UserSettingsScreen from "../UserSettingsScreen/UserSettingsScreen";
import {useAuth} from "../../context/auth";
import CompanyForm from "../CompanyForm/CompanyForm";
import CompanyAdminDelegationForm from "../CompanyAdminDelegationForm/CompanyAdminDelegationForm";
import CompanyPublicHolidaysForm from "../CompanyPublicHolidaysForm/CompanyPublicHolidaysForm";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import {bindActionCreators} from "redux";
import * as companiesActionCreators from "../../actions/companiesActionCreators";
import * as usersActionCreators from "../../actions/usersActionCreators";

dayjs.extend(isBetween);

const {Title} = Typography;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    bankRegions: state.$$bankRegionsStore.get('$$bankRegions'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
    isSaving: state.$$companiesStore.get('isSaving'),
    isUserSaving: state.$$usersStore.get('isSaving'),
  };
}

const ManagerSettingsScreen = (props) => {
  const items = [
    {
      key: 'user_settings',
      label: 'User Settings',
      style: {paddingLeft: 0, height: 30, lineHeight: 1, fontSize: 14, fontWeight: 'bold'},
      onClick: () => setSelectedPage('user_settings')
    },
    {
      key: 'company_settings',
      label: 'Company Settings',
      style: {paddingLeft: 0, height: 30, lineHeight: 1, fontSize: 14, fontWeight: 'bold'},
      onClick: () => setSelectedPage('company_settings')
    },
    {
      key: 'public_holidays',
      label: 'Public Holidays',
      style: {paddingLeft: 0, height: 30, lineHeight: 1, fontSize: 14, fontWeight: 'bold'},
      onClick: () => setSelectedPage('public_holidays')
    },
  ];


  const {companies, activeCompanyId, isSaving, bankHolidays, bankRegions, users, isUserSaving} = props;
  const [selectedPage, setSelectedPage] = useState('user_settings');

  const {updateCompany} = bindActionCreators(companiesActionCreators, props.dispatch);
  const {updateUser} = bindActionCreators(usersActionCreators, props.dispatch);

  const auth = useAuth();
  const company = companies.find((company) => (auth.user.role === 'accountant' &&  company.get('id') === activeCompanyId) || company.get('id') === auth.user.company_id);

  const onFinish = (form, values) => {
    if (values.id) {
      updateCompany(values.id, {
        company: {
          ...values,
          bank_holidays_attributes: values.bank_holidays
        }
      });
    }
  };

  const onFinishDeletation = (form, values) => {
    users.filter((user) => user.get('role') === 'company_admin' && !values.company_admins.map((admin) => admin.user_id).includes(user.get('id')))
      .map((removedAdmin) => {
        updateUser(removedAdmin.get('id'), {
          role: 'user'
        });
      })

    values.company_admins.forEach((admin) => {
      if (admin.user_id) {
        updateUser(admin.user_id, {
          role: 'company_admin'
        });
      }
    })
  };

  return (
      <Layout style={{padding: 20, maxWidth: 1200, width: '100%', margin: 'auto', height: 'calc(100vh - 100px)'}}>
        <Sider style={{borderRight: '1px #ddd solid'}} width={150}>
          <Title level={4}>Timeoff Setup</Title>
          <Menu
              defaultSelectedKeys={['user_settings']}
              selectedKeys={[selectedPage]}
              items={items}
          />
        </Sider>

        {selectedPage === 'user_settings' &&
        <Content style={{padding: '0 24px'}}>
          <UserSettingsScreen/>
        </Content>}

        {selectedPage === 'company_settings' &&
        <Content style={{padding: '0 24px', width: "auto"}}>
          <Row justify="start" align="baseline">
            <Col span={24} align="center">
              <Title level={1}>General Settings</Title>
              <p>Your basic organization settings</p>
            </Col>
          </Row>
          <Row style={{marginBottom: 20, marginTop: 40, alignItems: 'start'}}>
            <Col span={12} style={{display: "flex", alignItems: "center"}}>
              {company &&
              <CompanyForm
                  onFinish={onFinish}
                  isSaving={isSaving}
                  initValues={{
                    id: company.get('id'),
                    name: company.get('name'),
                    annual_reset_month: company.get('annual_reset_month'),
                    allowance_carry_over: company.get('allowance_carry_over'),
                    hide_leave_types: company.get('hide_leave_types'),
                    bank_region_id: company.get('bank_region_id')
                  }}
                  disabled={auth.user.role !== 'company_admin'}
              />}
            </Col>
            <Col span={12} style={{display: "flex", flexDirection: 'column'}}>
              <Title level={4} style={{marginBottom: 0}}>Select Company Admins</Title>
              {company &&
              <CompanyAdminDelegationForm
                  users={users}
                  onFinish={onFinishDeletation}
                  isSaving={isUserSaving}
                  initValues={{
                    company_admins: Array.from(users.filter((user) => user.get('role') === 'company_admin').map((user) => ({user_id: user.get('id')}))),
                  }}
                  disabled={auth.user.role !== 'company_admin'}
              />}
            </Col>
          </Row>
        </Content>}

        {selectedPage === 'public_holidays' &&
        <Content style={{padding: '0 24px'}}>
          <Row justify="start" align="baseline">
            <Col span={24} align="center">
              <Title level={1}>Public Holidays</Title>
              <p>Changes to this list will apply to all users using this location.</p>
            </Col>
          </Row>
          <Row style={{marginBottom: 20, marginTop: 40}}>
            <Col span={24} style={{display: "flex", alignItems: "center"}}>
              {company &&
              <CompanyPublicHolidaysForm
                  onFinish={onFinish}
                  bankHolidays={Array.from(bankHolidays)}
                  bankRegions={bankRegions}
                  isSaving={isSaving}
                  initValues={{
                    id: company.get('id'),
                    bank_holidays: Array.from(company.get('bank_holidays')),
                    bank_region_id: company.get('bank_region_id')
                  }}
                  disabled={auth.user.role !== 'company_admin'}
              />}
            </Col>
          </Row>
        </Content>}
    </Layout>
  );
}

export default connect(stateToProps)(ManagerSettingsScreen)
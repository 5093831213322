import React from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {Popover} from "antd";
import {ScheduleOutlined, GiftOutlined} from "@ant-design/icons";

dayjs.extend(isBetween)

const DateWrapper = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: 1px;
  text-align: center;
  line-height: 35px;
  color: ${props => (props.$amColor || props.$pmColor) && !props.$notAvailableAM && !props.$notAvailablePM ? '#FFFFFF' : '#0E0B4E'};
  background-image: linear-gradient(to top right, ${props => props.$amColor || 'transparent'} 50%, ${props => props.$pmColor || 'transparent'} 50%);
  
  span {
    font-size: 16px;
  }
  .am {
    z-index: 1;
    height: 36px;
    width: 18px;
    position: absolute;
    background: transparent;
    top: 0;
    &:hover {
      z-index: 0;
      &::after {
        content: "";
        display: block;
        width: 36px;
        height: 36px;
        background-image: ${props => props.$notAvailableAM ? 'none' : 'linear-gradient(to top right, #AEADC4 50%, transparent 50%)'};
        border-radius: 50%;
        opacity: 0.2;
      }
    }
  }
  .pm {
    z-index: 1;
    height: 36px;
    width: 18px;
    position: absolute;
    background: transparent;
    top: 0;
    left: 18px;
    &:hover {
      z-index: 0;
      &::after {
        content: "";
        display: block;
        width: 36px;
        height: 36px;
        margin-left: -18px;
        background-image: ${props => props.$notAvailablePM ? 'none' : 'linear-gradient(to bottom left, #AEADC4 50%, transparent 50%)'};
        border-radius: 50%;
        opacity: 0.2;
      }
    }
  }
  @media (max-width: 768px) {
    // width: 30px;
    // height: 30px;
    // line-height: 29px;
    span {
      // font-size: 12px;
    }
  }
`

function stateToProps(state) {
  return {
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
  };
}

class CalendarDateCell extends React.Component {

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !(this.props.mdDate[0].status === nextProps.mdDate[0].status &&
        this.props.mdDate[1].status === nextProps.mdDate[1].status &&
        this.props.mdDate[0].booking === nextProps.mdDate[0].booking &&
        this.props.mdDate[1].booking === nextProps.mdDate[1].booking &&
        this.props.dragStartDate === nextProps.dragStartDate);
  }

  render() {
    const {
      mdDate,
      user,
      ownerView,
      leaveTypes,
      dragStartDate,
      dragStartDateMid,
      onChangeDragStartDate,
      onChangeDragStartDateMid,
      onChangeDragEndDate,
      onChangeDragEndDateMid,
      onChangeDragHoverDate,
      onChangeDragHoverDateMid,
      onChangeActiveBooking,
      hideLeaveTypes
    } = this.props

    const leaveTypeAM = !mdDate[0].booking ? null :
        leaveTypes.find((lt) => lt.get('id') === mdDate[0].booking.get('leave_type_id')).get('name') + (mdDate[0].booking.get('notes') ? ' - ' + `'${mdDate[0].booking.get('notes')}'` : '');
    const leaveTypePM = !mdDate[1].booking ? null :
        leaveTypes.find((lt) => lt.get('id') === mdDate[1].booking.get('leave_type_id')).get('name') + (mdDate[1].booking.get('notes') ? ' - ' + `'${mdDate[1].booking.get('notes')}'` : '');

    let leaveTypeColorAM = !mdDate[0].booking || mdDate[0].status !== 'booked' ? (mdDate[0].status === 'pending' ? '#FF5CF4' : (mdDate[0].status === 'selected' || mdDate[0].status === 'hovered' ? '#AEADC4' : null)) : leaveTypes.find((lt) => lt.get('id') === mdDate[0].booking.get('leave_type_id')).get('ui_color');
    let leaveTypeColorPM = !mdDate[1].booking || mdDate[1].status !== 'booked' ? (mdDate[1].status === 'pending' ? '#FF5CF4' : (mdDate[1].status === 'selected' || mdDate[1].status === 'hovered' ? '#AEADC4' : null)) : leaveTypes.find((lt) => lt.get('id') === mdDate[1].booking.get('leave_type_id')).get('ui_color');

    if (hideLeaveTypes && leaveTypeColorAM) {
      leaveTypeColorAM = leaveTypes.find((lt) => lt.get('name') === 'Holiday').get('ui_color');
    }
    if (hideLeaveTypes && leaveTypeColorPM) {
      leaveTypeColorPM = leaveTypes.find((lt) => lt.get('name') === 'Holiday').get('ui_color');
    }

    const dayOfTheWeek = dayjs(mdDate[0].date).day();

    const workDaySchedule = user.get('work_schedules').find((schedule) => schedule.get('week_day') === dayOfTheWeek);
    const noneWorkAM = workDaySchedule ? !workDaySchedule.get('am_available') : (dayOfTheWeek === 0 || dayOfTheWeek === 6);
    const noneWorkPM = workDaySchedule ? !workDaySchedule.get('pm_available') : (dayOfTheWeek === 0 || dayOfTheWeek === 6);
    leaveTypeColorAM = noneWorkAM ? '#E4E4E4' : leaveTypeColorAM;
    leaveTypeColorPM = noneWorkPM ? '#E4E4E4' : leaveTypeColorPM;

    return (
        <DateWrapper
            $amColor={leaveTypeColorAM}
            $pmColor={leaveTypeColorPM}
            $notAvailableAM={noneWorkAM}
            $notAvailablePM={noneWorkPM}
            $employmentStarted={dayjs(mdDate[0].date).isSame(dayjs(user.get('employment_from')), 'days')}
        >
          <Popover
              content={mdDate[0].status === 'holiday' ? mdDate[0].holiday : ((noneWorkAM || !ownerView) ? null : leaveTypeAM)}
          >
            <div className={`am`} onClick={() => {
              if (noneWorkAM || !ownerView)
                return;

              if (mdDate[0].status === 'booked' || mdDate[0].status === 'pending') {
                onChangeActiveBooking(mdDate[0].booking ? mdDate[0].booking.get('id') : null);
                return;
              }

              if (!dragStartDate) {
                // setDragEmployee(user.get('id'));
                onChangeDragStartDate(mdDate[0].date);
                onChangeDragStartDateMid('am');
              } else {
                if (dayjs(dragStartDate).isAfter(mdDate[0].date, 'day') || (dayjs(dragStartDate).isSame(mdDate[0].date))) {
                  // replace start and end
                  onChangeDragEndDate(dragStartDate);
                  onChangeDragEndDateMid(dragStartDateMid);
                  onChangeDragStartDate(mdDate[0].date);
                  onChangeDragStartDateMid('am');
                } else {
                  onChangeDragEndDate(mdDate[0].date);
                  onChangeDragEndDateMid('am');
                }
              }
            }} onMouseOver={() => {
              if (dragStartDate && (dragStartDate !== mdDate[0].date || dragStartDate === mdDate[0].date && dragStartDateMid !== 'am')) {
                onChangeDragHoverDate(mdDate[0].date);
                onChangeDragHoverDateMid('am');
              }
            }}/>
          </Popover>
          <Popover
              content={mdDate[1].status === 'holiday' ? mdDate[1].holiday : ((noneWorkAM || !ownerView) ? null : leaveTypePM)}
          >
            <div className={`pm`} onClick={() => {
              if (noneWorkPM || !ownerView)
                return;

              if (mdDate[1].status === 'booked' || mdDate[1].status === 'pending') {
                onChangeActiveBooking(mdDate[1].booking ? mdDate[1].booking.get('id') : null);
                return;
              }

              if (!dragStartDate) {
                // setDragEmployee(user.get('id'));
                onChangeDragStartDate(mdDate[1].date);
                onChangeDragStartDateMid('pm');
              } else {
                if (dayjs(dragStartDate).isAfter(mdDate[1].date, 'day')) {
                  // replace start and end
                  onChangeDragEndDate(dragStartDate);
                  onChangeDragEndDateMid(dragStartDateMid);
                  onChangeDragStartDate(mdDate[1].date);
                  onChangeDragStartDateMid('pm');
                } else {
                  onChangeDragEndDate(mdDate[1].date);
                  onChangeDragEndDateMid('pm');
                }
              }
            }} onMouseOver={() => {
              if (dragStartDate && (dragStartDate !== mdDate[1].date || dragStartDate === mdDate[1].date && dragStartDateMid !== 'pm')) {
                onChangeDragHoverDate(mdDate[1].date);
                onChangeDragHoverDateMid('pm');
              }
            }}/>
          </Popover>
          <span>
            {mdDate[0].status === 'holiday' ?
              <ScheduleOutlined /> :
              ((mdDate[0].date.month() === dayjs(user.get('birth_date')).month() && mdDate[0].date.date() === dayjs(user.get('birth_date')).date() && ownerView) ?
                  <GiftOutlined/> :
                  dayjs(mdDate[0].date).date())
            }
          </span>
        </DateWrapper>
    )
  }
}

export default connect(stateToProps)(CalendarDateCell)

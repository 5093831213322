import React from 'react';
import BaseComponent from 'libs/components/BaseComponent';
import {Typography} from 'antd';
import SignupForm from '../SignupForm/SignupForm';

import css from './SignupScreen.module.scss';

const { Title } = Typography;

export default class SignupScreen extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {

    return (
        <div className={css.loginFormContainer}>
          <Title level={1}>Join TimeOff for free</Title>
          <Title level={4}>Test drive the full TimeOff feature set <a>free for one month</a> - no card required</Title>
          <SignupForm />
        </div>
    );
  }
}

import React, {useState} from 'react';
import { connect } from 'react-redux';
import {Form, Button, Typography, Space, Badge, Row, Col} from 'antd';
import {withRouter } from 'react-router-dom';
import {CloseOutlined} from "@ant-design/icons";

const { Title } = Typography;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    departments: state.$$departmentsStore.get('$$departments'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
  };
}

function WallchartFilterDrawer(props) {
  const {activeCompanyId, users, departments, open, department, year, month, onChangeYear, onChangeMonth, onChangeDepartment, onCloseFilter} = props;

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const curYear = (new Date).getFullYear();

  const arrayRange = (start, stop, step) =>
      Array.from(
          { length: (stop - start) / step + 1 },
          (value, index) => start + index * step
      );

  return (
    <div style={{display: (open ? 'block' : 'none'), width: '100%', position: 'sticky', bottom: 0, backgroundColor: 'white', padding: 30,
       boxShadow: '0px -6px 6px -6px rgba(0, 0, 0, 0.102)'}}>
      <Row>
        <Col>
          <Title level={4} style={{marginBottom: 10}}>Filter by department</Title>
          <Space wrap style={{marginBottom: 10}}>
            <Button type={'all' === department ? "primary" : "default"} style={{height: 34, borderRadius: 34, lineHeight: '30px', fontSize: 16, padding: '1px 8px'}}
                    color={"#0023C4"} onClick={() => onChangeDepartment('all')}>
              <span style={{paddingLeft: 10, paddingRight: 5}}>All members</span>
              <Badge
                  color={"#0E0B4E"}
                  count={users.filter((user) => user.get('company_id') === activeCompanyId).size}
                  style={{border: 0, top: -2, left: 3}}/>
            </Button>
            {departments.filter((d) => users.filter((user) => user.get('company_id') === activeCompanyId && user.get('department_id') === d.get('id')).size).map((d) => (
                <Button key={d.get('id')} type={d.get('id') === department ? "primary" : "default"} style={{height: 34, borderRadius: 34, lineHeight: '30px', fontSize: 16, padding: '1px 8px'}}
                        color={"#0023C4"} onClick={() => onChangeDepartment(d.get('id'))}>
                  <span style={{paddingLeft: 10, paddingRight: 5}}>{d.get('name')}</span>
                  <Badge
                      color={"#0E0B4E"}
                      count={users.filter((user) => user.get('company_id') === activeCompanyId && user.get('department_id') === d.get('id')).size}
                      style={{border: 0, top: -2, left: 3}}/>
                </Button>
            ))}
          </Space>
        </Col>
        {/*<Col span={8}>*/}
        {/*  <Title level={4} style={{marginBottom: 30}}>Filter by month</Title>*/}
        {/*  {arrayRange(0, 11, 1).map((mn) => (*/}
        {/*      <Button key={mn} type={month === mn ? "primary" : "default"} style={{lineHeight: 1, height: 34, borderRadius: 34, fontSize: 16, marginRight: 15, marginBottom: 15}}*/}
        {/*              color={"#0023C4"} onClick={() => onChangeMonth(mn)}>*/}
        {/*        <span>{months[mn]}</span>*/}
        {/*      </Button>*/}
        {/*  ))}*/}
        {/*</Col>*/}
        {/*<Col span={8}>*/}
        {/*  <Title level={4} style={{marginBottom: 30}}>Filter by year</Title>*/}
        {/*  {arrayRange(curYear-1, curYear+2, 1).map((y) => (*/}
        {/*      <Button key={y} type={year === y ? "primary" : "default"} style={{lineHeight: 1, height: 34, borderRadius: 34, fontSize: 16, marginRight: 15, marginBottom: 15}}*/}
        {/*              color={"#0023C4"} onClick={() => onChangeYear(y)}>*/}
        {/*        <span>{y}</span>*/}
        {/*      </Button>*/}
        {/*  ))}*/}
        {/*</Col>*/}
      </Row>

      <Row style={{justifyContent: 'space-around'}}>
        <Button size={"large"} type="default" onClick={onCloseFilter}
                style={{borderRadius: 25}}>Close<CloseOutlined/></Button>
      </Row>

    </div>
  );
}

export default connect(stateToProps)(withRouter(WallchartFilterDrawer));

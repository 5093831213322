import React from 'react';
import {Link} from 'react-router-dom';
import {HOME_PATH, LOGIN_ROUTER_PATH, ROOT_PATH, SIGNUP_ROUTER_PATH} from "../constants/paths";
import BaseComponent from '../../../libs/components/BaseComponent.jsx';

class LandingPageContainer extends BaseComponent {
  render() {
    return (
        <ul style={{marginTop: 100, fontFamily: 'SourceSansPro', fontSize: 18}}>
          <li>
            <Link to={ROOT_PATH}>
              Timeoff
            </Link>
          </li>
          <li>
            <Link to={HOME_PATH}>
              Home
            </Link>
          </li>
          <li>
            <Link to={LOGIN_ROUTER_PATH}>
              Login
            </Link>
          </li>
          <li>
            <Link to={SIGNUP_ROUTER_PATH}>
              Signup
            </Link>
          </li>
        </ul>
    );
  }
}

export default (LandingPageContainer);

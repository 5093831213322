export const FETCH_LEAVE_BOOKINGS_SUCCESS = 'FETCH_LEAVE_BOOKINGS_SUCCESS';
export const FETCH_LEAVE_BOOKINGS_FAILURE = 'FETCH_LEAVE_BOOKINGS_FAILURE';

export const SUBMIT_LEAVE_BOOKING_SUCCESS = 'SUBMIT_LEAVE_BOOKING_SUCCESS';
export const SUBMIT_LEAVE_BOOKING_FAILURE = 'SUBMIT_LEAVE_BOOKING_FAILURE';

export const UPDATE_LEAVE_BOOKING_SUCCESS = 'UPDATE_LEAVE_BOOKING_SUCCESS';
export const UPDATE_LEAVE_BOOKING_FAILURE = 'UPDATE_LEAVE_BOOKING_FAILURE';

export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_IS_SAVING = 'SET_IS_LEAVE_BOOKING_SAVING';

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  FloatButton,
  Modal,
  Typography,
  Badge,
  Input,
  Button,
  Space,
  Pagination,
  Row,
  Col,
  Avatar,
} from 'antd';
import {
  PlusOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  SearchOutlined,
  ArrowUpOutlined,
  CloseOutlined, DownOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import {connect} from "react-redux";
import LeaveBookingForm from "../LeaveBookingForm/LeaveBookingForm";
import {bindActionCreators} from "redux";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";

const {Title} = Typography;

function stateToProps(state) {
  return {
    users: state.$$usersStore.get('$$users'),
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    departments: state.$$departmentsStore.get('$$departments'),
    leaveTypes: state.$$leaveTypesStore.get('$$leaveTypes'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    isSaving: state.$$leaveBookingsStore.get('isSaving'),
  };
}

const LeaveBookingModal = (props) => {
  const {
    managerView,
    ownerView,
    users,
    leaveBookings,
    leaveTypes,
    activeBookingId,
    onCloseBookingInfo,
    onFinishBooking,
    onCancelBooking,
    onApproveBooking,
    onDeclineBooking,
    isSaving
  } = props;

  const activeBooking = leaveBookings.find((leaveBooking) => leaveBooking.get('id') === activeBookingId);

  return (
      <Modal open={activeBookingId} footer={null} closable={false} destroyOnClose>
        {activeBooking &&
        <>
          <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 0}}>
            <Title level={2}>Booking Info</Title>
            <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onCloseBookingInfo}
                    style={{width: 50}}/>
          </Space>

          <Space align={'start'} style={{flexDirection: 'column', justifyContent: "space-between", width: '100%', marginBottom: 20}} size={0}>
            <p className={'help-block'}>{`Requested ${dayjs.utc(activeBooking.get('created_at')).format('ddd, DD MMM YYYY')} by ${users.find((user) => user.get('id') === activeBooking.get('user_id')).get('first_name')} ${users.find((user) => user.get('id') === activeBooking.get('user_id')).get('last_name')}`}</p>
            <p className={'help-block'}>{activeBooking.get('approved') ? `Approved ${dayjs.utc(activeBooking.get('approved_at')).format('ddd, DD MMM YYYY')} by ${users.find((user) => user.get('id') === activeBooking.get('approved_by_id')).get('first_name')} ${users.find((user) => user.get('id') === activeBooking.get('approved_by_id')).get('last_name')}` : ''}</p>
            <p className={'help-block'}>{activeBooking.get('declined') ? `Declined ${dayjs.utc(activeBooking.get('declined_at')).format('ddd, DD MMM YYYY')} by ${users.find((user) => user.get('id') === activeBooking.get('declined_by_id')).get('first_name')} ${users.find((user) => user.get('id') === activeBooking.get('declined_by_id')).get('last_name')}` : ''}</p>
          </Space>

          <LeaveBookingForm
              leaveTypes={leaveTypes}
              isSaving={isSaving}
              onFinish={onFinishBooking}
              onCancel={onCancelBooking}
              onDecline={onDeclineBooking}
              onApprove={onApproveBooking}
              managerView={managerView}
              ownerView={ownerView}
              users={users}
              initValues={{
                from_date: dayjs.utc(activeBooking.get('from')),
                to_date: dayjs.utc(activeBooking.get('to')),
                from_mid: dayjs.utc(activeBooking.get('from')).hour() === 12 ? 'pm' : 'am',
                to_mid: dayjs.utc(activeBooking.get('to')).hour() === 23 ? 'pm' : 'am',
                ...Object.fromEntries(activeBooking)
              }}
          />
        </>
        }
      </Modal>
  );
}

export default connect(stateToProps)(LeaveBookingModal)

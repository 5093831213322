import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import BaseComponent from '../../../libs/components/BaseComponent.jsx';

import NavigationBar from '../components/NavigationBar/NavigationBar.jsx';
import {bindActionCreators} from "redux";
import * as authActionCreators from "../actions/authActionCreators";

function stateToProps(state) {
  // Which part of the Redux global state does our component want to receive as props?
  if (state.$$mainStore) {
    return {
      email: state.$$mainStore.get('$$user').get('email'),
      pathname: state.railsContext.pathname,
    };
  }
  return {};
}

class NavigationBarContainer extends BaseComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  };

  render() {
    const { email, pathname, dispatch } = this.props;
    const actions = bindActionCreators(authActionCreators, dispatch);

    return <NavigationBar {...{ email, pathname, actions }} />;
  }
}

export default connect(stateToProps)(NavigationBarContainer);

import requestsManager from '../../../libs/requestsManager';
import * as actionTypes from '../constants/leaveTypesConstants';

export function setIsFetching() {
  return {
    type: actionTypes.SET_IS_FETCHING,
  };
}

export function fetchLeaveTypesSuccess(data) {
  return {
    type: actionTypes.FETCH_LEAVE_TYPES_SUCCESS,
    leaveTypes: data,
  };
}

export function fetchLeaveTypesFailure(error) {
  return {
    type: actionTypes.FETCH_LEAVE_TYPES_FAILURE,
    error,
  };
}

export function fetchLeaveTypes() {
  return (dispatch) => {
    dispatch(setIsFetching());
    return requestsManager
      .fetchEntities('leave_types')
      .then((res) => dispatch(fetchLeaveTypesSuccess(res.data)))
      .catch((error) => dispatch(fetchLeaveTypesFailure(error)));
  };
}

import React, {useState} from 'react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {Modal, Button, Space, Typography, Row, Col} from "antd";
import {daysOfTheWeek, MONTH_NAMES} from "../../../../libs/dateUtils";
import {CloseOutlined} from "@ant-design/icons";
import LeaveBookingForm from "../LeaveBookingForm/LeaveBookingForm";
import VtMonthCalendar from "../VtMonthCalendar/VtMonthCalendar";
import {bindActionCreators} from "redux";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import {connect} from "react-redux";

const { Title } = Typography;
dayjs.extend(isBetween)

const VtYearCalendar = ({
        dispatch,
        year,
        user,
        userBookings,
        companyHolidays,
        ownerView,
        managerView,
        onChangeActiveBooking
    }) => {
  const {submitLeaveBooking} = bindActionCreators(leaveBookingsActionCreators, dispatch);

  const [dragStartDate, setDragStartDate] = useState(null);
  const [dragStartDateMid, setDragStartDateMid] = useState(null);
  const [dragEndDate, setDragEndDate] = useState(null);
  const [dragEndDateMid, setDragEndDateMid] = useState(null);
  const [dragHoverDate, setDragHoverDate] = useState(null);
  const [dragHoverDateMid, setDragHoverDateMid] = useState(null);

  const onFinishManagerBooking = (form, values) => {
    submitLeaveBooking({
      leave_booking: {
        ...values,
        from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
        to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
      }
    }).then(() => {
      setDragStartDate(null);
      setDragEndDate(null);
      setDragHoverDate(null);
      form.resetFields();
    });
  };

  const onCloseManagerBooking = (form) => {
    setDragStartDate(null);
    setDragEndDate(null);
    setDragHoverDate(null);
  };

  const selection = {
    from: dayjs(dragStartDate).format('YYYY-MM-DD') + `T${dragStartDateMid === 'am' ? '00' : '12'}:00:00Z`,
    to: dayjs(dragEndDate).format('YYYY-MM-DD') + `T${dragEndDateMid === 'am' ? '12' : '23'}:00:00Z`
  };
  const hoveration = {
    from: dayjs(dragStartDate).format('YYYY-MM-DD') + `T${dragStartDateMid === 'am' ? '00' : '12'}:00:00Z`,
    to: dayjs(dragHoverDate).format('YYYY-MM-DD') + `T${dragHoverDateMid === 'am' ? '12' : '23'}:00:00Z`
  };

  return (
      <Row key={user.get('id')} style={{display: 'flex', flexWrap: 'wrap', gap: 30, justifyContent: 'center'}}>
        {
          Object.values(MONTH_NAMES).map((month, index) => {
              return (
                <div className='month' key={month}>
                  <Title level={4}>{month}</Title>
                  <Space style={{height: 50, display: 'flex', alignItems: 'center', flexWrap: 'nowrap', marginLeft: 4}}>
                  {daysOfTheWeek.map((dayOfTheWeek, pos) => {
                    return (
                        <div key={pos} style={{width: 30, textAlign: 'center', fontWeight: 'bold', color: '#0E0B4E'}}>
                          {dayOfTheWeek}
                        </div>
                    )
                  })}
                  </Space>
                  <VtMonthCalendar year={year} month={index + 1} user={user} onChangeActiveBooking={onChangeActiveBooking}
                                   userBookings={userBookings}
                                   companyHolidays={companyHolidays}
                                   selection={selection} hoveration={hoveration}
                                   dragStartDate={dragStartDate}
                                   dragStartDateMid={dragStartDateMid}
                                   onChangeDragStartDate={setDragStartDate}
                                   onChangeDragStartDateMid={setDragStartDateMid}
                                   onChangeDragEndDate={setDragEndDate}
                                   onChangeDragEndDateMid={setDragEndDateMid}
                                   onChangeDragHoverDate={setDragHoverDate}
                                   onChangeDragHoverDateMid={setDragHoverDateMid}
                                   ownerView={ownerView}
                  />


                </div>
            )
          }

          )
        }
        {ownerView &&
        <Modal open={dragStartDate && dragEndDate} footer={null} closable={false} destroyOnClose>
          <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 20}}>
            <Title level={2}>Book time off</Title>
            <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onCloseManagerBooking} style={{width: 50}}/>
          </Space>

          <LeaveBookingForm
              onFinish={onFinishManagerBooking}
              managerView={managerView}
              ownerView={ownerView}
              initValues={{
                user_id: user.get('id'),
                leave_type_id: 1,
                from_date: dayjs(dragStartDate),
                to_date: dayjs(dragEndDate),
                from_mid: dragStartDateMid,
                to_mid: dragEndDateMid
              }}
          />
        </Modal>}
      </Row>
  )
}

export default connect()(VtYearCalendar)

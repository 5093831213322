import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from '../../../libs/middlewares/loggerMiddleware';
import reducers, { initialStates } from '../reducers';

export default (props, railsContext) => {
  const { $$usersState, $$companiesState, $$leaveTypesState, $$bankHolidaysState, $$bankRegionsState, $$departmentsState, $$leaveBookingsState } = initialStates;
  const initialState = {
    $$usersStore: $$usersState,
    $$companiesStore: $$companiesState,
    $$leaveTypesStore: $$leaveTypesState,
    $$bankHolidaysStore: $$bankHolidaysState,
    $$bankRegionsStore: $$bankRegionsState,
    $$departmentsStore: $$departmentsState,
    $$leaveBookingsStore: $$leaveBookingsState,
    railsContext,
  };

  const reducer = combineReducers(reducers);
  const composedStore = compose(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};

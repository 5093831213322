import React, {useState} from 'react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import {Modal, Button, Space, Typography} from "antd";
import {getAllMdDates} from "../../../../libs/dateUtils";
import {CloseOutlined} from "@ant-design/icons";
import LeaveBookingForm from "../LeaveBookingForm/LeaveBookingForm";
import CalendarDateCell from "../CalendarDateCell/CalendarDateCell";
import {bindActionCreators} from "redux";
import * as leaveBookingsActionCreators from "../../actions/leaveBookingsActionCreators";
import {connect} from "react-redux";
import {useAuth} from "../../context/auth";

const { Title } = Typography;
dayjs.extend(isBetween)

function stateToProps(state) {
  return {
    companies: state.$$companiesStore.get('$$companies'),
    activeCompanyId: state.$$companiesStore.get('activeCompanyId'),
    leaveBookings: state.$$leaveBookingsStore.get('$$leaveBookings'),
    bankHolidays: state.$$bankHolidaysStore.get('$$bankHolidays'),
  };
}

const HzMonthCalendar = ({
                           dispatch,
                           dayRange,
                           user,
                           companies,
                           activeCompanyId,
                           leaveBookings,
                           bankHolidays,
                           ownerView,
                           managerView,
                           onChangeActiveBooking,
                           hideLeaveTypes
    }) => {

  const {submitLeaveBooking} = bindActionCreators(leaveBookingsActionCreators, dispatch);

  const [dragStartDate, setDragStartDate] = useState(null);
  const [dragStartDateMid, setDragStartDateMid] = useState(null);
  const [dragEndDate, setDragEndDate] = useState(null);
  const [dragEndDateMid, setDragEndDateMid] = useState(null);
  const [dragHoverDate, setDragHoverDate] = useState(null);
  const [dragHoverDateMid, setDragHoverDateMid] = useState(null);

  const onFinishManagerBooking = (form, values) => {
    submitLeaveBooking({
      leave_booking: {
        ...values,
        from: dayjs(values.from_date).format('YYYY-MM-DD') + `T${values.from_mid === 'am' ? '00' : '12'}:00:00Z`,
        to: dayjs(values.to_date).format('YYYY-MM-DD') + `T${values.to_mid === 'am' ? '12' : '23'}:00:00Z`,
      }
    }).then(() => {
      setDragStartDate(null);
      setDragEndDate(null);
      setDragHoverDate(null);
      form.resetFields();
    });
  };

  const onCloseManagerBooking = (form) => {
    setDragStartDate(null);
    setDragEndDate(null);
    setDragHoverDate(null);
  };

  const company = companies.find((company) => (user.get('role') === 'accountant' &&  company.get('id') === activeCompanyId) || company.get('id') === user.get('company_id'));

  const userBookings = leaveBookings.filter((leaveBooking) => leaveBooking.get('user_id') === user.get('id') && !leaveBooking.get('declined') && !leaveBooking.get('canceled'));
  const companyHolidays = bankHolidays.filter((holiday) => company.get('bank_holidays').findIndex((ch) => holiday.get('id') === ch.get('bank_holiday_id') && ch.get('subscribed') && company.get('bank_region_id') === ch.get('bank_region_id')) !== -1)
      .map((holiday) => ({date: dayjs(holiday.get('date')).format('YYYY-MM-DD'), name: holiday.get('name')}));

  const selection = {
    from: dayjs(dragStartDate).format('YYYY-MM-DD') + `T${dragStartDateMid === 'am' ? '00' : '12'}:00:00Z`,
    to: dayjs(dragEndDate).format('YYYY-MM-DD') + `T${dragEndDateMid === 'am' ? '12' : '23'}:00:00Z`
  };
  const hoveration = {
    from: dayjs(dragStartDate).format('YYYY-MM-DD') + `T${dragStartDateMid === 'am' ? '00' : '12'}:00:00Z`,
    to: dayjs(dragHoverDate).format('YYYY-MM-DD') + `T${dragHoverDateMid === 'am' ? '12' : '23'}:00:00Z`
  };

  const mdDatesInMonth = getAllMdDates(dayRange, userBookings, Array.from(companyHolidays), selection, hoveration);

  return (
      <div className='month' data-testid='month'>
        <div className='content' style={{display: 'flex', padding: 0}}>

          {mdDatesInMonth.map((mdDate, index) => {
            return <CalendarDateCell key={`${mdDate[0].date}`} mdDate={mdDate} user={user}
                                     ownerView={ownerView}
                                     dragStartDate={dragStartDate}
                                     dragStartDateMid={dragStartDateMid}
                                     onChangeDragStartDate={setDragStartDate}
                                     onChangeDragStartDateMid={setDragStartDateMid}
                                     onChangeActiveBooking={onChangeActiveBooking}
                                     onChangeDragEndDate={setDragEndDate}
                                     onChangeDragEndDateMid={setDragEndDateMid}
                                     onChangeDragHoverDate={setDragHoverDate}
                                     onChangeDragHoverDateMid={setDragHoverDateMid}
                                     hideLeaveTypes={!ownerView && hideLeaveTypes}
            />
          })}
        </div>

        {ownerView &&
        <Modal open={dragStartDate && dragEndDate} footer={null} closable={false} destroyOnClose>
          <Space align={"center"} style={{justifyContent: "space-between", width: '100%', marginBottom: 20}}>
            <Title level={2}>Book time off</Title>
            <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onCloseManagerBooking} style={{width: 50}}/>
          </Space>

          <LeaveBookingForm
              onFinish={onFinishManagerBooking}
              ownerView={ownerView}
              managerView={managerView}
              initValues={{
                user_id: user.get('id'),
                leave_type_id: 1,
                from_date: dayjs(dragStartDate),
                to_date: dayjs(dragEndDate),
                from_mid: dragStartDateMid,
                to_mid: dragEndDateMid
              }}
          />
        </Modal>}
      </div>
  )
}

export default connect(stateToProps)(HzMonthCalendar)

import React, {createContext, useContext, useState} from "react";
import {Redirect, Route, useHistory} from 'react-router-dom';
import requestsManager from "../../../libs/requestsManager";
import {HOME_PATH, LOGIN_ROUTER_PATH, MANAGERS_WALLCHART_PATH} from "../constants/paths";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
export const authContext = createContext();

export function ProvideAuth({children}) {
  const auth = useProvideAuth();
  return (
      <authContext.Provider value={auth}>
        {children}
      </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [token, setToken] = useState(localStorage.getItem('token'));

  const signIn = (values, cb) => {
    return requestsManager
        .submitLogin({user: values})
        .then((response) => {

          if (response.status === 201) {
            setUser(response.data);
            setToken(response.headers.authorization);

            localStorage.setItem('user', JSON.stringify(response.data));
            localStorage.setItem('token', response.headers.authorization);
          } else {
            console.log(response)
          }

          cb(response);
        })
        .catch((error) => {
          console.log(error);
          cb();
        });
  };

  const signUp = (values, cb) => {
    return requestsManager
        .submitSignup({user: values})
        .then((response) => {
          console.log(response);
          cb(response.data);
        })
        .catch((error) => {
          console.log(error);
          cb();
        });
  };

  const signOut = cb => {
    return requestsManager
        .logout(token)
        .then((response) => {
          setUser(null);
          localStorage.setItem('user', null);
          localStorage.setItem('token', null);
          cb();
        })
        .catch((error) => {
          console.log(error);
          setUser(null);
          localStorage.setItem('user', null);
          localStorage.setItem('token', null);
          cb();
        });
  };

  const createPassword = (values, cb) => {
    const queryParams = new URLSearchParams(window.location.search);
    const user = queryParams.get('user');
    const token = queryParams.get('token');

    return requestsManager
        .submitPassword({user: {...values, id: user, reset_password_token: token}})
        .then((response) => {
          cb(response.data);
        })
        .catch((error) => {
          console.log(error);
          cb();
        });
  };

  const resetPassword = (values, cb) => {
    return requestsManager
        .resetPassword({user: values})
        .then((response) => {

          cb(response);
        })
        .catch((error) => {
          console.log(error);
          cb();
        });
  };

  return {
    user,
    token,
    signIn,
    signOut,
    signUp,
    createPassword,
    resetPassword,
  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({children, ...rest}) {
  let auth = useAuth();
  return (
      <Route
          {...rest}
          render={({location}) =>
              auth.user && auth.user.role === 'user' ? (
                  children
              ) : (
                  <Redirect
                      to={{
                        pathname: LOGIN_ROUTER_PATH,
                        state: {from: location}
                      }}
                  />
              )
          }
      />
  );
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function ManagerPrivateRoute({children, ...rest}) {
  let auth = useAuth();
  return (
      <Route
          {...rest}
          render={({location}) =>
              auth.user && (auth.user.role === 'company_admin' || auth.user.role === 'accountant') ? (
                  children
              ) : (
                  <Redirect
                      to={{
                        pathname: LOGIN_ROUTER_PATH,
                        state: {from: location}
                      }}
                  />
              )
          }
      />
  );
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function HomeRoute({children, ...rest}) {
  let auth = useAuth();
  return (
      <Route
          {...rest}
          render={({location}) =>
              auth.user && (auth.user.role === 'company_admin' || auth.user.role === 'accountant') ? (
                  <Redirect
                      to={{
                        pathname: MANAGERS_WALLCHART_PATH,
                        state: {from: location}
                      }}
                  />
              ) : auth.user && auth.user.role === 'user' ? (
                  <Redirect
                      to={{
                        pathname: HOME_PATH,
                        state: {from: location}
                      }}
                  />
              ) : (
                  <Redirect
                      to={{
                        pathname: LOGIN_ROUTER_PATH,
                        state: {from: location}
                      }}
                  />
              )
          }
      />
  );
}

/* eslint new-cap: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/leaveBookingsConstants';

export const $$initialState = Immutable.fromJS({
  $$leaveBookings: [],
  fetchLeaveBookingError: null,
  submitLeaveBookingError: null,
  updateLeaveBookingError: null,
  isFetching: false,
  isSaving: false,
});

export default function leaveBookingsReducer($$state = $$initialState, action = null) {
  const { type, leaveBooking, leaveBookings, error } = action;

  switch (type) {
    case actionTypes.FETCH_LEAVE_BOOKINGS_SUCCESS: {
      return $$state.merge({
        $$leaveBookings: leaveBookings,
        fetchLeaveBookingError: null,
        isFetching: false,
      });
    }

    case actionTypes.FETCH_LEAVE_BOOKINGS_FAILURE: {
      return $$state.merge({
        fetchLeaveBookingError: error,
        isFetching: false,
      });
    }

    case actionTypes.SUBMIT_LEAVE_BOOKING_SUCCESS: {
      return $$state.withMutations((state) =>
          state
              .updateIn(['$$leaveBookings'], ($$leaveBookings) => {
                const index = $$leaveBookings.findIndex((com) => com.get('id') === leaveBooking.id);
                return index === -1
                    ? $$leaveBookings.unshift(Immutable.fromJS(leaveBooking))
                    : $$leaveBookings.set({ index, value: Immutable.fromJS(leaveBooking) });
              })
              .merge({
                submitLeaveBookingError: null,
                isSaving: false,
              }),
      );
    }

    case actionTypes.SUBMIT_LEAVE_BOOKING_FAILURE: {
      return $$state.merge({
        submitLeaveBookingError: error,
        isSaving: false,
      });
    }

    case actionTypes.UPDATE_LEAVE_BOOKING_SUCCESS: {
      return $$state.withMutations((state) =>
         state
             .updateIn(['$$leaveBookings'], ($$leaveBookings) =>
                  $$leaveBookings.map((com) => com.get('id') === leaveBooking.id ? Immutable.fromJS(leaveBooking) : com)
              )
              .merge({
                updateLeaveBookingError: null,
                isSaving: false,
              }),
      );
    }

    case actionTypes.UPDATE_LEAVE_BOOKING_FAILURE: {
      return $$state.merge({
        updateLeaveBookingError: error,
        isSaving: false,
      });
    }

    case actionTypes.SET_IS_FETCHING: {
      return $$state.merge({
        isFetching: true,
      });
    }

    case actionTypes.SET_IS_SAVING: {
      return $$state.merge({
        isSaving: true,
      });
    }

    default: {
      return $$state;
    }
  }
}

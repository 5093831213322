export const ROOT_PATH = '/';
export const LOGIN_ROUTER_PATH = '/login';
export const SIGNUP_ROUTER_PATH = '/signup';
export const FORGOT_PASSWORD_ROUTER_PATH = '/forgot-password';
export const SIGNUP_SUCCESS_ROUTER_PATH = '/signup-success';
export const CREATE_PASSWORD_ROUTER_PATH = '/create-password';
export const HOME_PATH = '/home';
export const WALLCHART_PATH = '/wallchart';
export const MY_CALENDAR_PATH = '/my-calendar';
export const USER_SETTINGS_PATH = '/user-settings';

export const MANAGERS_WALLCHART_PATH = '/managers/wallchart';
export const MANAGERS_MY_CALENDAR_PATH = '/managers/my-calendar';
export const MANAGERS_USERS_PATH = '/managers/users';
export const MANAGERS_COMPANY_SETTINGS_PATH = '/managers/manager-settings';
export const MANAGERS_USER_SETTINGS_PATH = '/managers/user-settings';

export const ADMIN_PATH = '/admin';

import {Button, Checkbox, Form, Input, InputNumber, Select, Space, Typography} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import dayjs from 'dayjs';

const { Title } = Typography;

function CompanyPublicHolidaysForm(props) {
  const [form] = Form.useForm();
  const bankRegionId = Form.useWatch('bank_region_id', form);
  const {onFinish, initValues, isSaving, bankHolidays, bankRegions, disabled} = props;

  useEffect(() => {
    form.resetFields();
  }, [initValues.id])

  const companyBankHolidays = bankHolidays.map((holiday) => {
    const existingComHoliday = initValues.bank_holidays.find((comHoliday) => comHoliday.get('bank_holiday_id') === holiday.get('id'));
    return existingComHoliday ? Object.fromEntries(existingComHoliday) : {
      bank_region_id: holiday.get('bank_region_id'),
      bank_holiday_id: holiday.get('id'),
      subscribed: false,
      company_id: initValues.id
    };
  })

  const getHolidayName = (holidayId) => {
    return bankHolidays.find((holiday) => holidayId === holiday.get('id')).get('name');
  }

  const getHolidayDate = (holidayId) => {
    return dayjs(bankHolidays.find((holiday) => holidayId === holiday.get('id')).get('date')).format('DD/MM/YYYY');
  }

  return (
    <Form
        style={{width: 300}}
        form={form}
        layout="vertical"
        initialValues={{...initValues,
          bank_holidays: (companyBankHolidays)}}
        onFinish={(values) => onFinish(form, values)}
    >
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item
          name="bank_region_id"
          rules={[{required: true, message: 'Please input bank holiday region!'}]}
          label={<Title level={4} style={{marginBottom: 0}}>Bank Holiday Region</Title>}>
        <Select size={"large"} placeholder="Choose Public Holiday List" style={{borderWidth: '0 0 2px', borderRadius: 0}}>
          {bankRegions.map((region, index) => (
              <Select.Option key={region.get('id')}
                             value={region.get('id')}>{region.get('title')}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.List name="bank_holidays">
        {(fields, { add, remove }) => (
            <>
              <Space>
                <span></span>

                <span style={{width: 200, display: 'block'}}><Title level={4}>Name</Title></span>

                <span style={{width: 200, display: 'block'}}><Title level={4}>Date</Title></span>

              </Space>
              {fields.filter(({_, name}) => bankRegionId === form.getFieldValue('bank_holidays')[name].bank_region_id)
                  .map(({key, name, ...restField}) => (
                  <Space key={key} align="baseline">
                    <Form.Item label="id" name={[name, 'id']} noStyle>
                      <Input type="hidden" />
                    </Form.Item>

                    <span style={{width: 200, display: 'block', fontSize: 16}}>{getHolidayName(form.getFieldValue('bank_holidays')[name].bank_holiday_id)}</span>

                    <span style={{width: 200, display: 'block', fontSize: 16}}>{getHolidayDate(form.getFieldValue('bank_holidays')[name].bank_holiday_id)}</span>

                    <Form.Item
                        // shouldUpdate={(prev, cur) =>
                        //     prev?.id !==
                        //     cur?.id}
                        {...restField}
                        label=""
                        name={[name, 'subscribed']}
                        valuePropName="checked"
                        style={{marginBottom: 0}}
                    >
                      <Checkbox />
                    </Form.Item>
                  </Space>
              ))}
            </>
        )}
      </Form.List>

      <Form.Item>
        <Button style={{marginTop: 40}}  size={"large"} type="primary" htmlType="submit" disabled={isSaving || disabled} icon={isSaving ? <LoadingOutlined /> : null}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}

export default withRouter(CompanyPublicHolidaysForm);

import axios from 'axios';
import jwtInterceptor from "../../app/libs/jwtInterceptor";
import ReactOnRails from 'react-on-rails';

export default {
  /**
   * Retrieve list of entities from server using AJAX call.
   *
   * @returns {Promise} - Result of ajax call.
   */
  fetchEntities(endpoint) {
    return jwtInterceptor.get('/' + endpoint + '.json');
  },

  /**
   * Submit new entity to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  submitEntity(endpoint, entity) {
    return jwtInterceptor.post('/' + endpoint + '.json', entity);
  },

  /**
   * Patch existing entity to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  updateEntity(endpoint, id, entity) {
    return jwtInterceptor.patch('/' + endpoint + '/' + id + '.json', entity);
  },

  /**
   * Patch existing entity to server using AJAX call.
   *
   * @param {Object} id - Object id to delete.
   * @returns {Promise} - Result of ajax call.
   */
  deleteEntity(endpoint, id) {
    return jwtInterceptor.delete('/' + endpoint + '/' + id + '.json');
  },

  /**
   * Submit new user to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  submitSignup(entity) {
    return axios.request({
      method: 'POST',
      url: '/users/signup',
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders(),
      data: entity,
    });
  },

  /**
   * Submit new user to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  submitLogin(entity) {
    return axios.request({
      method: 'POST',
      url: '/users/login',
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders(),
      data: entity,
    });
  },

  /**
   * Submit new user to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  logout(token) {
    return axios.request({
      method: 'DELETE',
      url: '/users/logout',
      responseType: 'json',
      headers: {...ReactOnRails.authenticityHeaders()},
    });
  },

  /**
   * Submit new user to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  submitPassword(entity) {
    return axios.request({
      method: 'PUT',
      url: `/password`,
      responseType: 'json',
      headers: {...ReactOnRails.authenticityHeaders()},
      data: entity,
    });
  },

  /**
   * Submit new user to server using AJAX call.
   *
   * @param {Object} entity - Request body to post.
   * @returns {Promise} - Result of ajax call.
   */
  resetPassword(entity) {
    return axios.request({
      method: 'POST',
      url: `users/reset-password`,
      responseType: 'json',
      headers: {...ReactOnRails.authenticityHeaders()},
      data: entity,
    });
  },
};

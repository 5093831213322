import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BaseComponent from '../../../libs/components/BaseComponent.jsx';
import HomeScreen from '../components/HomeScreen/HomeScreen.jsx';

class HomeContainer extends BaseComponent {

  render() {
    return (
        <HomeScreen />
    );
  }
}

export default HomeContainer;

import React from 'react';
import BaseComponent from '../../../libs/components/BaseComponent.jsx';
import LoginScreen from "../components/LoginScreen/LoginScreen";


class RouterLoginContainer extends BaseComponent {

  render() {
    return (
          <LoginScreen />
    );
  }
}

export default RouterLoginContainer;

export const antTheme = {
  token: {
    fontFamily: 'SourceSansPro',
        colorBgLayout: 'white',
        colorPrimary: '#0023C4',
        colorPrimaryBgHover: '#0023C4',
        colorLink: '#0023C4',
        colorTextBase: '#0E0B4E',
        colorTextHeading: '#0E0B4E',
        colorInfo: '#0023C4',
        colorTextDisabled: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 4,
        controlHeightLG: 50,
        controlHeight: 40,
        controlOutlineWidth: 0,
        colorBgBase: '#ffffff',
        colorBgContainer: 'white',
        fontWeightStrong: 700
  },
  components: {
    Menu: { // if you use "dark" theme on menu
      // colorItemBg: '#ffffff', // colorBgBase -3% lightness
      // colorSubItemBg: '#ffffff' // colorBgBase -6% lightness
      itemSelectedBg: '#ffffff',
          itemHoverBg: '#ffffff',
          fontSize: 18,
          lineHeight: 50,
    },
    Radio: {
      borderRadiusLG: 30,
          fontSizeLG: 14
    },
    Select: {
      borderRadiusLG: 0,
    },
    DatePicker: {
      borderRadiusLG: 0
    }
  }
}
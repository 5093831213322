import usersReducer, { $$initialState as $$usersState } from "./usersReducer";
import companiesReducer, { $$initialState as $$companiesState } from "./companiesReducer";
import departmentsReducer, { $$initialState as $$departmentsState } from "./departmentsReducer";
import leaveTypesReducer, { $$initialState as $$leaveTypesState } from "./leaveTypesReducer";
import bankRegionsReducer, { $$initialState as $$bankRegionsState } from "./bankRegionsReducer";
import bankHolidaysReducer, { $$initialState as $$bankHolidaysState } from "./bankHolidaysReducer";
import leaveBookingsReducer, { $$initialState as $$leaveBookingsState } from "./leaveBookingsReducer";
import railsContextReducer, { initialState as railsContextState } from './railsContextReducer';

export default {
  $$usersStore: usersReducer,
  $$companiesStore: companiesReducer,
  $$departmentsStore: departmentsReducer,
  $$leaveTypesStore: leaveTypesReducer,
  $$bankRegionsStore: bankRegionsReducer,
  $$bankHolidaysStore: bankHolidaysReducer,
  $$leaveBookingsStore: leaveBookingsReducer,
  railsContext: railsContextReducer,
};

export const initialStates = {
  $$usersState,
  $$companiesState,
  $$departmentsState,
  $$leaveTypesState,
  $$bankRegionsState,
  $$bankHolidaysState,
  $$leaveBookingsState,
  railsContextState,
};

import React from 'react';
import {Button, Menu, Typography, Drawer, Space} from 'antd';
import {
  CloseOutlined, HomeOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import {useHistory, withRouter} from 'react-router-dom';
import {
  HOME_PATH,
  MANAGERS_MY_CALENDAR_PATH,
  MANAGERS_COMPANY_SETTINGS_PATH,
  MANAGERS_USERS_PATH,
  MANAGERS_WALLCHART_PATH, MY_CALENDAR_PATH, USER_SETTINGS_PATH, WALLCHART_PATH
} from "../../constants/paths";
import {useAuth} from "../../context/auth";

const { Title } = Typography;

function NotificationsDrawer(props) {
  const {open, onClose, children} = props;
  const auth = useAuth();
  const history = useHistory();

  return (
    <Drawer placement="right" headerStyle={{display: 'none'}} bodyStyle={{padding: 20}} onClose={onClose}
            open={open} destroyOnClose>
      <Space align={"center"} style={{justifyContent: "flex-end", width: '100%', marginBottom: 40}}>
        <Button type="primary" shape="circle" icon={<CloseOutlined/>} size={"large"} onClick={onClose} style={{width: 50}}/>
      </Space>
      {children}
    </Drawer>
  );
}

export default withRouter(NotificationsDrawer);
